import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Table, Input, Button, ButtonGroup } from 'reactstrap';
import { TemplateView } from '../../../models/enums/TemplateView';

type ConsultWithSpecialistProps = {
    templateData?: string;
    setCounsultChoice?: Dispatch<SetStateAction<string>>;
    className?: string;
    viewType: TemplateView;
}

const ConsultWithSpecialist = (props: ConsultWithSpecialistProps) => {

    const inputData = props.templateData ? props.templateData : "";
    const tableStylePerView = props.viewType == TemplateView.View ? "table-measurements lenses-table transparent-background mb-2" : "table-measurements lenses-table mb-2";
    const tableStyleClasses = `${tableStylePerView} ${props.className}`;
    const [counsultChoice, setCounsultObjectChoice] = useState("");

    const consultationChoices = ["Да", "Не", "Незабавно", "Друг-вид-специалист"];

    useEffect(() => {
        if (props.viewType != TemplateView.CreateNew){
            setCounsultObjectChoice(inputData);
        }
    },[]);

    useEffect(() => {
        if(typeof(props.setCounsultChoice) === "function" ) {
            props.setCounsultChoice(counsultChoice);
        }
      }, [counsultChoice]);
  
    return <>
        <Table className={tableStyleClasses} bordered>
        <tbody>
            <tr>
                <th className="f-size-16 align-middle text-center w-24" scope="row">Насочване към офталмолог</th>
                {props.viewType === TemplateView.View ?
                    <td>
                        <div className="f-size-18">{counsultChoice === "Друг-вид-специалист" ? "Друг вид специалист" : counsultChoice}</div>
                    </td>
                :
                    <>
                        <td colSpan={3} className="w-45">
                            <ButtonGroup className="d-flex justify-content-around contact-lenses-buttons">
                            {consultationChoices.map((data, index) =>
                            <Button
                                key={index}
                                className={data === "Друг-вид-специалист" ? "px-2 w-45 mx-1" : "px-2 w-23 mx-1"}
                                color="primary"
                                onClick={() => setCounsultObjectChoice(data)}
                                active={counsultChoice === data}>
                                {data === "Друг-вид-специалист" ? "Друг вид специалист" : data}
                            </Button>
                            )}
                            </ButtonGroup>
                        </td>
                        <td>
                            <Input
                                className={`f-size-18`}
                                type="text"
                                placeholder="Друго"
                                defaultValue={counsultChoice === "Друг-вид-специалист" ? "Друг вид специалист" : counsultChoice}
                                onChange={e => setCounsultObjectChoice(e.target.value)}
                            />
                        </td>
                    </>
                }
            </tr>
        </tbody>
      </Table>
  </>
}

export default ConsultWithSpecialist;