import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { Table, Input, Button, ButtonGroup, Col } from 'reactstrap';
import TooltipItem from '../../../../components/TooltipItem';
import { undefinedChecker } from '../../../../helpers/Checkers';
import { Get } from '../../../../helpers/requests';
import { TemplateView } from '../../../../models/enums/TemplateView';
import { GlassesOrderData } from '../../../../models/glassesOrderData';

export type LensesData = {
    manufacturer?: string;
    materialType?: string;
    designType?: string;
    lensesType?: string;
    product?: string;
    coating?: string;
    fotoTechnologyType?: string;
    coloring?: string;
    lensDiameter?: string;
    distanceType?: string;
}

type LensesProps = {
    viewType: TemplateView;
    setLensesData?: Dispatch<SetStateAction<LensesData>>;
    reload?: boolean;
    orderId?: string;
}

const Lenses = (props: LensesProps) => {

    const [lensesData, setLensesObjectData] = useState<LensesData>({});

    const distanceType = ["Далече", "Близо", "Комбинирано"];
    const manufacturer = ["Маска", "Optiswiss", "Essilor", "Zeiss", "Hoya", "Rodenstock", "Novacell"];
    const material = ["Минерал", "1.5", "1.56", "1.6", "1.67", "1.74", "Поликарбонат", "Тривекс"];
    const design = [
        {label: "Сферичен", tooltip: null}, 
        {label: "Асферичен", tooltip: "Задължително вземете височина на пупилата! Асферичните лещи се децентрират под височината на пупилата! Децентрирането може да варира в зависимост от вертекса и пантоскопичния ъгъл. За подробности вижте инструкциите на производителя!"},
        {label: "ДвеАсферичен", tooltip: "Задължително вземете височина на пупилата! Асферичните лещи се децентрират под височината на пупилата! Децентрирането може да варира в зависимост от вертекса и пантоскопичния ъгъл. За подробности вижте инструкциите на производителя!"},
        {label: "Фрийформ", tooltip: "Задължително вземето моноколярно ПД, височина на пупилата, според вида на продукта може да е нужен вертекс и пантоскопичен ъгъл! За повече информация вижте инструкциите на производителя!"}
    ];
    const type = [
        {label: "Еднофокусни", tooltip: null},
        {label: "Офисни", tooltip: "Нужна е моноколявно ПД за близо, вертикалата се центрира по геометричен център! За повече информация погледнете инструкциите на производителя!"},
        {label: "Дегресив", tooltip: "Необходими са моноколярно ПД за далече, височина на пупилата, пантоскопичен ъгъл. Отбележете желаната добавка според каталога на производителя."},
        {label: "Прогресив", tooltip: "Необходими са моноколярно ПД за далече, ПД за близо, височина на пупилата, вертексно разстояние, пантоскопичен ъгъл. При специфични мерки, изберете индивидуален клас прогресив и отбележете особеностите. Препоръчително е да се избере приоритетна дистанция, според която се избира дължината на коридора. За повече информация, вижте каталога на производителя."},
        {label: "Бифокал", tooltip: "Необходими са ПД за далече и височина на долния клепач. При изработка ПДто се децентрира спрямо инструкциите на производителя)"}
    ];
    const coloring = ["Сиво", "Кафяво", "Зелено"];
    const diameter = [
        {label: "Ø55", tooltip: "проверете дали производителя поддържа желаният диаметър"},
        {label: "Ø60", tooltip: "проверете дали производителя поддържа желаният диаметър"},
        {label: "Ø65", tooltip: null},
        {label: "Ø70", tooltip: "проверете дали производителя поддържа желаният диаметър"},
        {label: "Ø75", tooltip: "проверете дали производителя поддържа желаният диаметър"},
        {label: "Ø80", tooltip: "проверете дали производителя поддържа желаният диаметър"},
    ];

    useEffect(() => {        
        if(props.orderId){
            Get(`glassesOrder/${props.orderId}`)
                .catch(error => console.log(error))
                .then((json: GlassesOrderData) => {
                setLensesObjectData({  
                    manufacturer: json.manufacturer, 
                    materialType: json.materialType, 
                    designType: json.designType, 
                    lensesType: json.lensesType,
                    product: json.product, 
                    coating: json.coating, 
                    fotoTechnologyType: json.fotoTechnologyType, 
                    coloring: json.coloring, 
                    lensDiameter: json.lensDiameter, 
                    distanceType: json.distanceType});
            });
        }
        
        if (props.viewType == TemplateView.CreateNew){
            setLensesObjectData({...lensesData, 
                manufacturer: "",
                materialType: "",
                designType: "",
                lensesType: "",
                product: "",
                coating: "",
                fotoTechnologyType: "",
                coloring: "",
                lensDiameter: "",
                distanceType: ""
            });
        }
    },[props.reload]);

    useEffect(() => {
        if(typeof(props.setLensesData) === "function" ) {
            props.setLensesData(lensesData);
        }
      }, [lensesData]);

    const displayRowElement = (rowName, propertyName): JSX.Element => {
        return <>
            <tr>
                <th className="f-size-16 text-right" scope="row">{rowName}</th>
                <td className="f-size-16 p-l-1">{undefinedChecker(lensesData, propertyName)}</td>
            </tr>
        </>
    }

    const buttonGroupWithNameColumn = (rowName, objectProperty, buttonGroup, btnClassName, columnSpan = 7, btnGroupClassName = "d-flex justify-content-around"): JSX.Element => {
        return <>
            <th className="f-size-16 align-middle text-center" scope="row">{rowName}</th>
            <td colSpan={columnSpan}>
                <ButtonGroup className={btnGroupClassName}>
                    {buttonGroup.map((name, index) =>
                        <Button 
                            key={index}
                            className={btnClassName}
                            color="primary" 
                            onClick={() => setLensesObjectData({ ...lensesData, [objectProperty]: name})} 
                            active={lensesData[objectProperty] === name}>
                            {name}
                        </Button>
                    )}
                </ButtonGroup>
            </td>
        </>
    }

    const buttonGroupWithTooltipColumn = (rowName, objectProperty, buttonGroup, btnClassName, columnSpan = 7, btnGroupClassName = "d-flex justify-content-around"): JSX.Element => {
        return <>
            <th className="f-size-16 align-middle text-center" scope="row">{rowName}</th>
            <td colSpan={columnSpan}>
                <ButtonGroup className={btnGroupClassName}>
                    {buttonGroup.map((data, index) =>
                        <Button 
                            key={index}
                            color="primary" 
                            className={btnClassName}
                            onClick={() => setLensesObjectData({ ...lensesData, [objectProperty]: data.label})}
                            active={lensesData[objectProperty] === data.label}>
                            {data.label}
                            {data.tooltip && 
                                <TooltipItem item={data} />
                            }
                        </Button>
                    )}
                </ButtonGroup>
            </td>
        </>
    }

    const inputField = (objectProperty, inputClass = "f-size-18", placeholder = ""): JSX.Element => {
        return <>
                <Input
                    className={inputClass}
                    type="text"
                    placeholder={placeholder}
                    defaultValue={lensesData[objectProperty]}
                    onChange={e => setLensesObjectData({ ...lensesData, [objectProperty]: e.target.value})}
                />
        </>
    }

    return <>
        {props.viewType == TemplateView.View ?
            <Table className="table-border-blue lenses-view" bordered>
                <tbody>
                    {displayRowElement("Корекция за", "distanceType")}
                    {displayRowElement("Марка", "manufacturer")}
                    {displayRowElement("Продукт", "product")}
                    {displayRowElement("Материал", "materialType")}
                    {displayRowElement("Дизайн", "designType")}
                    {displayRowElement("Вид", "lensesType")}
                    {displayRowElement("Покритие", "coating")}
                    {displayRowElement("Фото. Технология", "fotoTechnologyType")}
                    {displayRowElement("Оцветяване", "coloring")}
                    {displayRowElement("Диаметър", "lensDiameter")}
                </tbody>
            </Table>
        :
        <Col lg="12">
            <Table className="table-border-blue lenses-table" bordered>
                <tbody>
                    <tr>
                        {buttonGroupWithNameColumn("Корекция за", "distanceType", distanceType, "w-32", 8, "d-flex justify-content-around manufacturer-buttons")}
                    </tr>
                    <tr>
                        {buttonGroupWithNameColumn("Марка", "manufacturer", manufacturer, "", 7, "d-flex justify-content-around manufacturer-buttons")}
                        <td className="w-20">
                            {inputField("manufacturer", "f-size-18", "Друг")}
                        </td>
                    </tr>
                    <tr>
                        <th className="f-size-16 align-middle text-center" scope="row">Продукт</th>
                        <td colSpan={8}>
                            {inputField("product")}
                        </td>
                    </tr>
                    <tr>
                        {buttonGroupWithNameColumn("Материал", "materialType", material, "px-2")}
                        <td className="w-20">
                            {inputField("materialType", "f-size-18", "Друг")}
                        </td>
                    </tr>
                    <tr>
                        {buttonGroupWithTooltipColumn("Дизайн", "designType", design, "px-2 w-23-5")}
                        <td className="w-20">
                            {inputField("designType", "f-size-18", "Друг")}
                        </td>
                    </tr>
                    <tr>
                        {buttonGroupWithTooltipColumn("Вид", "lensesType", type, "px-2")}
                        <td>
                            {inputField("lensesType", "f-size-18", "Друг")}
                        </td>
                    </tr>
                    <tr>
                        <th className="f-size-16 align-middle text-center" scope="row">Покритие</th>
                        <td colSpan={8}>
                            {inputField("coating")}
                        </td>
                    </tr>
                    <tr>
                        <th className="f-size-16 align-middle text-center" scope="row">Фото. Технология</th>
                        <td colSpan={8}>
                            {inputField("fotoTechnologyType")}
                        </td>
                    </tr>
                    <tr>
                        {buttonGroupWithNameColumn("Оцветяване", "coloring", coloring, "w-32")}
                        <td>
                            {inputField("coloring", "f-size-18", "Други")}
                        </td>
                    </tr>
                    <tr>
                        {buttonGroupWithTooltipColumn("Диаметър", "lensDiameter", diameter, "w-15-5", 8)}
                    </tr>
                </tbody>
            </Table>
        </Col>
        }
    </>
}

export default Lenses;