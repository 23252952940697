import React from "react";
import { useHistory } from "react-router";
import { Table } from "reactstrap";
import { CustomerListItem } from "../../../models/customer/customerListItem";
import { SharedRoute } from '../../../routes';

type CustomerListProps = {
  listData: CustomerListItem[]
}

const CustomersList = (props: CustomerListProps): JSX.Element => {

  const history = useHistory();

  const redirectToPage = (link: string): void => {
    history.push(link);
  }
  
  return <>
      <Table className="align-items-center table-flush table-striped" responsive>
        <thead className="silver-background black-font-color">
          <tr className="text-center">
            <th className="f-size-16" scope="col">Име</th>
            <th className="f-size-16" scope="col">Телефон</th>
            {/* <th className="f-size-16" scope="col">Мейл</th> */}
            <th className="f-size-16" scope="col">Брой Поръчки</th>
          </tr>
        </thead>
        <tbody>
          {props.listData.map((data, index) =>
            <tr className="text-center cursor-pointer" key={index} onClick={() => redirectToPage(`${SharedRoute.CustomerDetails}?id=${data.id}`)}>
              <td className="f-size-16">{data.name}</td>
              <td className="f-size-16">{data.phone}</td>
              {/* <td className="f-size-16">{data.email}</td> */}
              <td className="f-size-16">{data.ordersAmount}</td>
            </tr>
          )}
        </tbody>
      </Table>
  </>
}

export default CustomersList;