import React, { useContext, useEffect, useState } from 'react';
import { Input, Button, Card, CardBody } from 'reactstrap';
import { TemplateView } from '../../../models/enums/TemplateView';
import { ClientRoute } from '../../../routes';
import { Create } from '../../../helpers/requests';
import { MeasurementsDetails } from '../../../models/measurementDetails';
import CommonConditionTemplate from './CommonConditionTemplate';
import { CommonCondition } from '../../../models/commonCondition';
import LenseMeasurementsTemplate from './LenseMeasurementsTemplate';
import { LenseMeasurements } from '../../../models/LenseMeasurements';
import LastUsedLenses from './LastUsedLenses';
import ConsultWithSpecialist from './ConsultWithSpecialist';
import LensesType from './LensesType';
import { RefractionProtocol } from '../../../models/refractionProtocol';
import { UserContext } from '../../../userContext';
import OtherObservationsTemplate from './OtherObservationsTemplate';
import { OtherObservations } from '../../../models/otherObservations';

type MeasurementsDetailsProps = {
    templateData?: MeasurementsDetails;
    populateData?: any;
    viewType: TemplateView;
}

const AddRefraction = (props: MeasurementsDetailsProps) => {

    var url = new URL(window.location.href);
    var clientId = url.searchParams.get("customerId");

    const inputData = props.templateData ? props.templateData : {} as MeasurementsDetails;
    
    const [measurementsData, setMeasurementsData] = useState<MeasurementsDetails>({});
    const [conditionData, setConditionData] = useState<CommonCondition>({});
    const [lenseMeasurements, setLenseMeasurements] = useState<LenseMeasurements>({});
    const [lastUsedLenses, setLastUsedLenses] = useState<LenseMeasurements>({});
    const [consultWithSpecialist, setConsultWithSpecialist] = useState("");
    const [lensesType, setLensesType] = useState("");
    const [additionalInformation, setAdditionalInformation] = useState("");
    const [otherObservationsData, setOtherObservationsData] = useState({} as OtherObservations);
    const {objectData} = useContext(UserContext);

    useEffect(() => {
        if (props.viewType != TemplateView.CreateNew){
            if(inputData){
                setMeasurementsData(inputData);
            }
        }
    },[]);
        
    const saveDataObject = () => {

      const employeeId = objectData.getObjectData.id;
      const customerId = clientId;
      let newLenseMeasurement: LenseMeasurements = { ...lenseMeasurements, prescribedBy: objectData.getObjectData.name};
      
      let protocolData: RefractionProtocol = {};
      protocolData = {
        ...conditionData,
        ...otherObservationsData,
        additionalInformation: additionalInformation,
        lastUsedGlassesType: lensesType,
        clientId: customerId,
        employeeId: employeeId,
        facilityId: objectData.getObjectData.facilityId,
        companyId: objectData.getObjectData.companyId,
        consultWithSpecialist: consultWithSpecialist,
        type: lensesType,
        newMeasurement: newLenseMeasurement,
        latestMeasurement: lastUsedLenses,
      };
      Create(protocolData, "refraction", `${ClientRoute.CustomerDetails}?id=${customerId}`);
    }
    console.log("render");
    return <>
      <Card className="bg-secondary shadow mb-4">
        <CardBody className="py-3">
          <h6 className="heading-small text-muted f-size-16 mb-0">
            Общо състояние
          </h6>
          <CommonConditionTemplate viewType={TemplateView.CreateNew} setConditionData={setConditionData}/>
        </CardBody>
      </Card>
      <Card className="bg-secondary shadow mb-4">
        <CardBody className="py-3">
          <h6 className="heading-small text-muted f-size-16 mb-0">
            Ново измерване
          </h6>
          <LenseMeasurementsTemplate className="mt-3 lenses-table" viewType={TemplateView.CreateNew} setMeasurementstData={setLenseMeasurements}/>
        </CardBody>
      </Card>
      <Card className="bg-secondary shadow mb-4">
        <CardBody className="py-3">
          <h6 className="heading-small text-muted f-size-16 mb-0">
            Други наблюдения
          </h6>
          <OtherObservationsTemplate className="mt-4 lenses-table" viewType={TemplateView.CreateNew} setOtherObservationData={setOtherObservationsData}/>
          <ConsultWithSpecialist className="mt-4" viewType={TemplateView.CreateNew} setCounsultChoice={setConsultWithSpecialist}/>
        </CardBody>
      </Card>
      <Card className="bg-secondary shadow mb-4">
        <CardBody className="py-3">
          <h6 className="heading-small text-muted f-size-16 mb-0">
            Последно ползвани очила
          </h6>
          <LastUsedLenses className="mt-3 lenses-table" viewType={TemplateView.CreateNew} setMeasurementsData={setLastUsedLenses}/>
          <LensesType className="mt-4" viewType={TemplateView.CreateNew} setLenseTypeChoice={setLensesType}/>
        </CardBody>
      </Card>
      <Card className="bg-secondary shadow mb-4">
        <CardBody>
          <h6 className="heading-small text-muted f-size-16">Допълнителна информация</h6>
          <Input 
              type="textarea" 
              name="text"
              resize="none"
              className="mb-2"
              onChange={e => setAdditionalInformation(e.target.value)}/>
          <Button className="mt-4" color="success" onClick={() => saveDataObject()}>Запази</Button>
        </CardBody>
      </Card>
  </>
}

export default AddRefraction;