import React from "react";
import FacilitiesList, { FacilityListItem } from '../Facility/FacilitiesList';
import CompanyTemplate from './CompanyTemplate';
import { TemplateView } from '../../../models/enums/TemplateView';
import { Card } from "reactstrap";
import ApiResource from "../../../helpers/ApiResource";
import { CompanyDetailsData } from "../../../models/companyDetailsData";

export type CompanyViewModel = {
  companyDetails : CompanyDetailsData;
  facilities: FacilityListItem[];
}

const CompanyDetails = (props): JSX.Element => {

  var url = new URL(window.location.href);
  var id = url.searchParams.get("id");
  
  return <>
    <ApiResource url={`company/${id}`}>
        {(companyData: CompanyViewModel) => <>
          <CompanyTemplate viewType={TemplateView.View} templateData={companyData.companyDetails} />
          <Card className="mt-4">
            <h6 className="heading-small f-size-16 m-4">Прилежащи обекти</h6>
            <FacilitiesList listData={companyData.facilities} />
          </Card>
        </>}
    </ApiResource>
  </>
}

export default CompanyDetails;