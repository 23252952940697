import React from "react";
import { Table } from "reactstrap";
import PageLoader from "./pageLoader";
import NoAvailableData from "./noAvailableData";
import { ProductDetailsItem } from "../models/productDetailsItem";

export const tableHeader = [
  "Производител",
  "Брой",
  "Код",
];

type ProductsDetailsTableProps = {
  loading: boolean;
  data: ProductDetailsItem[];
  reload?: any;
}

const ProductsDetailsTable = (props: ProductsDetailsTableProps) => {
     
  return <>
  {props.loading ? <PageLoader></PageLoader> :
    <Table className="align-items-center table-flush orders-table table-striped" responsive>
      <thead className="silver-background black-font-color">
        <tr>
          {tableHeader.map((data, index) =>
            <th key={index} className="f-size-16 pt-4 pb-4 border-top-0" scope="col">
                {data}
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        {props.data
        ?
          <>
          {props.data.map((data, index) =>
          <tr key={index} >
            <td className="f-size-16">{data.productType}</td>
            <td className="f-size-16">{data.amountUsed}</td>
            <td className="f-size-16">{data.codesTotal}</td>
          </tr>
        )}
          </>
          :
          <tr><td><NoAvailableData/></td></tr>
        }
      </tbody>
    </Table>
  }
  </>
}

export default ProductsDetailsTable;
