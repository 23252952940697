export const undefinedChecker = (inputObject, objectProperty) => {
    return inputObject != undefined ? inputObject[objectProperty] : "";
}

export const isNullOrWhiteSpace = (inputString: string, signAfterString: string = ""): string => {

    if (/\S/.test(inputString)) {
        return `${inputString}${signAfterString}`;
    }
    return "";
}

export const isNullChecker = (input: string, signBeforeString: string = "", signAfterString: string = ""): string => {

    if(input !== null && input !== undefined){
        return `${signBeforeString}${input}${signAfterString}`;
    }

    return "";
}

export const isNullCheckerNumber = (input: string) => {
    if(input !== null && input !== undefined){
        return parseFloat(input);
    }

    return 0;
}