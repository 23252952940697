import React from "react";

type PaginationBarProps = {
    setCurrentPage: any;
    currentPage: number;
    pageCount: number;
}

const PaginationBar = (props: PaginationBarProps): JSX.Element => {
    
    const minimumPaginationItems = 7;

    const previousPage = (currentPage: number, pageCount: number): number => {
        if (currentPage >= 2 && currentPage <= pageCount) {
            return currentPage - 1;
        }
    }
    
    const nextPage = (currentPage: number, pageCount: number): number => {
        if (currentPage >= 1 && currentPage < pageCount) {
            return currentPage + 1;
        }
    }

    const minimumPage = (currentPage: number, pageCount: number): number => {
        if(currentPage < pageCount){
            if(pageCount < minimumPaginationItems){
                return pageCount;
            }
            return minimumPaginationItems;
        } 
        else if(pageCount <= minimumPaginationItems) {
            return pageCount;
        }
    }

    const startOfPagination = (currentPage: number, pageCount: number) => {
        let rows = [];

        let minimumRange = minimumPage(currentPage, pageCount);

        for (let i = 1; i <= minimumRange; i++) {
          rows.push(<li className={`page-item ${currentPage === i ? "active" : ""}`} key={i}><a className="page-link" onClick={()=> props.setCurrentPage(i)}>{i}</a></li>);
        }
        return <>{rows}</>
    }

    const middleOfPagination = (currentPage: number, pageCount: number) => {

        return currentPage <= pageCount &&
            <>
                <li className={`page-item ${currentPage === 1 ? "active" : ""}`}><a className="page-link" onClick={()=> props.setCurrentPage(1)}>1</a></li>
                <li className="page-item"><a className="page-link">...</a></li>
                {currentPage <= pageCount-3 ?
                <>
                    <li className="page-item">
                        <a className="page-link" onClick={()=> props.setCurrentPage(currentPage - 1)}>{currentPage - 1}</a>
                    </li>
                    <li className="page-item active">
                        <a className="page-link" onClick={()=> props.setCurrentPage(currentPage)}>{currentPage}</a>
                    </li>
                    <li className="page-item">
                        <a className="page-link" onClick={()=> props.setCurrentPage(currentPage + 1)}>{currentPage + 1}</a>
                    </li>
                </>
                :
                <>
                    <li className={`page-item ${currentPage === pageCount - 3 ? "active" : ""}`}>
                        <a className="page-link" onClick={()=> props.setCurrentPage(pageCount - 3)}>{pageCount - 3}</a>
                    </li>
                    <li className={`page-item ${currentPage === pageCount - 2 ? "active" : ""}`}>
                        <a className="page-link" onClick={()=> props.setCurrentPage(pageCount - 2)}>{pageCount - 2}</a>
                    </li>
                    <li className={`page-item ${currentPage === pageCount - 1 ? "active" : ""}`}>
                        <a className="page-link" onClick={()=> props.setCurrentPage(pageCount - 1)}>{pageCount - 1}</a>
                    </li>
                </>
                }
                
                <li className="page-item"><a className="page-link">...</a></li>
                <li className={`page-item ${currentPage === pageCount ? "active" : ""}`}><a className="page-link" onClick={()=> props.setCurrentPage(pageCount)}>{pageCount}</a></li>
            </>
    }

    return <>
        <nav>
            <ul className="pagination">
                <li className="page-item">
                    <a className="page-link" onClick={()=> props.setCurrentPage(previousPage(props.currentPage, props.pageCount))} aria-label="Previous">
                        <span aria-hidden="true">&laquo;</span>
                        <span className="sr-only">Previous</span>
                    </a>
                </li>

                {props.currentPage <= minimumPaginationItems 
                    ?
                    <>
                        {startOfPagination(props.currentPage, props.pageCount)}
                    </>
                    :
                    <>
                        {middleOfPagination(props.currentPage, props.pageCount)}
                    </>
                }     
                
                <li className="page-item">
                    <a className="page-link" onClick={()=> props.setCurrentPage(nextPage(props.currentPage, props.pageCount))} aria-label="Next">
                        <span aria-hidden="true">&raquo;</span>
                        <span className="sr-only">Next</span>
                    </a>
                </li>
            </ul>
        </nav>
    </>
}

export default PaginationBar;