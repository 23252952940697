import React, { useContext, useEffect, useState } from "react";
import { Button, Row, Col, Modal, ModalBody, ModalFooter, Card, CardBody } from "reactstrap";
import { formatDate } from "../../helpers/Formatters";
import { Get } from "../../helpers/requests";
import { UserContext } from "../../userContext";
import { TimePeriod } from "../../models/enums/TimePeriod";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import OrdersTableTemplate from "../../components/OrdersTableTemplate";

const Activity = () => {

  const [data, setData] = useState<any>();
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(Boolean);
  const {objectData} = useContext(UserContext);
  const facilityId = objectData.getObjectData.facilityId;
  const [intervalModal, setIntervalModal] = useState(false);
  const [requestUrl, setRequestUrl] = useState(`order/${facilityId}/period/${TimePeriod.Day}`);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [intervalName, setIntervalName] = useState("Днес");

  const toggleIntervalModal = () => {
    setIntervalModal(!intervalModal);
  }

  useEffect(() => {
    setLoading(true);
    Get(requestUrl)
    .catch(error => console.log(error))
    .then((json: any) => {
      setData(json);
      setLoading(false);
    });
    setReload(false);
  },[reload]);

  const showDay = () => {
    setRequestUrl(`order/${facilityId}/period/${TimePeriod.Day}`);
    setReload(true);
    setIntervalName("Днес");
  }

  const showWeek = () => {
    setRequestUrl(`order/${facilityId}/period/${TimePeriod.Week}`);
    setReload(true);
    setIntervalName("Седмица назад");
  }

  const showMonth = () => {
    setRequestUrl(`order/${facilityId}/period/${TimePeriod.Month}`);
    setReload(true);
    setIntervalName("30 дни назад");
  }

  const showTimePeriod = (fromDate, toDate) => {
    setRequestUrl(`order/${facilityId}/period/${TimePeriod.Period}?fromDate=${formatDate(fromDate).toString()}&toDate=${formatDate(toDate).toString()}`);
    setReload(true);
    setIntervalModal(false);
    setIntervalName(`от ${formatDate(fromDate)} до ${formatDate(toDate)}`);
  }

  const reloadTableData = (newValue) => {
    setReload(newValue);
  }
  
  const intervalButton = (): JSX.Element => {
    return <>
      <Modal isOpen={intervalModal} toggle={toggleIntervalModal}>
        <ModalBody className="light-silver-background pb-0">
          <h6 className="heading-small text-muted f-size-16 my-1 text-center">
            Въведете датите от и до, за да филтрирате резултатите
          </h6>
          <Row className="light-silver-background py-4 no-gutters">
            <Col className="text-center datepicker-styled d-flex justify-content-center">
              <span className="mr-2">От</span>
                <DatePicker selected={fromDate} onChange={date => setFromDate(date)} />
              <span className="mx-2">До</span>
                <DatePicker selected={toDate} onChange={date => setToDate(date)} />
            </Col>
        </Row>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <Button color="success" onClick={() => {showTimePeriod(fromDate, toDate)}}>Филтрирай</Button>
          <Button color="danger" onClick={toggleIntervalModal}>Откажи</Button>
        </ModalFooter>
      </Modal>
    </>
  }
  
  return <>
    <Card className="bg-secondary shadow mb-4">
        <CardBody className="py-3">
            <h6 className="heading-small text-muted f-size-16 mb-0">
                Филтрирайте за период - {intervalName}
            </h6>
            <Row className="mt-2">
              <Col>
                <Button className="optileader-teal-background white-font-color" onClick={() => {showDay()}}>Днес</Button>
                <Button className="optileader-teal-background white-font-color" onClick={() => {showWeek()}}>Седмицата</Button>
                <Button className="optileader-teal-background white-font-color" onClick={() => {showMonth()}}>Месеца</Button>
                <Button className="optileader-teal-background white-font-color" onClick={() => toggleIntervalModal()}>Интервал</Button>
              </Col>
            </Row>
        </CardBody>
    </Card>
    <OrdersTableTemplate loading={loading} data={data} reload={reloadTableData}/>
    <>{intervalButton()}</>
  </>
}

export default Activity;
