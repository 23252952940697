import React, { useContext, useEffect, useState } from "react";
import { Row, Pagination, PaginationItem, Card, Table, Form, FormGroup, InputGroup, InputGroupAddon, Input, InputGroupText } from "reactstrap";
import RedirectButton from '../../../components/Buttons/RedirectButton';
import { ClientRoute } from '../../../routes';
import { serverAddress } from "../../../helpers/requests";
import { UserContext } from "../../../userContext";
import { ContactsListView } from "../../../models/contact/contactsListView";
import { useHistory } from "react-router";

const ContactsList = (): JSX.Element => {

  const [data, setData] = useState({} as ContactsListView);
  const {objectData} = useContext(UserContext);
  const [search, setSearch] = useState("");
  const [pageResults, setPageResults] = useState(0);

  const history = useHistory();

  const redirectToPage = (link: string): void => {
    history.push(link);
  }

  const loadData = (apiUrl: string) => {
    (async () => {
      const rawResponse = await fetch(`${serverAddress}/${apiUrl}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      });
      const content: ContactsListView = await rawResponse.json();
      setData(content);
      if(content.results){
        setPageResults(content.results.length);
      }
    })();
  }

  useEffect(() => {      
    loadData(`contacts/${objectData.getObjectData.companyId}/all`);
  },[]);

  const getResults = (searchText) => {
    setSearch(searchText);
    loadData(`contacts/${ objectData.getObjectData.companyId }/all?searchText=${searchText}`);
  }

  const paginate = (currentPage: number) => {
    loadData(`contacts/${ objectData.getObjectData.companyId }/all?pageSize=25&currentPage=${currentPage}&searchText=${search}`);
  }
    
  return <>
      <Card className="p-4 d-flex-none align-middle w-100">
        <Form className="navbar-search mr-3">
          <FormGroup className="mb-0">
            <InputGroup className="input-group-alternative" 
              onChange={e => getResults(e.target.value)}>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fas fa-search" />
                </InputGroupText>
              </InputGroupAddon>
              <Input placeholder="Търси Клиент" type="text"/>
            </InputGroup>
          </FormGroup>
        </Form>
        <RedirectButton className="optileader-teal-background white-font-color" buttonText="Добави Контакт" url={ClientRoute.AddContact} />
      </Card>
      <Row className="p-3">
        <span className="d-flex align-items-center pr-4">Резултати: {pageResults} от {data.recordCount}</span>
        <Pagination className="d-flex justify-content-center">
          {Array.apply(null, Array(data.pageCount)).map((i, index)=>
            <PaginationItem key={index}>
              <div className="page-link" onClick={() => paginate(index+1)}>
                {index+1}
              </div>
            </PaginationItem>
          )}
        </Pagination>
      </Row>
      <Card>
        <Table className="align-items-center table-flush table-striped" responsive>
          <thead className="silver-background black-font-color">
            <tr className="text-center">
              <th className="f-size-16" scope="col">Име</th>
              <th className="f-size-16" scope="col">Телефон</th>
              <th className="f-size-16" scope="col">Мейл</th>
              <th className="f-size-16" scope="col">Адрес</th> 
            </tr>
          </thead>
          <tbody>
            {data.results != undefined 
            ?
            <>
              {data.results.map((data, index) =>
                <tr className="text-center cursor-pointer" key={index} onClick={() => redirectToPage(`${ClientRoute.ViewContact}?id=${data.id}`)}>
                  <td className="f-size-16">{data.name}</td>
                  <td className="f-size-16">{data.phoneNumber}</td>
                  <td className="f-size-16">{data.email}</td>
                  <td className="f-size-16">{data.address}</td>
                </tr>
              )}
            </>
            :
            <tr>
              <td colSpan={7} className="text-center"><h4>Няма данни</h4></td>
            </tr>
            }
          </tbody>
        </Table>
      </Card>
  </>
}

export default ContactsList;
