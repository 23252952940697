import wretch from "wretch";
import history from "../../src/history";

//prod
// export const serverAddress = "https://optileader.com/api";

//dev
export const serverAddress = "/api";

export const Create = (data, controller, redirectRoute?) => {
    wretch(`${serverAddress}/${controller}`)
        .json(data)
        .post()
        .res(response => {
            if (response.ok){
                history.push(redirectRoute)
            } else {
                console.log(response);
            }
        });
}

export const Get = async (controller: string) => {
    return await wretch(`${serverAddress}/${controller}`)
        .get()
        .json();
}

export const Delete = (controller, redirectRoute?) => {
    wretch(`${serverAddress}/${controller}`)
        .delete()
        .res(response => {
            if (response.ok){
                history.push(redirectRoute)
            } else {
                console.log(response);
            }
        });
}

export const Update = (data, controller, redirectRoute?) => {
    wretch(`${serverAddress}/${controller}`)
        .put(data)
        .res(response => {
            if (response.ok){
                history.push(redirectRoute)
            } else {
                console.log(response);
            }
        });
}

export const UpdateWithoutRedirect = (data, controller) => {
    return wretch(`${serverAddress}/${controller}`)
        .put(data);
}

export const DeleteWithoutRedirect = (controller) => {
    return wretch(`${serverAddress}/${controller}`)
        .delete();        
}