import React, { useState, useEffect } from "react";
import { Row, Col, Button, FormGroup, Input, Card, CardBody, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalBody, ModalFooter } from "reactstrap";
import RedirectButton from '../../../components/Buttons/RedirectButton';
import { AdminRoute } from '../../../routes';
import { TemplateView } from '../../../models/enums/TemplateView';
import { EmployeeRole } from '../../../models/enums/EmployeeRole';
import PageBreadcrumbs, { BreadCrumbItem } from "../../../components/PageBreadcrumbs";
import { undefinedChecker } from "../../../helpers/Checkers";
import { Create, Update, Delete } from "../../../helpers/requests";
import { SetObjectData } from "../../../helpers/objectContextManager";
import { EmployeeDetailsData } from "../../../models/employeeDetailsData";
import { ProfileStatus } from "../../../models/enums/ProfileStatus";

type EmployeeTemplateProps = {
  templateData?: EmployeeDetailsData;
  viewType: TemplateView;
}

const EmployeeTemplate = (props: EmployeeTemplateProps): JSX.Element => {

  var url = new URL(window.location.href);
  var facilityId = url.searchParams.get("facilityId");

  const inputData = props.templateData;
  
  const breadcrumbs: Array<BreadCrumbItem> = [
      {label: "Фирма", url: AdminRoute.CompanyDetails, objectId: inputData.companyId},
      {label: "Обект", url: AdminRoute.FacilityDetails, objectId: inputData.facilityId},
      {label: "Служител"}
  ];

  let employeeRolesArray = Object.values(EmployeeRole);

  const [dropdownOpen, setOpen] = useState(false);

  const toggle = () => setOpen(!dropdownOpen);
  const [modal, setModal] = useState(false);

  const toggleModal = () => setModal(!modal);

  const [employeeData, setEmployeeData] = useState({} as EmployeeDetailsData);

  const employeeDetails = [
    { label: "Име", value: undefinedChecker(inputData, "name"), newValue: (newValue) => setEmployeeData({ ...employeeData, name: newValue}) },
    { label: "Имейл", value: undefinedChecker(inputData, "email"), newValue: (newValue) => setEmployeeData({ ...employeeData, email: newValue}) },
    { label: "Телефон", value: undefinedChecker(inputData, "phone"), newValue: (newValue) => setEmployeeData({ ...employeeData, phone: newValue}) },
  ];

  useEffect(() => {
    if (props.viewType == TemplateView.Edit){
      setEmployeeData(inputData);
    }
    if (props.viewType == TemplateView.CreateNew){
      setEmployeeData({ ...employeeData,
        role: EmployeeRole.Employee,
        companyId: inputData.companyId,
        facilityId: inputData.facilityId,
        profileStatus: ProfileStatus.Active
      });
    }
  },[]);

  const saveDataObject = (data) => {
    SetObjectData(data);
    Create(employeeData, "employee", `${AdminRoute.FacilityDetails}?id=${facilityId}`);
  }  

  const updateDataObject = (data) => {
    SetObjectData(data);
    Update(employeeData, "employee", AdminRoute.FacilityDetails);
  }

  const deleteDataObject = (data) => {
    SetObjectData(data);
    Delete(`employee/${employeeData.id}`, AdminRoute.FacilityDetails);
  }  

  const updateEmployeePassword = (data) => {
    SetObjectData(data);
    let updateObject: EmployeeDetailsData = {id: inputData.id, password: inputData.password}; 
    Update(updateObject, "employee", AdminRoute.FacilityDetails);
  }
  
  const renderFields = (): JSX.Element => {
    switch (props.viewType) {
      case TemplateView.CreateNew: {
        return <>
          {employeeDetails.map((data, index) =>
            <Col lg="4" key={index}>
              <FormGroup>
                <h6 className="heading-small text-muted f-size-16 my-1">
                  {data.label}
                </h6>
                <Input
                  className="form-control-alternative"
                  type="text"
                  onChange={e => data.newValue(e.target.value)}
                />
              </FormGroup>
            </Col>
          )}
          <Col lg="4">
              <FormGroup>
                <h6 className="heading-small text-muted f-size-16 my-1">
                  Парола
                </h6>
                <Input
                  className="form-control-alternative"
                  type="text"
                  onChange={e => setEmployeeData({ ...employeeData, password: e.target.value})}
                />
              </FormGroup>
            </Col></>
      }
      case TemplateView.Edit: {
        return <>
          {employeeDetails.map((data, index) =>
            <Col lg="4" key={index}>
              <FormGroup>
                <h6 className="heading-small text-muted f-size-16 my-1">
                  {data.label}
                </h6>
                <Input
                  className="form-control-alternative"
                  type="text"
                  defaultValue={data.value}
                  onChange={e => data.newValue(e.target.value)}
                />
              </FormGroup>
            </Col>
          )}
          <Col lg="4">
            <h6 className="heading-small text-muted f-size-16 my-1">
              Парола
            </h6>
            <Button color="primary" onClick={toggleModal}>Промени парола</Button>
            <Modal isOpen={modal} toggle={toggleModal}>
              <ModalBody className="light-silver-background">
                  <h6 className="heading-small text-muted f-size-16 my-1">
                    Въведете новата парола
                  </h6>
                  <Input
                    className="form-control-alternative"
                    type="text"
                    onChange={e => inputData.password = e.target.value}
                  />
              </ModalBody>
              <Row className="light-silver-background py-4 no-gutters">
                <Col className="text-center">
                  <Button color="success" onClick={() => updateEmployeePassword(inputData.facilityId)}>Запази</Button>
                  <Button color="danger" onClick={toggleModal}>Откажи</Button>
                </Col>
              </Row>
            </Modal>
          </Col>
        </>
      }
      case TemplateView.View: {
        return <>
          {employeeDetails.map((data, index) =>
            <Col lg="4" key={index}>
              <FormGroup>
                <h6 className="heading-small text-muted f-size-16 my-1">
                  {data.label}
                </h6>
                <hr className="my-1" />
                <span>{data.value}</span>
              </FormGroup>
            </Col>
          )}
          <Col lg="4">
              <FormGroup>
                <h6 className="heading-small text-muted f-size-16 my-1">
                  Статус
                </h6>
                <hr className="my-1" />
                <span>{ProfileStatus[inputData.profileStatus]}</span>
              </FormGroup>
            </Col></>
      }
      default: {
        return <span>Template Type Unknown</span>
      }
    }
  }

  const renderButtons = (): JSX.Element => {
    switch (props.viewType) {
      case TemplateView.CreateNew: {
        return <>
          <Button color="success" onClick={() => {saveDataObject(inputData.facilityId)}}>Запази</Button>
        </>
      }
      case TemplateView.Edit: {
        return <>
          <Button color="success" onClick={() => {updateDataObject(inputData.facilityId)}}>Запази</Button>
          <Button color="danger" onClick={() => {deleteDataObject(inputData.facilityId)}}>Изтрий Служителя</Button>
        </>
      }
      case TemplateView.View: {
        return <></>
      }
      default: {
        return <span>Template Type Unknown</span>
      }
    }
  }

  return <>
    <PageBreadcrumbs breadcrumbsList={breadcrumbs}/>
    <Card className="bg-secondary shadow mt-2">
      <CardBody>
        <h6 className="heading-small f-size-16">
          Детайли на служителя
          {props.viewType === TemplateView.View &&
            <RedirectButton className="optileader-teal-background white-font-color ml-4" buttonText="Редактирай" url={`${AdminRoute.EditEmployee}?id=${inputData.id}`} />
          }
        </h6>

        <Row className="mt-4">
          {renderFields()}
          <Col lg="4">
            <FormGroup>
              <h6 className="heading-small text-muted f-size-16 my-1">
                Позиция във фирмата
              </h6>
              {props.viewType === TemplateView.View ?
                <>
                  <hr className="my-1" />
                  <span>{inputData.role}</span>
                </>
                :
                <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
                  <DropdownToggle caret className="px-5 optileader-teal-background white-font-color">
                  {props.viewType === TemplateView.Edit
                    ? <>{employeeData.role}</>
                    : <>{employeeData.role ? employeeData.role : "Избери"}</>
                    }
                  </DropdownToggle>
                  <DropdownMenu>
                    {employeeRolesArray.map((data, index) =>
                      <DropdownItem key={index} onClick={() => setEmployeeData({ ...employeeData, role: data})}>{data}</DropdownItem>
                    )}
                  </DropdownMenu>
                </ButtonDropdown>
              }
            </FormGroup>
          </Col>
        </Row>
        {renderButtons()}
      </CardBody>
    </Card>
  </>
}

export default EmployeeTemplate;
