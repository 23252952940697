import { MeasurementsDetails } from "../models/measurementDetails";
import { isNullChecker, isNullCheckerNumber } from "./Checkers";
import numeral from 'numeral';
export const osDioptersDetails = (distanceType: string, measurements: any): string => {

    switch (distanceType) {
        case "Далече": {
            return `{
                "os_sph": "${isNullChecker(measurements.os_sph)}",
                "os_cyl": "${isNullChecker(measurements.os_cyl)}",
                "os_ax": "${isNullChecker(measurements.os_ax)}",
                "os_add": "",
                "os_prism": "${isNullChecker(measurements.os_prism)}",
                "os_base": "${isNullChecker(measurements.os_base)}"
            }`;
        }
        case "Близо": {
            let os_sph_close = isNullCheckerNumber(measurements.os_sph) + isNullCheckerNumber(measurements.os_add);
            
            let os_sph = os_sph_close === 0 ? "" : os_sph_close;

            return `{
                "os_sph": "${numeral(os_sph).format("+0.00")}",
                "os_cyl": "${isNullChecker(measurements.os_cyl)}",
                "os_ax": "${isNullChecker(measurements.os_ax)}",
                "os_add": "",
                "os_prism": "${isNullChecker(measurements.os_prism)}",
                "os_base": "${isNullChecker(measurements.os_base)}"
            }`;
        }
        case "Комбинирано": {
            return `{
                "os_sph": "${isNullChecker(measurements.os_sph)}",
                "os_cyl": "${isNullChecker(measurements.os_cyl)}",
                "os_ax": "${isNullChecker(measurements.os_ax)}",
                "os_add": "${isNullChecker(measurements.os_add)}",
                "os_prism": "${isNullChecker(measurements.os_prism)}",
                "os_base": "${isNullChecker(measurements.os_base)}"
            }`;
        }
        default: {
            return "";
        }
    }
}

