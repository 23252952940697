import { UserData } from "../models/userData";

export const LoginUser = (user: UserData) => {
    
    if(user){
        localStorage.setItem('v', user.verificationToken);
        return true;
    } else {
        console.log("user not found");
        window.location.href = "/auth/login";
        return false;
    }
}
