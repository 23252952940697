import React, { useState, useEffect, useContext } from 'react';
import { Table, Input, Button, ButtonGroup, Card, CardBody, Row, Col, FormGroup } from 'reactstrap';
import TooltipItem from '../../../../components/TooltipItem';
import { TemplateView } from '../../../../models/enums/TemplateView';
import { undefinedChecker } from '../../../../helpers/Checkers';
import { ClientRoute } from '../../../../routes';
import { Update, Delete, Create, Get } from '../../../../helpers/requests';
import MeasurementsTableTemplate from '../../OpticalMeasurement/MeasurementsTableTemplate';
import { productDetails, ProductDetailsType } from '../../../../helpers/ProductDetails';
import { osDioptersDetails } from '../../../../helpers/OsDioptersDetails';
import { ContactLensesOrderData } from '../../../../models/contactLensesOrderData';
import { MeasurementsDetails } from '../../../../models/measurementDetails';
import { UserContext } from '../../../../userContext';
import { OrderStatus } from '../../../../models/enums/OrderStatus';
import RedirectButton from '../../../../components/Buttons/RedirectButton';
import { odDioptersDetails } from '../../../../helpers/OdDioptersDetails';

export enum PeriodLength {
    Day = "Day",
    Month = "Month",
    ThreeMonths = "ThreeMonths",
    Year = "Year"
}

export enum ContactLensesBrand {
    Alcon = "Alcon",
    CooperVision = "CooperVision",
    BL = "BL",
    CMI = "CMI"
}

type ContactLensesProps = {
    viewType: TemplateView;
    orderId?: string;
    latestOpticalMeasurementId?: string;
    orderStatus?: OrderStatus;
}

const ContactLensesOrderTemplate = (props: ContactLensesProps) => {

    var url = new URL(window.location.href);
    var clientId = url.searchParams.get("customerId");

    const [contactLensesData, setContactLensesData] = useState({} as ContactLensesOrderData);
    const [measurementsDetails, setMeasurementsDetails] = useState({} as MeasurementsDetails);
    let orderData: ContactLensesOrderData = {};
    const {objectData} = useContext(UserContext);

    const companyId = objectData.getObjectData.companyId;
    const facilityId = objectData.getObjectData.facilityId;
    const customerId = clientId;
    const employeeId = objectData.getObjectData.id;
    const templateType = props.viewType;
    useEffect(() => {
        Get(`opticalMeasurements/${props.latestOpticalMeasurementId}/details`)
            .catch(error => console.log(error))
            .then((json: any) => {
            setMeasurementsDetails(json);
        });
        if (props.viewType == TemplateView.Edit) {
            Get(`contactLensesOrder/${props.orderId}`)
            .then((json) => {
                setContactLensesData(json);
            });
        }
        if (props.viewType == TemplateView.CreateNew){
            setContactLensesData({...contactLensesData, 
                od_sph: "",
                od_cyl: "",
                od_ax: "",
                od_add: "",
                os_sph: "",
                os_cyl: "",
                os_ax: "",
                os_add: "",
                period: "",
                manufacturer: "",
                product: "",
                additionalInformation: ""
            });
        }
    }, []);

    const odDetails = [
        { value: undefinedChecker(contactLensesData, "od_sph"), newValue: (newValue) => setContactLensesData({ ...contactLensesData, od_sph: newValue }) },
        { value: undefinedChecker(contactLensesData, "od_cyl"), newValue: (newValue) => setContactLensesData({ ...contactLensesData, od_cyl: newValue }) },
        { value: undefinedChecker(contactLensesData, "od_ax"), newValue: (newValue) => setContactLensesData({ ...contactLensesData, od_ax: newValue }) },
        { value: undefinedChecker(contactLensesData, "od_add"), newValue: (newValue) => setContactLensesData({ ...contactLensesData, od_add: newValue }) },
    ];

    const osDetails = [
        { value: undefinedChecker(contactLensesData, "os_sph"), newValue: (newValue) => setContactLensesData({ ...contactLensesData, os_sph: newValue }) },
        { value: undefinedChecker(contactLensesData, "os_cyl"), newValue: (newValue) => setContactLensesData({ ...contactLensesData, os_cyl: newValue }) },
        { value: undefinedChecker(contactLensesData, "os_ax"), newValue: (newValue) => setContactLensesData({ ...contactLensesData, os_ax: newValue }) },
        { value: undefinedChecker(contactLensesData, "os_add"), newValue: (newValue) => setContactLensesData({ ...contactLensesData, os_add: newValue }) },
    ];

    const tableHeader = [
        { label: "sph", tooltip: "до +/-3dpt не се преизчислява,проверете начина на преизчисляване според производителя" },
        { label: "cyl", tooltip: "проверете начина на преизчисляване според производителя, желателно е компетентно напасване на торични контактни лещи" },
        { label: "ax", tooltip: "проверете на какви градуси работи производителя, препоръчително е компетентно напасване на контактни лещи" },
        { label: "add", tooltip: "проверете вида на добавките според производителя, желателно е компетентно напасване" }
    ];

    const product = {
        label: "Продукт", tooltip: "всеки вид контактни лещи има параметри: базова кривина и диаметър"
    }

    const periods = [
        { label: "day", text: "Дневна", period: PeriodLength.Day, tooltip: "" },
        { label: "month", text: "Месечна", period: PeriodLength.Month, tooltip: "" },
        { label: "threeMonths", text: "3 Месечна", period: PeriodLength.ThreeMonths, tooltip: "не е препоръчително носене за повече от 6 часа дневно, ако клиентът смята да ползва лещите на 6часа дневно, препоръчайте месечни или еднодневни контактни лещи! има риск от хипоксия!" },
        { label: "year", text: "Годишна", period: PeriodLength.Year, tooltip: "не е препоръчително носене за повече от 6 часа дневно, ако клиентът смята да ползва лещите на 6 часа дневно, препоръчайте месечни или еднодневни контактни лещи! има риск от хипоксия!" }
    ];

    const contactLensesBrands = [
        { text: "Alcon", brand: ContactLensesBrand.Alcon },
        { text: "B & L", brand: ContactLensesBrand.BL },
        { text: "CMI", brand: ContactLensesBrand.CMI },
        { text: "Cooper Vision", brand: ContactLensesBrand.CooperVision },
    ];
    
    const saveDataObject = () => {
        orderData = {
            ...contactLensesData,
            companyId: companyId,
            facilityId: facilityId,
            employeeId: employeeId,
            customerId: customerId,
            opticalMeasurementId: props.latestOpticalMeasurementId,
            productDetails: productDetails(contactLensesData, ProductDetailsType.ContactLenses),
            osDioptersDetails: osDioptersDetails("Комбинирано", contactLensesData),
            odDioptersDetails: odDioptersDetails("Комбинирано", contactLensesData),
        };

        Create(orderData, "contactLensesOrder", `${ClientRoute.CustomerDetails}?id=${customerId}`);
    }

    const updateDataObject = () => {
        orderData = {
            ...contactLensesData,
            employeeId: employeeId,
            id: props.orderId,
            productDetails: productDetails(contactLensesData, ProductDetailsType.ContactLenses),
            osDioptersDetails: osDioptersDetails("Комбинирано", contactLensesData),
            odDioptersDetails: odDioptersDetails("Комбинирано", contactLensesData),
        };
        
        Update(orderData, "contactLensesOrder", `${ClientRoute.CustomerDetails}?id=${measurementsDetails.clientId}`);
    }

    const deleteDataObject = () => {
        Delete(`order/${props.orderId}`);
        Delete(`contactLensesOrder/${props.orderId}`, `${ClientRoute.CustomerDetails}?id=${measurementsDetails.clientId}`);
    }

    const renderButtons = (): JSX.Element => {
        switch (templateType) {
            case TemplateView.CreateNew: {
                return <>
                    <Button color="success" onClick={saveDataObject}>Запази</Button>
                </>
            }
            case TemplateView.Edit: {
                return <>
                    {props.orderStatus == OrderStatus.Processing && 
                        <>
                            <Button color="success" onClick={updateDataObject}>Запази</Button>
                            <Button color="danger" onClick={deleteDataObject}>Изтрий Поръчка</Button>
                        </>
                    }
                    {props.orderStatus == OrderStatus.Requested && 
                    <>
                        <RedirectButton 
                            className="optileader-teal-background white-font-color" 
                            buttonText="Обратно към поръчки" 
                            url={ClientRoute.Index} 
                            />
                        <RedirectButton 
                            className="optileader-teal-background white-font-color" 
                            buttonText="Редактирай" 
                            url={`${ClientRoute.EditContactLensesOrder}?opticalMeasurementId=${props.latestOpticalMeasurementId}&orderId=${props.orderId}&orderStatus=${OrderStatus.Processing}`} 
                        />
                    </>
                    }
                </>
            }
            default: {
                return <span>Template Type Unknown</span>
            }
        }
    }

    return <>
        <Card className="bg-secondary shadow mb-1">
            <CardBody className="py-3">
                <h6 className="heading-small text-muted f-size-16 mb-0">
                    Клиент
                    {measurementsDetails.customerName && 
                        <span className="mr-4"> - {measurementsDetails.customerName} </span>
                    }
                    {measurementsDetails.customerPhone && 
                        <span className="ml-4">Телефон - {measurementsDetails.customerPhone}</span>
                    }
                </h6>
            </CardBody>
        </Card>
        <Card className="bg-secondary shadow mb-1">
            <CardBody className="py-3">
                <h6 className="heading-small text-muted f-size-16">
                    Рецепта
                    {measurementsDetails.prescribedBy && 
                        <> - Предписал: {measurementsDetails.prescribedBy}</>
                    }
                </h6>
                <Row>
                    <Col m={8} lg={8}>
                        <MeasurementsTableTemplate viewType={TemplateView.View} templateData={measurementsDetails} />
                    </Col>
                </Row>
            </CardBody>
        </Card>
        <Card className="bg-secondary shadow">
            <CardBody className="py-3">
                <h6 className="heading-small text-muted f-size-16 mb-0">
                    Лещи
                </h6>
                <Table className="table-border-blue contact-lenses-table my-3" bordered>
                    <thead>
                        <tr>
                            <th></th>
                            {tableHeader.map((data, index) =>
                                <th className="f-size-16" key={index}>
                                    {data.label}
                                    <TooltipItem item={data} />
                                </th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th className="f-size-16 w-20" scope="row">OD</th>
                            {odDetails.map((data, index) =>
                                <td className="w-20" key={index}>
                                    {props.orderStatus == OrderStatus.Processing ? 
                                        <Input
                                            className={`f-size-18`}
                                            type="text"
                                            maxLength={6}
                                            defaultValue={data.value}
                                            onChange={e => odDetails[index].newValue(e.target.value)}
                                        />
                                    :
                                        <div className="f-size-18">{data.value}</div>
                                    }
                                </td>
                            )}
                        </tr>
                        <tr>
                            <th className="f-size-16" scope="row">OS</th>
                            {osDetails.map((data, index) =>
                                <td key={index}>
                                    {props.orderStatus == OrderStatus.Processing ?
                                        <Input
                                            className={`f-size-18`}
                                            type="text"
                                            maxLength={6}
                                            defaultValue={data.value}
                                            onChange={e => osDetails[index].newValue(e.target.value)}
                                        />
                                    :
                                        <div className="f-size-18">{data.value}</div>
                                    }
                                </td>
                            )}
                        </tr>
                        <tr>
                            <th className="f-size-16" scope="row">Период</th>
                            <td colSpan={4}>
                            {props.orderStatus == OrderStatus.Processing ?
                                <ButtonGroup className="d-flex justify-content-around contact-lenses-buttons">
                                    {periods.map((data, index) =>
                                        <Button
                                            key={index}
                                            color="primary"
                                            className="w-23-5"
                                            onClick={() => setContactLensesData({ ...contactLensesData, period: data.period })}
                                            active={contactLensesData.period === data.period}>
                                            {data.text}
                                            {data.tooltip && <TooltipItem item={data} />}
                                        </Button>
                                    )}
                                </ButtonGroup>
                            :
                                <>
                                    {contactLensesData.period && 
                                        <div className="f-size-18">{periods.find(x => x.period == contactLensesData.period).text}</div>
                                    }
                                </>
                            }
                            </td>
                        </tr>
                        <tr>
                            <th className="f-size-16" scope="row">Производител</th>
                            {props.orderStatus == OrderStatus.Processing ?
                            <>
                                <td colSpan={3}>
                                    <ButtonGroup className="d-flex justify-content-around contact-lenses-buttons">
                                        {contactLensesBrands.map((data, index) =>
                                            <Button
                                                key={index}
                                                color="primary"
                                                onClick={() => setContactLensesData({ ...contactLensesData, manufacturer: data.brand })}
                                                active={contactLensesData.manufacturer === data.brand}>
                                                {data.text}
                                            </Button>
                                        )}
                                    </ButtonGroup>
                                </td>
                                <td colSpan={1}>
                                    <Input
                                        className={`f-size-18`}
                                        type="text"
                                        defaultValue={contactLensesData.manufacturer}
                                        onChange={e => setContactLensesData({ ...contactLensesData, manufacturer: e.target.value })}
                                    />
                                </td>
                            </>
                            :
                            <>
                                <td colSpan={4}>
                                    {contactLensesData.manufacturer && 
                                        <div className="f-size-18">{contactLensesData.manufacturer}</div>
                                    }
                                </td>
                            </>
                            }
                        </tr>
                        <tr>
                            <th className="f-size-16" scope="row">
                                {product.label}
                                <TooltipItem item={product} />
                            </th>
                            <td colSpan={4}>
                                {props.orderStatus == OrderStatus.Processing ?
                                    <Input
                                        className={`f-size-18`}
                                        type="text"
                                        placeholder="Други"
                                        defaultValue={contactLensesData.product}
                                        onChange={e => setContactLensesData({ ...contactLensesData, product: e.target.value})}
                                    />
                                    :
                                    <>
                                        {contactLensesData.product && 
                                            <div className="f-size-18">{contactLensesData.product}</div>
                                        }
                                    </>
                                }
                            </td>
                        </tr>
                    </tbody>
                </Table>
                {templateType !== TemplateView.View && props.orderStatus == OrderStatus.Processing ?
                    <Row>
                        <Col lg="2">
                            <FormGroup className="mb-3">
                                <label className="form-control-label mb-1">
                                    Код лещи
                                </label>
                                <Input
                                    className="form-control-alternative"
                                    type="text"
                                    defaultValue={contactLensesData.lensesCode}
                                    onChange={e => setContactLensesData({ ...contactLensesData, lensesCode: e.target.value.replace(",", ".")})}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    : 
                    <FormGroup className="mb-4 mt-4">
                        <label className="form-control-label mb-1">
                            Код лещи {contactLensesData.lensesCode ? `- ${contactLensesData.lensesCode}` : ""}
                        </label>
                    </FormGroup>
                }
                <FormGroup>
                    <h6 className="heading-small text-muted f-size-16">Допълнителна информация</h6>
                    {templateType !== TemplateView.View ?
                        <>
                            {contactLensesData.additionalInformation != undefined &&
                            <Input 
                                type="textarea" 
                                name="text"
                                resize="none"
                                defaultValue={contactLensesData.additionalInformation}
                                onChange={e => setContactLensesData({ ...contactLensesData, additionalInformation: e.target.value})}/>
                            }
                        </>
                        :
                        <>
                            <hr className="my-2" />
                            <div className="f-size-16 text-left">{contactLensesData.additionalInformation}</div>
                        </>
                    }
                </FormGroup>
                {contactLensesData.employeeName && 
                    <h6 className="heading-small text-muted f-size-16">Създал: {contactLensesData.employeeName}</h6>
                }
                {renderButtons()}
            </CardBody>
        </Card>
    </>
}

export default ContactLensesOrderTemplate;