
import React from "react";
import { TemplateView } from '../../../models/enums/TemplateView';
import ContactTemplate from "./ContactTemplate";

const AddContact = (): JSX.Element => {

  return <>
    <ContactTemplate viewType={TemplateView.CreateNew}/>
  </>
}

export default AddContact;
