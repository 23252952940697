import { MeasurementsDetails } from "../models/measurementDetails";
import { isNullChecker, isNullCheckerNumber } from "./Checkers";
import numeral from 'numeral';

export const odDioptersDetails = (distanceType: string, measurements: any): string => {

    switch (distanceType) {
        case "Далече": {
            return `{
                "od_sph": "${isNullChecker(measurements.od_sph)}",
                "od_cyl": "${isNullChecker(measurements.od_cyl)}",
                "od_ax": "${isNullChecker(measurements.od_ax)}",
                "od_add": "",
                "od_prism": "${isNullChecker(measurements.od_prism)}",
                "od_base": "${isNullChecker(measurements.od_base)}"
            }`;
        }
        case "Близо": {
            let od_sph_close = isNullCheckerNumber(measurements.od_sph) + isNullCheckerNumber(measurements.od_add);
            
            let od_sph = od_sph_close === 0 ? "" : od_sph_close;

            return `{
                "od_sph": "${numeral(od_sph).format("+0.00")}",
                "od_cyl": "${isNullChecker(measurements.od_cyl)}",
                "od_ax": "${isNullChecker(measurements.od_ax)}",
                "od_add": "",
                "od_prism": "${isNullChecker(measurements.od_prism)}",
                "od_base": "${isNullChecker(measurements.od_base)}"
            }`;
        }
        case "Комбинирано": {
            return `{
                "od_sph": "${isNullChecker(measurements.od_sph)}",
                "od_cyl": "${isNullChecker(measurements.od_cyl)}",
                "od_ax": "${isNullChecker(measurements.od_ax)}",
                "od_add": "${isNullChecker(measurements.od_add)}",
                "od_prism": "${isNullChecker(measurements.od_prism)}",
                "od_base": "${isNullChecker(measurements.od_base)}"
            }`;
        }
        default: {
            return "";
        }
    }
}

