import { LensesData } from "../views/client-area/Order/Order-Components/Lenses";
import { ContactLensesOrderData } from "../models/contactLensesOrderData";
import { isNullOrWhiteSpace } from "./Checkers";

export enum ProductDetailsType {
    Glasses,
    ContactLenses
}

export const productDetails = (productData, productType: ProductDetailsType): string => {
    
    switch(productType) {
        case ProductDetailsType.Glasses: {

            let data: LensesData = productData;
            
            return `
            ${isNullOrWhiteSpace(data.product, ",")}
            ${isNullOrWhiteSpace(data.materialType, ",")}
            ${isNullOrWhiteSpace(data.designType, ",")}
            ${isNullOrWhiteSpace(data.coating, ",")}
            ${isNullOrWhiteSpace(data.fotoTechnologyType, ",")}
            ${isNullOrWhiteSpace(data.coloring, ",")}
            ${isNullOrWhiteSpace(data.lensDiameter)}
            `;
        }
        case ProductDetailsType.ContactLenses: {

            let contactLensesdata: ContactLensesOrderData = productData;
            let period: string;
            
            switch(contactLensesdata.period) {
                case "Day": {
                    period = "Дневни";
                    break;
                }
                case "Month": {
                    period = "Месечни";
                    break;
                }
                case "ThreeMonths": {
                    period = "Три Месеца";
                    break;
                }
                case "Year": {
                    period = "Годишни";
                    break;
                }
                default: {
                    period = "";
                    break;
                }
            }
            
            return `${isNullOrWhiteSpace(contactLensesdata.manufacturer == "BL" ? "B & L" : contactLensesdata.manufacturer, ",")}
            ${isNullOrWhiteSpace(contactLensesdata.product, ",")}
            ${isNullOrWhiteSpace(period)}
            `;
        }

        default: {
            return "";
        }
    }
}
