import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Table, Pagination, PaginationItem, Row } from "reactstrap";
import PageLoader from "../../components/pageLoader";
import { formatDate } from "../../helpers/Formatters";
import { serverAddress } from "../../helpers/requests";
import { TemplateView } from "../../models/enums/TemplateView";
import { MeasurementsDetails } from "../../models/measurementDetails";
import { ClientRoute } from '../../routes';
import { UserContext } from "../../userContext";
import MeasurementsTableTemplate from "./OpticalMeasurement/MeasurementsTableTemplate";

export type MeasurementListItem = {
  id?: string;
  dateCreated?: string;
  employeeName: string;
  measurementDetails?: MeasurementsDetails;
  clientName: string;
}

const EmployeeRefractions = (): JSX.Element => {
  
    const {objectData} = useContext(UserContext);
    const [loading, setLoading] = useState(true);
    const [measurementsDetails, setMeasurementsDetails] = useState<any>();
    const [pageResults, setPageResults] = useState(0);
    const employeeId = objectData.getObjectData.id;

    const loadData = (apiUrl: string) => {
      (async () => {
        const rawResponse = await fetch(`${serverAddress}/${apiUrl}`, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        });
        const content = await rawResponse.json();
        
        setMeasurementsDetails(content);
        setLoading(false);
        if(content.results){
          setPageResults(content.results.length);
        }
      })();
    }

    const paginate = (currentPage: number) => {
      loadData(`opticalMeasurements/refractions/employee/${employeeId}?pageSize=25&currentPage=${currentPage}`);
    }

    const history = useHistory();

    const redirectToPage = (link: string): void => {
      history.push(link);
    }

    const redirectRoute = (data: any): string => {
      return data.measurementDetails.refractionProtocolId.length 
      ?
      `${ClientRoute.ViewRefraction}?id=${data.measurementDetails.refractionProtocolId}` 
      : 
      `${ClientRoute.EditOpticalMeasurement}?id=${data.id}`;
    }

    useEffect(() => {
      loadData(`opticalMeasurements/refractions/employee/${employeeId}`);
    },[]);

  return <> {loading ? <PageLoader></PageLoader> : <>
      <Row className="p-3">
        <span className="d-flex align-items-center pr-4">Резултати: {pageResults} от {measurementsDetails.recordCount}</span>
        <Pagination className="d-flex justify-content-center">
          {Array.apply(null, Array(measurementsDetails.pageCount)).map((i, index)=>
            <PaginationItem key={index}>
              <div className="page-link" onClick={() => paginate(index+1)}>
                {index+1}
              </div>
            </PaginationItem>
          )}
        </Pagination>
      </Row>
      <Table className="align-items-center table-flush table-striped" responsive>
        <thead className="silver-background black-font-color">
          <tr className="text-center">
            <th className="f-size-16" scope="col">Детайли</th>
            <th className="f-size-16" scope="col">Измерване</th>
            <th className="f-size-16" scope="col">Предписал</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {measurementsDetails.results.map((data, index) =>
            <tr className="text-center cursor-pointer" key={index} onClick={() => redirectToPage(redirectRoute(data))}>
              <td className="f-size-16">
                <div className="d-flex align-items-center justify-content-center">
                  <div className="text-right mr-2">Дата: <br/> Клиент: </div>
                  <div className="text-left">{formatDate(data.dateCreated)} <br/> {data.clientName} </div>
                </div>
              </td>
              <td className="f-size-16 customer-measurements-list">
                <MeasurementsTableTemplate viewType={TemplateView.View} templateData={data.measurementDetails} />
              </td>
              <td className="f-size-16">{data.measurementDetails.prescribedBy}</td>
            </tr>
          )}
        </tbody>
      </Table>
      <Pagination className="d-flex justify-content-center">
        {Array.apply(null, Array(measurementsDetails.pageCount)).map((i, index)=>
          <PaginationItem key={index}>
            <div className="page-link" onClick={() => paginate(index+1)}>
              {index+1}
            </div>
          </PaginationItem>
        )}
      </Pagination>
    </>
  }
</>
}

export default EmployeeRefractions;