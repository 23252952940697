import React from 'react';
import { TemplateView } from '../../../models/enums/TemplateView';
import ApiResource from '../../../helpers/ApiResource';
import CustomerTemplate from './CustomerTemplate';
import { CustomerViewModel } from './CustomerDetails';

const EditCustomer = (props) => {

  var url = new URL(window.location.href);
  var id = url.searchParams.get("id");
  
  return <>
    <ApiResource url={`customer/${id}`}>
        {(templateData: CustomerViewModel) => <>
            <CustomerTemplate viewType={TemplateView.Edit} templateData={templateData.customerDetails}/>
        </>}
    </ApiResource>
  </>
}

export default EditCustomer;