import React from "react";
import EmployeeTemplate from './EmployeeTemplate';
import { TemplateView } from '../../../models/enums/TemplateView';
import ApiResource from "../../../helpers/ApiResource";
import { EmployeeDetailsData } from "../../../models/employeeDetailsData";

const EmployeeDetails = (props): JSX.Element => {

    var url = new URL(window.location.href);
    var id = url.searchParams.get("id");
    
    return <>
        <ApiResource url={`employee/${id}`}>
            {(employeeData: EmployeeDetailsData) => <>
                <EmployeeTemplate viewType={TemplateView.View} templateData={employeeData}/>
            </>}
        </ApiResource>
    </>
}

export default EmployeeDetails;
