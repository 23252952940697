import React from 'react';
import CompanyTemplate from './CompanyTemplate';
import { TemplateView } from '../../../models/enums/TemplateView';

const AddCompany = (props) => {

  return <>
    <CompanyTemplate viewType={TemplateView.CreateNew}/>
  </>
}

export default AddCompany;