import React, { useContext, useEffect, useState } from "react";
import { Pagination, PaginationItem, Row } from "reactstrap";
import { serverAddress } from "../../helpers/requests";
import { UserContext } from "../../userContext";
import OrdersTableTemplate from "../../components/OrdersTableTemplate";
import PageLoader from "../../components/pageLoader";

const EmployeeOrders = () => {
  const {objectData} = useContext(UserContext);

  const [data, setData] = useState<any>();
  const [reload, setReload] = useState(false);
  const [pageResults, setPageResults] = useState(0);

  const loadData = (apiUrl: string) => {
    (async () => {
      const rawResponse = await fetch(`${serverAddress}/${apiUrl}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      });
      const content = await rawResponse.json();
      
      setData(content);
      setReload(false);
      if(content.results){
        setPageResults(content.results.length);
      }
    })();
  }
  
  const employeeId = objectData.getObjectData.id;
    
  useEffect(() => {
    loadData(`order/${employeeId}/employee`);
  },[reload]);

  const reloadTableData = (newValue) => {
    setReload(newValue);
  }

  const paginate = (currentPage: number) => {
    loadData(`order/${employeeId}/employee?pageSize=25&currentPage=${currentPage}`);
  }
  
  return <>
    {data
        ?
          <>
            <Row className="p-3">
              <span className="d-flex align-items-center pr-4">Резултати: {pageResults} от {data.recordCount}</span>
              <Pagination className="d-flex justify-content-center">
                {Array.apply(null, Array(data.pageCount)).map((i, index)=>
                  <PaginationItem key={index}>
                    <div className="page-link" onClick={() => paginate(index+1)}>
                      {index+1}
                    </div>
                  </PaginationItem>
                )}
              </Pagination>
            </Row>
            <OrdersTableTemplate loading={false} data={data.results} reload={reloadTableData}/>
            <Pagination className="d-flex justify-content-center">
              {Array.apply(null, Array(data.pageCount)).map((i, index)=>
                <PaginationItem key={index}>
                  <div className="page-link" onClick={() => paginate(index+1)}>
                    {index+1}
                  </div>
                </PaginationItem>
              )}
            </Pagination>
          </>
        :
        <PageLoader></PageLoader>
      }
    
  </>
}

export default EmployeeOrders;
