import React, { useContext, useState } from 'react';
import { UserContext } from '../userContext';
import { LoginUser } from './loginUser';
import { Get } from './requests';

const GetUserRole = () => {

    const {objectData} = useContext(UserContext);
    const [userRole, setUserRole] = useState();

    if(!objectData.getObjectData) {

        var token = localStorage.getItem('v');

        if(!token) {window.location.href = "/auth/login";}

        Get(`login/${token}`)
            .catch(error => console.log(error))
            .then((json: any) => {
            LoginUser(json);
            objectData.setObjectData(json);
            setUserRole(json.role);
        });
    } else {
        return objectData.getObjectData.role;
    }
    
    return userRole;
}

export default GetUserRole;