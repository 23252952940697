import React, { useEffect, useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import { TemplateView } from '../../../models/enums/TemplateView';
import { ClientRoute } from '../../../routes';
import {  Get } from '../../../helpers/requests';
import { MeasurementsDetails } from '../../../models/measurementDetails';
import CommonConditionTemplate from './CommonConditionTemplate';
import LenseMeasurementsTemplate from './LenseMeasurementsTemplate';
import LastUsedLenses from './LastUsedLenses';
import ConsultWithSpecialist from './ConsultWithSpecialist';
import LensesType from './LensesType';
import { RefractionProtocol } from '../../../models/refractionProtocol';
import PageLoader from '../../../components/pageLoader';
import RedirectButton from '../../../components/Buttons/RedirectButton';
import OtherObservationsTemplate from './OtherObservationsTemplate';

type MeasurementsDetailsProps = {
    templateData?: MeasurementsDetails;
    populateData?: any;
    viewType: TemplateView;
}

const ViewRefraction = (props: MeasurementsDetailsProps) => {
    
    var url = new URL(window.location.href);
    var id = url.searchParams.get("id");
    const [refractionProtocolData, setRefractionProtocolData] = useState({} as RefractionProtocol);
    const [loading, setLoading] = useState(true);
    const refractionId = id;

    useEffect(() => {      
      if(refractionId){
        Get(`refraction/${refractionId}`)
        .catch(error => console.log(error))
        .then((json: RefractionProtocol) => {  
          if(json){
            setRefractionProtocolData(json);
          }
          setLoading(false);    
        });
      } 
    },[]);
 
    return <> {loading ? <PageLoader></PageLoader> : <>
      <Card className="bg-secondary shadow mb-4">
        <CardBody className="py-3">
          <h6 className="heading-small text-muted f-size-16 mb-0">
            Общо състояние
          </h6>
          <CommonConditionTemplate viewType={TemplateView.View} templateData={refractionProtocolData}/>
        </CardBody>
      </Card>
      <Card className="bg-secondary shadow mb-4">
        <CardBody className="py-3">
          <h6 className="heading-small text-muted f-size-16 mb-0">
            Ново измерване
          </h6>
          <LenseMeasurementsTemplate className="mt-3 lenses-table" viewType={TemplateView.View} templateData={refractionProtocolData.newMeasurement}/>
          <ConsultWithSpecialist className="mt-4" viewType={TemplateView.View} templateData={refractionProtocolData.consultWithSpecialist}/>
        </CardBody>
      </Card>
      <Card className="bg-secondary shadow mb-4">
        <CardBody className="py-3">
          <h6 className="heading-small text-muted f-size-16 mb-0">
            Други наблюдения
          </h6>
          <OtherObservationsTemplate className="mt-4 lenses-table" viewType={TemplateView.View} templateData={refractionProtocolData}/>
          <ConsultWithSpecialist className="mt-4" viewType={TemplateView.View} templateData={refractionProtocolData.consultWithSpecialist}/>
        </CardBody>
      </Card>
      <Card className="bg-secondary shadow mb-4">
        <CardBody className="py-3">
          <h6 className="heading-small text-muted f-size-16 mb-0">
            Последно ползвани очила
          </h6>
          <LastUsedLenses className="mt-3 lenses-table" viewType={TemplateView.View} templateData={refractionProtocolData.latestMeasurement}/>
          <LensesType className="mt-4" viewType={TemplateView.View} templateData={refractionProtocolData.lastUsedGlassesType}/>
        </CardBody>
      </Card>
      <Card className="bg-secondary shadow mb-4">
        <CardBody>
            <h6 className="heading-small text-muted f-size-16 text-left">Допълнителна информация</h6>
            <div className="f-size-16 text-left mb-4">{refractionProtocolData.additionalInformation}</div>
          <RedirectButton 
              className="optileader-teal-background white-font-color" 
              buttonText="Обратно към измервания" 
              url={`${ClientRoute.CustomerDetails}?id=${refractionProtocolData.clientId}`}
              />
          <RedirectButton 
              className="optileader-teal-background white-font-color" 
              buttonText="Редактирай" 
              url={`${ClientRoute.EditRefraction}?id=${refractionProtocolData.id}`}
              />
        </CardBody>
      </Card>
  </>
}
</>
}

export default ViewRefraction;