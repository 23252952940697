import React, { useState, useEffect } from 'react';
import { Table, Card, CardBody, Row, Col, FormGroup } from 'reactstrap';
import RedirectButton from '../../../components/Buttons/RedirectButton';
import TooltipItem from '../../../components/TooltipItem';
import { undefinedChecker } from '../../../helpers/Checkers';
import { GetObjectData } from '../../../helpers/objectContextManager';
import { Get } from '../../../helpers/requests';
import { ContactLensesOrderData } from '../../../models/contactLensesOrderData';
import { OrderStatus } from '../../../models/enums/OrderStatus';
import { TemplateView } from '../../../models/enums/TemplateView';
import { MeasurementsDetails } from '../../../models/measurementDetails';
import { ClientRoute } from '../../../routes';
import MeasurementsTableTemplate from '../OpticalMeasurement/MeasurementsTableTemplate';

export enum PeriodLength {
    Day = "Day",
    Month = "Month",
    ThreeMonths = "ThreeMonths",
    Year = "Year"
}

export enum ContactLensesBrand {
    Alcon = "Alcon",
    CooperVision = "CooperVision",
    BL = "BL",
    CMI = "CMI"
}

type ContactLensesProps = {
    viewType: TemplateView;
    orderId?: string;
    latestOpticalMeasurementId?: string;
    orderStatus?: OrderStatus;
}

const ContactLensesOrderDetails = (props: ContactLensesProps) => {

    var url = new URL(window.location.href);
    var opticalMeasurementId = url.searchParams.get("opticalMeasurementId");
    var orderId = url.searchParams.get("orderId");

    const [contactLensesData, setContactLensesData] = useState({} as ContactLensesOrderData);
    const [measurementsDetails, setMeasurementsDetails] = useState({} as MeasurementsDetails);

    useEffect(() => {
        Get(`opticalMeasurements/${opticalMeasurementId}/details`)
            .catch(error => console.log(error))
            .then((json: any) => {
            setMeasurementsDetails(json);
        });
        Get(`contactLensesOrder/${orderId}`)
        .then((json) => {
            setContactLensesData(json);
        });
    }, []);

    const odDetails = [
        { value: undefinedChecker(contactLensesData, "od_sph"), newValue: (newValue) => setContactLensesData({ ...contactLensesData, od_sph: newValue }) },
        { value: undefinedChecker(contactLensesData, "od_cyl"), newValue: (newValue) => setContactLensesData({ ...contactLensesData, od_cyl: newValue }) },
        { value: undefinedChecker(contactLensesData, "od_ax"), newValue: (newValue) => setContactLensesData({ ...contactLensesData, od_ax: newValue }) },
        { value: undefinedChecker(contactLensesData, "od_add"), newValue: (newValue) => setContactLensesData({ ...contactLensesData, od_add: newValue }) },
    ];

    const osDetails = [
        { value: undefinedChecker(contactLensesData, "os_sph"), newValue: (newValue) => setContactLensesData({ ...contactLensesData, os_sph: newValue }) },
        { value: undefinedChecker(contactLensesData, "os_cyl"), newValue: (newValue) => setContactLensesData({ ...contactLensesData, os_cyl: newValue }) },
        { value: undefinedChecker(contactLensesData, "os_ax"), newValue: (newValue) => setContactLensesData({ ...contactLensesData, os_ax: newValue }) },
        { value: undefinedChecker(contactLensesData, "os_add"), newValue: (newValue) => setContactLensesData({ ...contactLensesData, os_add: newValue }) },
    ];

    const tableHeader = [
        { label: "sph", tooltip: "до +/-3dpt не се преизчислява,проверете начина на преизчисляване според производителя" },
        { label: "cyl", tooltip: "проверете начина на преизчисляване според производителя, желателно е компетентно напасване на торични контактни лещи" },
        { label: "ax", tooltip: "проверете на какви градуси работи производителя, препоръчително е компетентно напасване на контактни лещи" },
        { label: "add", tooltip: "проверете вида на добавките според производителя, желателно е компетентно напасване" }
    ];

    const product = {
        label: "Продукт", tooltip: "всеки вид контактни лещи има параметри: базова кривина и диаметър"
    }

    const periods = [
        { label: "day", text: "Дневна", period: PeriodLength.Day, tooltip: "" },
        { label: "month", text: "Месечна", period: PeriodLength.Month, tooltip: "" },
        { label: "threeMonths", text: "3 Месечна", period: PeriodLength.ThreeMonths, tooltip: "не е препоръчително носене за повече от 6 часа дневно, ако клиентът смята да ползва лещите на 6часа дневно, препоръчайте месечни или еднодневни контактни лещи! има риск от хипоксия!" },
        { label: "year", text: "Годишна", period: PeriodLength.Year, tooltip: "не е препоръчително носене за повече от 6 часа дневно, ако клиентът смята да ползва лещите на 6 часа дневно, препоръчайте месечни или еднодневни контактни лещи! има риск от хипоксия!" }
    ];

    const renderButtons = (): JSX.Element => {
        return <>
            <RedirectButton 
                className="optileader-teal-background white-font-color" 
                buttonText="Обратно към поръчки" 
                url={ClientRoute.Index} 
                />
            <RedirectButton 
                className="optileader-teal-background white-font-color" 
                buttonText="Редактирай" 
                url={`${ClientRoute.EditContactLensesOrder}?opticalMeasurementId=${opticalMeasurementId}&orderId=${orderId}&orderStatus=${OrderStatus.Processing}`}  
                />
        </>
    }

    return <>
        <Card className="bg-secondary shadow mb-4">
            <CardBody className="py-3">
                <h6 className="heading-small text-muted f-size-16 mb-0">
                    Клиент
                    {measurementsDetails.customerName && 
                        <span className="mr-4"> - {measurementsDetails.customerName} </span>
                    }
                    {measurementsDetails.customerPhone && 
                        <span className="ml-4">Телефон - {measurementsDetails.customerPhone}</span>
                    }
                </h6>
            </CardBody>
        </Card>
        <Card className="bg-secondary shadow mb-4">
            <CardBody className="py-3">
                <h6 className="heading-small text-muted f-size-16">
                    Рецепта
                    {measurementsDetails.prescribedBy && 
                        <> - Предписал: {measurementsDetails.prescribedBy}</>
                    }
                </h6>
                <Row>
                    <Col m={8} lg={8}>
                        <MeasurementsTableTemplate viewType={TemplateView.View} templateData={measurementsDetails} />
                    </Col>
                </Row>
            </CardBody>
        </Card>
        <Card className="bg-secondary shadow">
            <CardBody className="py-3">
                <h6 className="heading-small text-muted f-size-16 mb-0">
                    Лещи
                </h6>
                <Table className="table-border-blue contact-lenses-table my-3" bordered>
                    <thead>
                        <tr>
                            <th></th>
                            {tableHeader.map((data, index) =>
                                <th className="f-size-16" key={index}>
                                    {data.label}
                                    <TooltipItem item={data} />
                                </th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th className="f-size-16 w-20" scope="row">OD</th>
                            {odDetails.map((data, index) =>
                                <td className="w-20" key={index}>
                                    <div className="f-size-18">{data.value}</div>
                                </td>
                            )}
                        </tr>
                        <tr>
                            <th className="f-size-16" scope="row">OS</th>
                            {osDetails.map((data, index) =>
                                <td key={index}>
                                    <div className="f-size-18">{data.value}</div>
                                </td>
                            )}
                        </tr>
                        <tr>
                            <th className="f-size-16" scope="row">Период</th>
                            <td colSpan={4}>
                                <>
                                    {contactLensesData.period && 
                                        <div className="f-size-18">{periods.find(x => x.period == contactLensesData.period).text}</div>
                                    }
                                </>
                            </td>
                        </tr>
                        <tr>
                            <th className="f-size-16" scope="row">Производител</th>
                            <>
                                <td colSpan={4}>
                                    {contactLensesData.manufacturer && 
                                        <div className="f-size-18">{contactLensesData.manufacturer}</div>
                                    }
                                </td>
                            </>
                        </tr>
                        <tr>
                            <th className="f-size-16" scope="row">
                                {product.label}
                                <TooltipItem item={product} />
                            </th>
                            <td colSpan={4}>
                                <>
                                    {contactLensesData.product && 
                                        <div className="f-size-18">{contactLensesData.product}</div>
                                    }
                                </>
                            </td>
                        </tr>
                    </tbody>
                </Table>
                <FormGroup className="mb-4 mt-4">
                    <label className="form-control-label mb-1">
                        Код лещи {contactLensesData.lensesCode ? `- ${contactLensesData.lensesCode}` : ""}
                    </label>
                </FormGroup>
                <FormGroup>
                    <>
                        <h6 className="heading-small text-muted f-size-16">Допълнителна информация</h6>
                        <hr className="my-2" />
                        <div className="f-size-16 text-left">{contactLensesData.additionalInformation}</div>
                    </>
                </FormGroup>
                {contactLensesData.employeeName && 
                    <h6 className="heading-small text-muted f-size-16">Създал: {contactLensesData.employeeName}</h6>
                }
                {renderButtons()}
            </CardBody>
        </Card>
    </>
}

export default ContactLensesOrderDetails;