import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Table, Button, ButtonGroup, Input } from 'reactstrap';
import { TemplateView } from '../../../models/enums/TemplateView';
import { CommonCondition }  from '../../../models/commonCondition';
import { OtherObservations } from '../../../models/otherObservations';

type OtherObservationsTemplateProps = {
    templateData?: any;
    setOtherObservationData?: Dispatch<SetStateAction<OtherObservations>>;
    viewType: TemplateView;
    className?: string;
}

const OtherObservationsTemplate = (props: OtherObservationsTemplateProps) => {

    const inputData = props.templateData ? props.templateData : {} as CommonCondition;
    const [otherObservationsData, setOtherObservationObjectData] = useState({} as OtherObservations);
    const tableStylePerView = props.viewType == TemplateView.View ? "other-observations-table transparent-background mb-2" : "other-observations-table mb-2";
    const tableStyleClasses = `${tableStylePerView} ${props.className}`;

    const foriesType = ["Езофория", "Екзофория", "Хиперфория"];
    const tropiesType = ["Езотропия", "Екзотропия", "Хипертропия"];
    
    useEffect(() => {
      if (props.viewType != TemplateView.CreateNew){
        if(props.templateData){
          setOtherObservationObjectData(inputData);
        }
      }
    },[]);

    useEffect(() => {
      if(typeof(props.setOtherObservationData) === "function" ) {
          props.setOtherObservationData(otherObservationsData);
      }
    }, [otherObservationsData]);
    
    const rowElement = (elementType, inputObject, objectProperty, label): JSX.Element => {
      return <>
        <tr>
            <th className="f-size-16 align-middle text-center" scope="row">{label}</th>
            {props.viewType == TemplateView.View ?
              <td>
                <div className="f-size-18">{inputObject[objectProperty]}</div>
              </td>
            :
            <>
              <td colSpan={3}>
                <ButtonGroup className="d-flex justify-content-around contact-lenses-buttons">
                {elementType.map((data, index) =>
                  <Button
                    key={index}
                    className="w-30"
                    color="primary"
                    onClick={() => setOtherObservationObjectData({ ...inputObject, [objectProperty]: data })}
                    active={inputObject[objectProperty] === data}>
                    {data}
                  </Button>
                )}
                </ButtonGroup>
              </td>
              <td className="w-20">
                  <Input
                      className={`f-size-18`}
                      type="text"
                      placeholder="Друг"
                      defaultValue={inputObject[objectProperty]}
                      onChange={e => setOtherObservationObjectData({ ...inputObject, [objectProperty]: e.target.value})}
                  />
              </td>
            </>
            }
          </tr>
      </>
    }

    const eyesObservationsRowElement = (inputObject, objectProperty, secondObjectProperty, label): JSX.Element => {
        return <>
        <tr>
            <th className="f-size-16 align-middle text-center" scope="row">{label}</th>
            {props.viewType == TemplateView.View ?
            <>
            <td>
                <div className="f-size-18">{inputObject[objectProperty] && <i className="fas fa-check optileader-teal-color"></i>}</div>
            </td>
            <td>
                <div className="f-size-18">{inputObject[secondObjectProperty] && <i className="fas fa-check optileader-teal-color"></i>}</div>
            </td>
            </>
            :
            <>
            <td className="align-middle text-center">
                <Input addon type="checkbox" aria-label="Checkbox for following text input" 
                    className="check-box-30 align-middle"
                    onClick={e => setOtherObservationObjectData({ ...inputObject, [objectProperty]: e.target.checked})} 
                    defaultChecked={inputObject[objectProperty]}/>
            </td>
            <td className="align-middle text-center">
                <Input addon type="checkbox" aria-label="Checkbox for following text input" 
                    className="check-box-30 align-middle"
                    onClick={e => setOtherObservationObjectData({ ...inputObject, [secondObjectProperty]: e.target.checked})} 
                    defaultChecked={inputObject[secondObjectProperty]}/>
            </td>
            </>
            }
        </tr>
    </>
    }
    
    return <>
      <Table className={tableStyleClasses} bordered>
        <tbody>
          {rowElement(foriesType, otherObservationsData, "fories_od", "Фории OD")}
          {rowElement(foriesType, otherObservationsData, "fories_os", "Фории OS")}
          {rowElement(tropiesType, otherObservationsData, "tropies_od", "Тропии OD")}
          {rowElement(tropiesType, otherObservationsData, "tropies_os", "Тропии OS")}
        </tbody>
      </Table>
      <Table className={tableStyleClasses} bordered>
        <tbody>
            <tr>
                <th className="f-size-16 align-middle text-center"></th>
                <th className="f-size-16 align-middle text-center">Дясно</th>
                <th className="f-size-16 align-middle text-center">Ляво</th>
            </tr>
            {eyesObservationsRowElement(otherObservationsData, "glaucoma_right", "glaucoma_left", "Глаукома")}
            {eyesObservationsRowElement(otherObservationsData, "maculaDegeneration_right", "maculaDegeneration_left", "Дегенерация на макулата")}
            {eyesObservationsRowElement(otherObservationsData, "nistagum_right", "nistagum_left", "Нистагъм")}
            {eyesObservationsRowElement(otherObservationsData, "retinalDetachment_right", "retinalDetachment_left", "Отлепване на ретината")}
            {eyesObservationsRowElement(otherObservationsData, "neovascularization_right", "neovascularization_left", "Неоваскуларизация")}
            {eyesObservationsRowElement(otherObservationsData, "exophthalmos_right", "exophthalmos_left", "Екзофталм")}
            {eyesObservationsRowElement(otherObservationsData, "eyelidPtosis_right", "eyelidPtosis_left", "Птоза на клепача")}
        </tbody>
      </Table>
    </>
}

export default OtherObservationsTemplate;