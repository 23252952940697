import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Container } from "reactstrap";
import AdminNavbar from '../components/Navbars/AdminNavbar';
// import AdminFooter from "components/Footers/AdminFooter.jsx";
import PageLoader from "../components/pageLoader";
import Sidebar from "../components/Sidebar/Sidebar.jsx";
import CheckForLoggedUser from '../helpers/CheckForLoggedUser';
import GetUserRole from '../helpers/GetUserRole';

import routes from "../routes";

function DashboardLayout(props) {

  let filteredRoutes = [];

  const getUserRole = () => {
    let userRole = GetUserRole();
    
    if(userRole) {
      return userRole; 
    }
  }
  var role = getUserRole();

  /* ORIGINAL LOGIC */
  const getRoutes = (routes, role) => {
    if (role) {
      return routes.map((prop, key) => {
        //MAKE THIS LOOK IN ARRAY AND FIND A MATCH

        if(prop.role) {
          if (prop.role.includes(role)) {
            
            filteredRoutes.push(prop);
            return (
              <Route exact
                path={prop.path}
                component={prop.component} 
                key={key}
              />
            );
          }
        }
          
        });
    }
  };
  
  /*THIS LOGIC IS FOR DEVELOPMENT PURPOSES ONLY*/
  // const getRoutes = routes => {
  //   return routes.map((prop, key) => {
  //       filteredRoutes.push(prop);
  //       return (
  //         <Route exact
  //           path={prop.path}
  //           component={prop.component} 
  //           key={key}
  //         />
  //       );
  //     }
  //   );
  // };

  const getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if ((routes[i].path) === props.location.pathname){
        return routes[i].name;
      }
    }
    return "";
  };
    return <> {!getUserRole() ? <PageLoader></PageLoader> : <>
        <AdminNavbar
          {...props}
          brandText={getBrandText(props.location.pathname)}
        />
        <div className="d-flex flex-row">
          <Sidebar
            {...props}
            routes={filteredRoutes}
            logo={{
              innerLink: "/admin/index",
              imgSrc: "",
              imgAlt: "..."
            }}
          />
          
          <div className="main-content">
            <Container fluid className="container-wrapper">
              {/* ORIGINAL LOGIC */}
              {CheckForLoggedUser() 
              ?
                <Switch>{getRoutes(routes, role)}</Switch> 
              : 
                <Redirect to="/auth/login" />}

              {/*THIS LOGIC IS FOR DEVELOPMENT PURPOSES ONLY*/}
              {/* <Switch>{getRoutes(routes)}</Switch>  */}
            </Container>
          </div>
        </div>
      </>
    }
    </>
}

export default DashboardLayout;
