//theme pages
import Tables from "./views/examples/Tables.jsx";
import Icons from "./views/examples/Icons.jsx";
import Profile from "./views/examples/Profile.jsx";
import Maps from "./views/examples/Maps.jsx";

//admin pages
import Register from "./views/admin-area/Register.jsx";
import TestPage from "./views/admin-area/testpage";
import AdminHome from "./views/admin-area/AdminHome";
import AddCompany from "./views/admin-area/Company/AddCompany";
import CompanyDetails from "./views/admin-area/Company/CompanyDetails";
import EditCompany from "./views/admin-area/Company/EditCompany";
import AddFacility from "./views/admin-area/Facility/AddFacility";
import EditFacility from "./views/admin-area/Facility/EditFacility";
import FacilityDetails from "./views/admin-area/Facility/FacilityDetails";
import AddEmployee from "./views/admin-area/Employee/AddEmployee";
import EditEmployee from "./views/admin-area/Employee/EditEmployee";
import EmployeeDetails from "./views/admin-area/Employee/EmployeeDetails";
import AddCustomer from "./views/client-area/Customer/AddCustomer";
import EditCustomer from "./views/client-area/Customer/EditCustomer";
import ViewCustomer from "./views/client-area/Customer/CustomerDetails";
import Customers from "./views/client-area/Customers";
import EditGlassesOrder from "./views/client-area/Order/EditGlassesOrder";
import AddGlassesOrder from "./views/client-area/Order/AddGlassesOrder";
import AddContactLensesOrder from "./views/client-area/Order/AddContactLensesOrder";
import EditContactLensesOrder from "./views/client-area/Order/EditContactLensesOrder";
import UserSettings from "./views/client-area/UserSettings";

//client pages
import Orders from "./views/client-area/Orders";
import Order from "./views/client-area/Order/Order";
import Activity from "./views/client-area/Activity";
import AddRefraction from "./views/client-area/Refraction/AddRefraction";
import EmployeeOrders from "./views/client-area/EmployeeOrders"
import EditRefraction from "./views/client-area/Refraction/EditRefraction";
import ViewRefraction from "./views/client-area/Refraction/ViewRefraction";
import EmployeeClients from "./views/client-area/EmployeeClients";
import EmployeeRefractions from "./views/client-area/EmployeeRefractions";
import AddContact from "./views/client-area/Contacts/AddContact";
import EditContact from "./views/client-area/Contacts/EditContact";
import ViewContact from "./views/client-area/Contacts/ViewContact";
import ContactsList from "./views/client-area/Contacts/ContactsList";
import GlassesOrderDetails from "./views/client-area/Order/GlassesOrderDetails";

//manager pages
import EmployeesDetails from "./views/client-area/EmployeesDetails";
import FacilitiesDetails from "./views/client-area/FacilitiesDetails";
import ManufacturerDetails from "./views/client-area/ManufacturersDetails";
import ProductsDetails from "./views/client-area/ProductsDetails";

import { UserRole } from './models/enums/UserRole';
import AddOpticalMeasurement from "./views/client-area/OpticalMeasurement/AddOpticalMeasurement";
import EditOpticalMeasurement from "./views/client-area/OpticalMeasurement/EditOpticalMeasurement";
import ContactLensesOrderDetails from "./views/client-area/Order/ContactLensesOrderDetails";

export enum AdminRoute {
  Index = "/admin",
  Companies = "/companies",
  Facilities = "/facilities",
  Employees = "/employees",
  AddCompany = "/add-company",
  CompanyDetails = "/company-details",
  EditCompany = "/edit-company",
  AddFacility = "/add-facility",
  EditFacility = "/edit-facility",
  FacilityDetails = "/facility-details",
  AddEmployee = "/add-employee",
  EditEmployee = "/edit-employee",
  EmployeeDetails = "/employee-details",
  Customers = "/all-customers"
}

export enum ClientRoute {
  Index = "/",
  Order = "/order",
  AddOrder = "/add-order",
  AddCustomer = "/add-customer",
  EditCustomer = "/edit-customer",
  CustomerDetails = "/customer-details",
  Customers = "/customers",
  AddOpticalMeasurement = "/add-optical-measurement",
  EditOpticalMeasurement = "/edit-optical-measurement",
  OpticalMeasurementDetails = "/edit-optical-measurement",
  EditGlassesOrder = "/edit-glasses-order",
  AddGlassesOrder = "/add-glasses-order",
  GlassesOrderDetails = "/glasses-order-details",
  AddContactLensesOrder = "/add-contact-lenses-order",
  EditContactLensesOrder = "/edit-contact-lenses-order",
  ContactLensesOrderDetails = "/contact-lenses-order-details",
  UserSettings = "/user-settings",
  Activity = "/activity",
  AddRefraction = "/add-refraction",
  EmployeeOrders = "/employee-orders",
  EditRefraction = "/edit-refraction",
  ViewRefraction = "/view-refraction",
  EmployeeClients = "/employee-clients",
  EmployeeRefractions = "/employee-refractions",
  AddContact = "/add-contact",
  EditContact = "/edit-contact",
  ViewContact = "/view-contact",
  ContactsList = "/contacts"
}

export enum ManagerRoutes {
  Employees = "/employees-details",
  Facilities = "/facilities-details",
  Manufacturers = "/manufacturers-details",
  Products = "/products-details"
}

export enum SharedRoute {
  AddCustomer = "/add-customer",
  EditCustomer = "/edit-customer",
  CustomerDetails = "/customer-details"
}

var administratorRoutes = [
  {
    path: AdminRoute.Index,
    name: "Фирми",
    icon: "fas fa-atlas text-blue",
    component: AdminHome,
    role: [UserRole.Administrator]
  },
  // {
  //   path: AdminRoute.Customers,
  //   name: "Клиенти",
  //   icon: "fas fa-users text-purple",
  //   component: AllCustomers,
  //   role: [UserRole.Administrator]
  // },
  {
    path: AdminRoute.AddCompany,
    name: "Добави Фирма",
    component: AddCompany,
    role: [UserRole.Administrator]
  },
  {
    path: AdminRoute.EditCompany,
    name: "Промени Фирма",
    component: EditCompany,
    role: [UserRole.Administrator]
  },
  {
    path: AdminRoute.CompanyDetails,
    name: "Детайли",
    component: CompanyDetails,
    role: [UserRole.Administrator]
  },
  {
    path: AdminRoute.AddFacility,
    name: "Добави Обект",
    component: AddFacility,
    role: [UserRole.Administrator]
  },
  {
    path: AdminRoute.EditFacility,
    name: "Промени Обект",
    component: EditFacility,
    role: [UserRole.Administrator]
  },
  {
    path: AdminRoute.FacilityDetails,
    name: "Детайли",
    component: FacilityDetails,
    role: [UserRole.Administrator]
  },
  {
    path: AdminRoute.AddEmployee,
    name: "Добави Служител",
    component: AddEmployee,
    role: [UserRole.Administrator]
  },
  {
    path: AdminRoute.EditEmployee,
    name: "Промени Служител",
    component: EditEmployee,
    role: [UserRole.Administrator]
  },
  {
    path: AdminRoute.EmployeeDetails,
    name: "Детайли",
    component: EmployeeDetails,
    role: [UserRole.Administrator]
  },
  // {
  //   path: AdminRoute.Facilities,
  //   name: "Обекти",
  //   icon: "fas fa-store-alt text-purple",
  //   component: Facilities,
  //   role: [UserRole.Administrator]
  // },
  // {
  //   path: AdminRoute.Employees,
  //   name: "Служители",
  //   icon: "fas fa-users text-teal",
  //   component: Employees,
  //   role: [UserRole.Administrator]
  // },
];

var sharedRoutes = [
  {
    path: SharedRoute.AddCustomer,
    name: "Добави Клиент",
    component: AddCustomer,
    role: [UserRole.Employee, UserRole.Manager]
  },
  {
    path: SharedRoute.EditCustomer,
    name: "Промени Клиент",
    component: EditCustomer,
    role: [UserRole.Employee, UserRole.Manager]
  },
  {
    path: SharedRoute.CustomerDetails,
    name: "Детайли",
    component: ViewCustomer,
    role: [UserRole.Employee, UserRole.Manager]
  },
];

var clientRoutes = [
  {
    path: ClientRoute.Index,
    name: "Текущи поръчки",
    icon: "fas fa-clipboard-check",
    component: Orders,
    role: [UserRole.Employee, UserRole.Manager]
  },
  {
    path: ClientRoute.Customers,
    name: "Всички Клиенти",
    icon: "fas fa-users",
    component: Customers,
    role: [UserRole.Employee, UserRole.Manager]
  },
  {
    path: ClientRoute.Activity,
    name: "Дейност",
    icon: "fas fa-clipboard",
    component: Activity,
    role: [UserRole.Employee, UserRole.Manager]
  },
  {
    path: ClientRoute.ContactsList,
    name: "Kонтакти",
    icon: "fas fa-book",
    component: ContactsList,
    role: [UserRole.Employee, UserRole.Manager]
  },
  {
    path: ClientRoute.EmployeeOrders,
    name: "Моите поръчки",
    icon: "fas fa-star",
    component: EmployeeOrders,
    role: [UserRole.Employee, UserRole.Manager]
  },
  {
    path: ClientRoute.EmployeeClients,
    name: "Моите клиенти",
    icon: "fas fa-user-friends",
    component: EmployeeClients,
    role: [UserRole.Employee, UserRole.Manager]
  },
  {
    path: ClientRoute.EmployeeRefractions,
    name: "Моите рефракции",
    icon: "fas fa-copy",
    component: EmployeeRefractions,
    role: [UserRole.Employee, UserRole.Manager]
  },
  {
    path: ClientRoute.AddRefraction,
    name: "Добави Рефракция",
    component: AddRefraction,
    role: [UserRole.Employee, UserRole.Manager]
  },
  {
    path: ClientRoute.EditRefraction,
    name: "Редактирай Рефракция",
    component: EditRefraction,
    role: [UserRole.Employee, UserRole.Manager]
  },
  {
    path: ClientRoute.ViewRefraction,
    name: "Преглед Рефракция",
    component: ViewRefraction,
    role: [UserRole.Employee, UserRole.Manager]
  },
  {
    path: ClientRoute.Order,
    name: "Поръчка",
    component: Order,
    role: [UserRole.Employee, UserRole.Manager]
  },
  {
    path: ClientRoute.AddCustomer,
    name: "Детайли",
    component: AddCustomer,
    role: [UserRole.Employee, UserRole.Manager]
  },
  {
    path: ClientRoute.AddOpticalMeasurement,
    name: "Добави Измерване",
    component: AddOpticalMeasurement,
    role: [UserRole.Employee, UserRole.Manager]
  },
  {
    path: ClientRoute.EditOpticalMeasurement,
    name: "Редактирай Измерване",
    component: EditOpticalMeasurement,
    role: [UserRole.Employee, UserRole.Manager]
  },
  {
    path: ClientRoute.EditGlassesOrder,
    name: "Редактирай Поръчка: Очила",
    component: EditGlassesOrder,
    role: [UserRole.Employee, UserRole.Manager]
  },
  {
    path: ClientRoute.GlassesOrderDetails,
    name: "Детайли",
    component: GlassesOrderDetails,
    role: [UserRole.Employee, UserRole.Manager]
  },
  {
    path: ClientRoute.EditContactLensesOrder,
    name: "Редактирай Поръчка: Контактни Лещи",
    component: EditContactLensesOrder,
    role: [UserRole.Employee, UserRole.Manager]
  },
  {
    path: ClientRoute.ContactLensesOrderDetails,
    name: "Детайли",
    component: ContactLensesOrderDetails,
    role: [UserRole.Employee, UserRole.Manager]
  },
  {
    path: ClientRoute.AddGlassesOrder,
    name: "Добави Поръчка: Очила",
    component: AddGlassesOrder,
    role: [UserRole.Employee, UserRole.Manager]
  },
  {
    path: ClientRoute.AddContactLensesOrder,
    name: "Добави Поръчка: Контактни Лещи",
    component: AddContactLensesOrder,
    role: [UserRole.Employee, UserRole.Manager]
  },
  {
    path: ClientRoute.AddContact,
    name: "Добави контакт",
    component: AddContact,
    role: [UserRole.Employee, UserRole.Manager]
  },
  {
    path: ClientRoute.EditContact,
    name: "Редактирай контакт",
    component: EditContact,
    role: [UserRole.Employee, UserRole.Manager]
  },
  {
    path: ClientRoute.ViewContact,
    name: "Детайли",
    component: ViewContact,
    role: [UserRole.Employee, UserRole.Manager]
  },
  {
    path: ClientRoute.UserSettings,
    component: UserSettings,
    role: [UserRole.Employee, UserRole.Manager]
  },
];

var managerRoutes = [
  {
    path: ManagerRoutes.Employees,
    name: "Служители",
    icon: "fas fa-address-book mt-3 optileader-teal-color",
    component: EmployeesDetails,
    role: [UserRole.Manager]
  },
  {
    path: ManagerRoutes.Manufacturers,
    name: "Производители",
    icon: "fas fa-industry optileader-teal-color",
    component: ManufacturerDetails,
    role: [UserRole.Manager]
  },
  {
    path: ManagerRoutes.Products,
    name: "Продукти",
    icon: "fas fa-box-open optileader-teal-color",
    component: ProductsDetails,
    role: [UserRole.Manager]
  },
  {
    path: ManagerRoutes.Facilities,
    name: "Обекти",
    icon: "fas fa-store-alt optileader-teal-color",
    component: FacilitiesDetails,
    role: [UserRole.Manager]
  },
];

var themeRoutes = [
  
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "ni ni-planet text-blue",
  //   component: Icons,
  //   role: [UserRole.Administrator]
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   icon: "ni ni-pin-3 text-orange",
  //   component: Maps,
  //   role: [UserRole.Administrator]
  // },
  // {
  //   path: "/user-profile",
  //   name: "User Profile",
  //   icon: "ni ni-single-02 text-yellow",
  //   component: Profile,
  //   role: [UserRole.Administrator]
  // },
  // {
  //   path: "/tables",
  //   name: "Tables",
  //   icon: "ni ni-bullet-list-67 text-red",
  //   component: Tables,
  //   role: [UserRole.Administrator]
  // },
  // {
  //   path: "/test-page",
  //   name: "Test Page",
  //   icon: "ni ni-circle-08 text-pink",
  //   component: TestPage,
  //   role: [UserRole.Administrator]
  // },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    // component: Login,
    layout: "/auth"
  },
  // {
  //   path: "/register",
  //   name: "Register",
  //   icon: "ni ni-circle-08 text-pink",
  //   component: Register,
  //   layout: "/auth"
  // }
  
];

const routes = [].concat(
  administratorRoutes, 
  clientRoutes,
  managerRoutes,
  sharedRoutes,
  themeRoutes
);

export default routes;
