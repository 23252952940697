import React, { useState, useEffect } from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import RedirectButton from "../../../components/Buttons/RedirectButton";
import PageLoader from "../../../components/pageLoader";
import { Get } from "../../../helpers/requests";
import { OrderStatus } from "../../../models/enums/OrderStatus";
import { TemplateView } from "../../../models/enums/TemplateView";
import { GlassesOrderData } from "../../../models/glassesOrderData";
import { MeasurementsDetails } from "../../../models/measurementDetails";
import { ClientRoute } from "../../../routes";
import MeasurementsTableTemplate from '../OpticalMeasurement/MeasurementsTableTemplate';
import FrameInputDetails from '../Order/Order-Components/FrameInputDetails';
import IndividualMeasurements from "./Order-Components/IndividualMeasurements";
import Lenses from "./Order-Components/Lenses";

type GlassesOrderTemplateProps = {
    templateData?: GlassesOrderData;
    viewType: TemplateView;
    orderId?: string;
    latestOpticalMeasurementId?: string;
    orderStatus?: OrderStatus;
}

const GlassesOrderDetails = (props: GlassesOrderTemplateProps) => {

    var url = new URL(window.location.href);
    var opticalMeasurementId = url.searchParams.get("opticalMeasurementId");
    var orderId = url.searchParams.get("orderId");

    const templateType = TemplateView.View;
    const [loading, setLoading] = useState(true);
    const [reload, setReload] = useState(false);
    const [measurementsDetails, setMeasurementsDetails] = useState<MeasurementsDetails>({});
    const [glassesOrderData, setGlassesOrderData] = useState<GlassesOrderData>({});

    useEffect(() => {

        Get(`opticalMeasurements/${opticalMeasurementId}/details`)
            .catch(error => console.log(error))
            .then((json: any) => {
            setMeasurementsDetails(json);
        });
        Get(`glassesOrder/${orderId}`)
            .catch(error => console.log(error))
            .then((json: GlassesOrderData) => {
                setGlassesOrderData(json)
                setLoading(false);
                setReload(false);      
        });
    },[reload]);
   
    const renderFields = (): JSX.Element => {
        return <>
            {loading ? <PageLoader></PageLoader> : 
                <>
                    <Row>
                        <Col className="pr-1">
                            <Card className="bg-secondary shadow mb-1">
                                <CardBody className="py-1 px-2">
                                    <h6 className="heading-small text-muted f-size-16 mb-0">
                                        Диоптрични лещи
                                    </h6>
                                    <Lenses viewType={templateType} reload={reload} orderId={orderId}/>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col className="d-flex align-items-stretch pl-0 w-100">
                            <Card className="bg-secondary shadow mb-1">
                                <CardBody className="py-1 px-2">
                                    <h6 className="heading-small text-muted f-size-16  mb-0">
                                        Рамка
                                    </h6>
                                    <FrameInputDetails viewType={templateType} reload={reload} orderId={orderId}/>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card className="bg-secondary shadow mb-1">
                                <CardBody className="py-1 px-2">
                                    <IndividualMeasurements viewType={templateType} reload={reload} orderId={orderId}/>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </>
            }
        </>
    }

    const renderButtons = (): JSX.Element => {
        return <>
            <RedirectButton 
                className="optileader-teal-background white-font-color" 
                buttonText="Обратно към поръчки" 
                url={ClientRoute.Index} 
                />
            <RedirectButton 
                className="optileader-teal-background white-font-color" 
                buttonText="Редактирай" 
                url={`${ClientRoute.EditGlassesOrder}?opticalMeasurementId=${opticalMeasurementId}&orderId=${orderId}&orderStatus=${OrderStatus.Processing}`} 
                
                callback={() => setReload(true)} 
                />
        </>
    }

    return (
        <>
        <Card className="bg-secondary shadow mb-1">
            <CardBody className="py-1 px-2">
                <h6 className="heading-small text-muted f-size-16 mb-0">
                    Клиент
                    {measurementsDetails.customerName && 
                        <span className="mr-4"> - {measurementsDetails.customerName} </span>
                    }
                    {measurementsDetails.customerPhone && 
                        <span className="ml-4">Телефон - {measurementsDetails.customerPhone}</span>
                    }
                </h6>
            </CardBody>
        </Card>
        <Card className="bg-secondary shadow mb-1">
            <CardBody className="py-1 px-2">
                <h6 className="heading-small text-muted f-size-16  mb-0">
                    Рецепта
                    {measurementsDetails.prescribedBy && 
                        <> - Предписал: {measurementsDetails.prescribedBy}</>
                    }
                </h6>
                <Row>
                    <Col m={8} lg={8}>
                        <MeasurementsTableTemplate viewType={TemplateView.View} templateData={measurementsDetails} />
                    </Col>
                </Row>
            </CardBody>
        </Card>
        {renderFields()}
        <Card className="bg-secondary shadow">
            <CardBody className="py-1 px-2">
                
                {glassesOrderData.employeeName && 
                    <h6 className="heading-small text-muted f-size-16">Създал: {glassesOrderData.employeeName}</h6>
                }
                {renderButtons()}
            </CardBody>
        </Card>
    </>
    );
}

export default GlassesOrderDetails;
