import React, { useState, useEffect, useContext } from "react";
import { Row, Col, FormGroup, Input, Card, CardBody, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from "reactstrap";
import RedirectButton from '../../../components/Buttons/RedirectButton';
import { AdminRoute, ClientRoute } from '../../../routes';
import { TemplateView } from '../../../models/enums/TemplateView';
import { Create, Delete, Update, UpdateWithoutRedirect } from "../../../helpers/requests";
import { undefinedChecker } from "../../../helpers/Checkers";
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate } from "../../../helpers/Formatters";
import { CompanyDetailsData } from "../../../models/companyDetailsData";
import { SetObjectData } from "../../../helpers/objectContextManager";
import { EmployeeDetailsData } from "../../../models/employeeDetailsData";
import { ProfileStatus } from "../../../models/enums/ProfileStatus";
import ButtonLoader from "../../../components/buttonLoader";
import { ContactInputData } from "../../../models/contact/contactInputData";
import { UserContext } from "../../../userContext";
import NoAvailableData from "../../../components/noAvailableData";

type ContactTemplateProps = {
  templateData?: ContactInputData;
  viewType: TemplateView;
}

const ContactTemplate = (props?: ContactTemplateProps): JSX.Element => {

  const [contactData, setContactData] = useState({} as ContactInputData);
  const {objectData} = useContext(UserContext);
  const inputData = props.templateData;

  const inputLabels = [
    { label: "Име", value: undefinedChecker(inputData, "name"), newValue: (newValue) => setContactData({ ...contactData, name: newValue}) },
    { label: "Адрес", value: undefinedChecker(inputData, "address"), newValue: (newValue) => setContactData({ ...contactData, address: newValue}) },
    { label: "Лице за контакт / МОЛ", value: undefinedChecker(inputData, "contactPerson"), newValue: (newValue) => setContactData({ ...contactData, contactPerson: newValue}) },
    { label: "ЕИК", value: undefinedChecker(inputData, "companyRegistrationNumber"), newValue: (newValue) => setContactData({ ...contactData, companyRegistrationNumber: newValue}) },
    { label: "Телефон", value: undefinedChecker(inputData, "phoneNumber"), newValue: (newValue) => setContactData({ ...contactData, phoneNumber: newValue}) },
    { label: "Мейл адрес", value: undefinedChecker(inputData, "email"), newValue: (newValue) => setContactData({ ...contactData, email: newValue}) },
  ];

  useEffect(() => {
    if (props.viewType == TemplateView.Edit){
      setContactData(inputData);
    }
    if (props.viewType == TemplateView.CreateNew){
      setContactData({ ...contactData})
    }
  },[]);

  const renderFields = (): JSX.Element => {
    return <>
        {inputLabels.map((data, index) => 
          <Col lg="4" key={index}>
            <FormGroup>
              <h6 className="heading-small text-muted f-size-16 my-1">
                {data.label}
              </h6>
              <Input
                className="form-control-alternative"
                type="text"
                defaultValue={data.value}
                onChange={e => data.newValue(e.target.value)}
              />
            </FormGroup>
          </Col>
        )}
      </> 
  }

  const saveDataObject = () => {
    contactData.companyId = objectData.getObjectData.companyId;    
    contactData.createdBy = objectData.getObjectData.id;    
    Create(contactData, "contacts", ClientRoute.ContactsList);
  }  

  const updateDataObject = (data) => {
    contactData.editedBy = objectData.getObjectData.id; 
    Update(contactData, "contacts", ClientRoute.ContactsList);
    SetObjectData(data);
  }

  const deleteDataObject = () => {
    Delete(`contacts/${contactData.id}`, ClientRoute.ContactsList);
  }  
  
  const renderButtons = (): JSX.Element => {
    switch(props.viewType) { 
      case TemplateView.CreateNew: { 
         return <>
         <Button color="success" onClick={() => {saveDataObject()}}>Запази</Button>
         </> 
      } 
      case TemplateView.Edit: { 
        return <>
        <Button color="success" onClick={() => {updateDataObject(contactData.id)}}>Запази</Button>
        <Button color="danger" onClick={() => {deleteDataObject()}}>Изтрий</Button>
        </> 
      }
      default: { 
        return <span>Template Type Unknown</span>
      } 
    } 
  }

  return <>
    <Card className="bg-secondary shadow mt-2">
      <CardBody>
        <h6 className="heading-small f-size-16">
          Детайли на Контакта
        </h6>
        {props 
        ?
        <>
          <Row className="mt-4">
            {renderFields()}
          </Row>
          <Row>
          <Col>
            <FormGroup>
              <h6 className="heading-small text-muted f-size-16">Допълнителна информация</h6>
              <Input 
                  type="textarea" 
                  name="text"
                  resize="none"
                  defaultValue={undefinedChecker(inputData, "additionalInformation")}
                  onChange={e => setContactData({ ...contactData, additionalInformation: e.target.value})}/>
            </FormGroup>
          </Col>
        </Row>
          {renderButtons()}
        </>
        :
        <NoAvailableData/>
        }
      </CardBody>
    </Card>
  </>
}

export default ContactTemplate;
