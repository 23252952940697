import React, { useContext, useEffect } from "react";
import { Button, Card, Form, FormGroup, InputGroupAddon, InputGroupText, Input, InputGroup, Pagination, PaginationItem, PaginationLink, Row } from "reactstrap";
import RedirectButton from '../../components/Buttons/RedirectButton';
import CustomersList from "./Customer/CustomersList";
import { ClientRoute } from '../../routes';
import { UserContext } from "../../userContext";
import { useState } from "react";
import { Get } from "../../helpers/requests";
import PageLoader from "../../components/pageLoader";

const EmployeeClients = () => {

    const {objectData} = useContext(UserContext);

    const [data, setData] = useState<any>();
    const [search, setSearch] = useState("");
  
    const loadData = (apiUrl: string) => {
      
      Get(apiUrl)
      .catch(error => console.log(error))
      .then((json: any) => {
        setData(json);
      });
    }
  
    useEffect(() => {
      loadData(`customer/${ objectData.getObjectData.companyId }/customers/${objectData.getObjectData.id}/search`);
    },[]);
  
    const getResults = (searchText) => {
      setSearch(searchText);
      loadData(`customer/${ objectData.getObjectData.companyId }/customers/${objectData.getObjectData.id}/search?searchText=${searchText}`);
    }
  
    const paginate = (currentPage: number) => {
      loadData(`customer/${ objectData.getObjectData.companyId }/customers/${objectData.getObjectData.id}/search?pageSize=25&currentPage=${currentPage}&searchText=${search}`);
    }
  
    return (
      <>
        <Card className="p-4 d-flex-none align-middle w-100">
          <Form className="navbar-search mr-3">
            <FormGroup className="mb-0">
              <InputGroup className="input-group-alternative" 
                onChange={e => getResults(e.target.value)}>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-search" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input placeholder="Търси Клиент" type="text"/>
              </InputGroup>
            </FormGroup>
          </Form>
          <Button color="success" 
            onClick={() => getResults(search)}>Търсене</Button>
          <RedirectButton className="optileader-teal-background white-font-color" buttonText="Добави Клиент" url={ClientRoute.AddCustomer}/>
        </Card>
        {data 
          ?
          <>
            <Row className="p-3">
              <span className="d-flex align-items-center pr-4">Резултати: {data.pageSize} от {data.recordCount}</span>
              <Pagination className="d-flex justify-content-center">
                {Array.apply(null, Array(data.pageCount)).map((i, index)=>
                  <PaginationItem key={index}>
                    <PaginationLink href="#" onClick={() => paginate(index+1)}>
                      {index+1}
                    </PaginationLink>
                  </PaginationItem>
                )}
              </Pagination>
            </Row>
            <CustomersList listData={data.results} />
            <Pagination className="d-flex justify-content-center">
              {Array.apply(null, Array(data.PageCount)).map((i, index)=>
                <PaginationItem key={index}>
                  <PaginationLink href="#" onClick={() => paginate(index+1)}>
                    {index+1}
                  </PaginationLink>
                </PaginationItem>
              )}
            </Pagination>
          </>
          :
          <PageLoader></PageLoader>
        }
      </>
    ); 
}

export default EmployeeClients;
