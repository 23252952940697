import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Table, Input, Button, ButtonGroup } from 'reactstrap';
import { TemplateView } from '../../../models/enums/TemplateView';

type LensesTypeProps = {
    templateData?: string;
    setLenseTypeChoice?: Dispatch<SetStateAction<string>>;
    className?: string;
    viewType: TemplateView;
}

const LensesType = (props: LensesTypeProps) => {

    const inputData = props.templateData ? props.templateData : "";
    const tableStylePerView = props.viewType == TemplateView.View ? "table-measurements lenses-table transparent-background mb-2" : "table-measurements lenses-table mb-2";
    const tableStyleClasses = `${tableStylePerView} ${props.className}`;
    const [lenseTypeChoice, setLenseTypeObjectChoice] = useState("");

    const consultationChoices = ["Еднофокусни", "Офис", "Бифокал", "Дегресия", "Прогресия", "Фото"];

    useEffect(() => {
        if (props.viewType != TemplateView.CreateNew){
            setLenseTypeObjectChoice(inputData);
        }
    },[]);

    useEffect(() => {
        if(typeof(props.setLenseTypeChoice) === "function" ) {
            props.setLenseTypeChoice(lenseTypeChoice);
        }
      }, [lenseTypeChoice]);
  
    return <>
        <Table className={tableStyleClasses} bordered>
        <tbody>
            <tr>
                <th className="f-size-16 align-middle text-center w-10" scope="row">Вид</th>
                {props.viewType == TemplateView.View ?
                    <td>
                        <div className="f-size-18">{lenseTypeChoice}</div>
                    </td>
                :
                    <>
                        <td colSpan={6}>
                            <ButtonGroup className="d-flex justify-content-around contact-lenses-buttons">
                            {consultationChoices.map((data, index) =>
                            <Button
                                key={index}
                                className="px-3"
                                color="primary"
                                onClick={() => setLenseTypeObjectChoice(data)}
                                active={lenseTypeChoice === data}>
                                {data}
                            </Button>
                            )}
                            </ButtonGroup>
                        </td>
                        <td>
                            <Input
                                className={`f-size-18`}
                                type="text"
                                placeholder="Други"
                                defaultValue={lenseTypeChoice}
                                onChange={e => setLenseTypeObjectChoice(e.target.value)}
                            />
                        </td>
                    </>
                }
            </tr>
        </tbody>
      </Table>
  </>
}

export default LensesType;