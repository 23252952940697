import React, { useEffect, useState } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane, Row, Col } from "reactstrap";
import classnames from 'classnames';
import { TemplateView } from '../../../models/enums/TemplateView';
import ApiResource from '../../../helpers/ApiResource';
import CustomerTemplate, { CustomerTemplateData } from './CustomerTemplate';
import MeasurementsList, { MeasurementListItem } from './MeasurementsList';
import OrdersTableTemplate from '../../../components/OrdersTableTemplate';
import { Get } from '../../../helpers/requests';

export type CustomerViewModel = {
  customerDetails : CustomerTemplateData;
  opticalMeasurements: MeasurementListItem[];
}

const ViewCustomer = (props) => {

  var url = new URL(window.location.href);
  var id = url.searchParams.get("id");

  const [activeTab, setActiveTab] = useState('1');

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  }
  
  const [data, setData] = useState<any>();
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {    
    Get(`order/${id}/client`)
    .catch(error => console.log(error))
    .then((json) => {
      setData(json);
      setLoading(false);        
    });
    setReload(false);
  },[reload]);

  const reloadTableData = (newValue) => {
    setReload(newValue);
  }

  return <>
    <ApiResource url={`customer/${id}`}>
        {(customerData: CustomerViewModel) => <>
            <CustomerTemplate viewType={TemplateView.View} templateData={customerData.customerDetails} measurementsList={customerData.opticalMeasurements.length} 
              latestOpticalMeasurement={customerData.opticalMeasurements.length && customerData.opticalMeasurements[0].id}/>
              <Nav tabs className="mt-4">
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '1' }, "p-3")}
                      onClick={() => { toggle('1'); }}
                    >
                      Поръчки
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '2' }, "p-3")}
                      onClick={() => { toggle('2'); }}
                    >
                      Измервания
                    </NavLink>
                  </NavItem>
              </Nav>
              <TabContent activeTab={activeTab} className="bg-secondary shadow">
                <TabPane tabId="1">
                  <Row>
                    <Col sm="12">
                      <div className="p-lg-4">
                        <OrdersTableTemplate loading={loading} data={data} reload={reloadTableData}/>
                      </div>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <div className="p-lg-4">
                    <MeasurementsList listData={customerData.opticalMeasurements} clientId={customerData.customerDetails.id}/>
                  </div>
                </TabPane>
              </TabContent>
        </>}
    </ApiResource>
  </>
}

export default ViewCustomer;

