import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Table, Input } from 'reactstrap';
import { TemplateView } from '../../../models/enums/TemplateView';
import { undefinedChecker } from '../../../helpers/Checkers';
import numeral from 'numeral';
import { LenseMeasurements } from '../../../models/LenseMeasurements';

type LenseMeasurementsProps = {
    templateData?: LenseMeasurements;
    setMeasurementsData?: Dispatch<SetStateAction<LenseMeasurements>>;
    className?: string;
    viewType: TemplateView;
}

const LastUsedLenses = (props: LenseMeasurementsProps) => {

    const inputData = props.templateData ? props.templateData : {} as LenseMeasurements;
    const tableStylePerView = props.viewType == TemplateView.View ? "table-measurements transparent-background mb-2" : "table-measurements mb-2";
    const tableStyleClasses = `${tableStylePerView} ${props.className}`

    const [measurementsData, setMeasurementsObjectData] = useState<LenseMeasurements>({});
    useEffect(() => {
        if (props.viewType != TemplateView.CreateNew){
            setMeasurementsObjectData(inputData);
        }
    },[]);
  
    useEffect(() => {
      if(typeof(props.setMeasurementsData) === "function" ) {
          props.setMeasurementsData(measurementsData);
      }
    }, [measurementsData]);

    const tableHeader = [
        { name: "Sph", length: 5 },
        { name: "Cyl", length: 5 },
        { name: "Ax", length: 3 },
        { name: "Add", length: 5 },
        { name: "Prism", length: 5 },
        { name: "Base", length: 5 },
        { name: "Vcc", length: 3 }
    ];

    const odDimensions = [
        {value: undefinedChecker(inputData, "od_sph"), newValue: (newValue) => setMeasurementsObjectData({ ...measurementsData, od_sph: numeral(newValue.replace(",", ".")).format("+0.00")})},
        {value: undefinedChecker(inputData, "od_cyl"), newValue: (newValue) => setMeasurementsObjectData({ ...measurementsData, od_cyl: numeral(newValue.replace(",", ".")).format("+0.00")})},
        {value: undefinedChecker(inputData, "od_ax"), newValue: (newValue) => setMeasurementsObjectData({ ...measurementsData, od_ax: newValue.replace(",", ".")})},
        {value: undefinedChecker(inputData, "od_add"), newValue: (newValue) => setMeasurementsObjectData({ ...measurementsData, od_add: numeral(newValue.replace(",", ".")).format("0.00")})},
        {value: undefinedChecker(inputData, "od_prism"), newValue: (newValue) => setMeasurementsObjectData({ ...measurementsData, od_prism: numeral(newValue.replace(",", ".")).format("+0.00")})},
        {value: undefinedChecker(inputData, "od_base"), newValue: (newValue) => setMeasurementsObjectData({ ...measurementsData, od_base: newValue.replace(",", ".")})},
        {value: undefinedChecker(inputData, "od_vcc"), newValue: (newValue) => setMeasurementsObjectData({ ...measurementsData, od_vcc: newValue.replace(",", ".")})},
    ];

    const osDimensions = [
        {value: undefinedChecker(inputData, "os_sph"), newValue: (newValue) => setMeasurementsObjectData({ ...measurementsData, os_sph: numeral(newValue.replace(",", ".")).format("+0.00")}) },
        {value: undefinedChecker(inputData, "os_cyl"), newValue: (newValue) => setMeasurementsObjectData({ ...measurementsData, os_cyl: numeral(newValue.replace(",", ".")).format("+0.00")})},
        {value: undefinedChecker(inputData, "os_ax"), newValue: (newValue) => setMeasurementsObjectData({ ...measurementsData, os_ax: newValue.replace(",", ".")})},
        {value: undefinedChecker(inputData, "os_add"), newValue: (newValue) => setMeasurementsObjectData({ ...measurementsData, os_add: numeral(newValue.replace(",", ".")).format("0.00")})},
        {value: undefinedChecker(inputData, "os_prism"), newValue: (newValue) => setMeasurementsObjectData({ ...measurementsData, os_prism: numeral(newValue.replace(",", ".")).format("+0.00")})},
        {value: undefinedChecker(inputData, "os_base"), newValue: (newValue) => setMeasurementsObjectData({ ...measurementsData, os_base: newValue.replace(",", ".")})},
        {value: undefinedChecker(inputData, "os_vcc"), newValue: (newValue) => setMeasurementsObjectData({ ...measurementsData, os_vcc: newValue.replace(",", ".")})},
    ];

    return <>
        <Table className={tableStyleClasses} bordered>
        <thead>
            <tr>
                <th></th>
                {tableHeader.map((data, index) =>
                    <th className="f-size-16" key={index}>{data.name}</th>
                )}
                <th className="f-size-16">Vccbino</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <th className="f-size-16 align-middle" scope="row">OD</th>
                {odDimensions.map((data, index) =>
                  <td key={index}>
                    {props.viewType == TemplateView.View ? 
                      <>
                        <div className="f-size-18 p-0">{data.value}</div>
                      </>
                      :
                      <Input
                        className={`f-size-18 p-0`}
                        type="text"
                        maxLength={6}
                        defaultValue={data.value}
                        onChange={e => data.newValue(e.target.value)}
                      />
                    }
                  </td>
                )}
                <td rowSpan={2}>
                  {props.viewType == TemplateView.View ? 
                    <>
                      <div className="f-size-18">{inputData.vccbino}</div>
                    </>
                    :
                    <Input
                      className="Vccbino_od f-size-18 p-0"
                      type="text"
                      maxLength={3}
                      defaultValue={inputData.vccbino}
                      onChange={e => setMeasurementsObjectData({ ...measurementsData, vccbino: e.target.value})}
                    />
                  }                   
                </td>
            </tr>
            <tr>
                <th className="f-size-16 align-middle" scope="row">OS</th>
                {osDimensions.map((data, index) =>
                    <td key={index}>
                      {props.viewType == TemplateView.CreateNew &&
                        <Input
                          className={`f-size-18 p-0`}
                          type="text"
                          maxLength={6}
                          onChange={e => data.newValue(e.target.value)}
                        />
                      }
                      {props.viewType == TemplateView.Edit && 
                        <Input
                          className={`f-size-18 p-0`}
                          type="text"
                          maxLength={6}
                          defaultValue={data.value}
                          onChange={e => data.newValue(e.target.value)}
                        />
                      }
                      {props.viewType == TemplateView.View &&
                        <div className="f-size-18 p-0">{data.value}</div>
                      }
                    </td>
                )}
            </tr>
        </tbody>
      </Table>
  </>
}

export default LastUsedLenses;