
import React, { useEffect, useState } from "react";
import NoAvailableData from "../../../components/noAvailableData";
import PageLoader from "../../../components/pageLoader";
import { Get } from "../../../helpers/requests";
import { ContactInputData } from "../../../models/contact/contactInputData";
import { TemplateView } from '../../../models/enums/TemplateView';
import ContactTemplate from "./ContactTemplate";

const EditContact = (): JSX.Element => {

    var url = new URL(window.location.href);
    var id = url.searchParams.get("id");

    const [data, setData] = useState({} as ContactInputData);
    const [loading, setLoading] = useState(Boolean);

    useEffect(() => {
        setLoading(true);
        
        Get(`contacts/${id}`)
            .catch(error => console.log(error))
            .then((json: ContactInputData) => {
                setData(json);
                setLoading(false);        
            });
    },[]);
      
  return <>
    {loading 
        ? 
        <PageLoader></PageLoader> 
        :
        <>
            {data
                ?
                <ContactTemplate viewType={TemplateView.Edit} templateData={data}/>
                :
                <NoAvailableData/>
            }
        </>
    }
  </>
}

export default EditContact;
