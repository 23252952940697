import React from 'react';
import FacilityTemplate, { FacilityDetailsData } from './FacilityTemplate';
import { TemplateView } from '../../../models/enums/TemplateView';

const AddFacility = (props) => {
  var url = new URL(window.location.href);
  var id = url.searchParams.get("companyId");

  const companyId: FacilityDetailsData = {companyId: id}

  return <>
    <FacilityTemplate viewType={TemplateView.CreateNew} templateData={companyId}/>
  </>
}

export default AddFacility;