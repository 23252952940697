import React from "react";
import { useHistory } from "react-router";
import { Table } from "reactstrap";
import { formatDate } from "../../../helpers/Formatters";
import { TemplateView } from "../../../models/enums/TemplateView";
import { MeasurementsDetails } from "../../../models/measurementDetails";
import { ClientRoute } from '../../../routes';
import MeasurementsTableTemplate from "../OpticalMeasurement/MeasurementsTableTemplate";

export type MeasurementListItem = {
  id?: string;
  dateCreated?: string;
  employeeName: string;
  measurementDetails?: MeasurementsDetails;
}

type MeasurementsListProps = {
    listData: MeasurementListItem[];
    clientId?: string;
}

const MeasurementsList = (props: MeasurementsListProps): JSX.Element => {

  const history = useHistory();

  const redirectToPage = (link: string): void => {
    history.push(link);
  }

  const redirectRoute = (data: any): string => {
    return data.measurementDetails.refractionProtocolId 
    ? 
    `${ClientRoute.ViewRefraction}?id=${data.measurementDetails.refractionProtocolId}` 
    : 
    `${ClientRoute.EditOpticalMeasurement}?id=${data.id}`;
  }
  
  return <>
      <Table className="align-items-center table-flush table-striped" responsive>
        <thead className="silver-background black-font-color">
          <tr className="text-center">
            <th className="f-size-16" scope="col">Дата</th>
            <th className="f-size-16" scope="col">Измерване</th>
            <th className="f-size-16" scope="col">Предписал</th>
          </tr>
        </thead>
        <tbody>
          {props.listData.map((data, index) =>
            <tr className="text-center cursor-pointer" key={index} onClick={() => redirectToPage(redirectRoute(data))}>
              <td className="f-size-16">{formatDate(data.dateCreated)}</td>
              <td className="f-size-16 customer-measurements-list">
                <MeasurementsTableTemplate viewType={TemplateView.View} templateData={data.measurementDetails} />
              </td>
              <td className="f-size-16">{data.measurementDetails.prescribedBy}</td>
            </tr>
          )}
        </tbody>
      </Table>
  </>
}

export default MeasurementsList;