import React, { useEffect, useState } from 'react';
import { Table, Input, Button, Row, Col, FormGroup } from 'reactstrap';
import { TemplateView } from '../../../models/enums/TemplateView';
import { undefinedChecker } from '../../../helpers/Checkers';
import { ClientRoute } from '../../../routes';
import { Create, Update, Delete } from '../../../helpers/requests';
import { MeasurementsDetails } from '../../../models/measurementDetails';
import { SetObjectData } from '../../../helpers/objectContextManager';
import numeral from 'numeral';

type MeasurementsDetailsProps = {
    templateData?: MeasurementsDetails;
    populateData?: any;
    viewType: TemplateView;
}

const MeasurementsTableTemplate = (props: MeasurementsDetailsProps) => {

    var url = new URL(window.location.href);
    var clientId = url.searchParams.get("customerId");

    const inputData = props.templateData;
    
    const [measurementsData, setMeasurementsData] = useState({} as MeasurementsDetails);
    // console.log(props.templateData);
    useEffect(() => {
        if (props.viewType != TemplateView.CreateNew){
            setMeasurementsData(inputData);
        }
        if (props.viewType == TemplateView.CreateNew){
          setMeasurementsData({ ...measurementsData,
            employeeId: inputData.employeeId,
            clientId: inputData.clientId
          });
        }
    },[]);

    const tableHeader = [
        { name: "Sph", length: 6 },
        { name: "Cyl", length: 6 },
        { name: "Ax", length: 3 },
        { name: "Add", length: 5 },
        { name: "PDfar", length: 5 },
        { name: "PDnear", length: 5 },
        { name: "Prism", length: 5 },
        { name: "Base", length: 5 },
        { name: "Vcc", length: 3 }
    ];

    const odDimensions = [
        {value: undefinedChecker(inputData, "od_sph"), newValue: (newValue) => setMeasurementsData({ ...measurementsData, od_sph: numeral(newValue.replace(",", ".")).format("+0.00")})},
        {value: undefinedChecker(inputData, "od_cyl"), newValue: (newValue) => setMeasurementsData({ ...measurementsData, od_cyl: numeral(newValue.replace(",", ".")).format("+0.00")})},
        {value: undefinedChecker(inputData, "od_ax"), newValue: (newValue) => setMeasurementsData({ ...measurementsData, od_ax: newValue.replace(",", ".")})},
        {value: undefinedChecker(inputData, "od_add"), newValue: (newValue) => setMeasurementsData({ ...measurementsData, od_add: numeral(newValue.replace(",", ".")).format("0.00")})},
        {value: undefinedChecker(inputData, "od_pdfar"), newValue: (newValue) => setMeasurementsData({ ...measurementsData, od_pdfar: newValue.replace(",", ".")})},
        {value: undefinedChecker(inputData, "od_pdnear"), newValue: (newValue) => setMeasurementsData({ ...measurementsData, od_pdnear: newValue.replace(",", ".")})},
        {value: undefinedChecker(inputData, "od_prism"), newValue: (newValue) => setMeasurementsData({ ...measurementsData, od_prism: numeral(newValue.replace(",", ".")).format("+0.00")})},
        {value: undefinedChecker(inputData, "od_base"), newValue: (newValue) => setMeasurementsData({ ...measurementsData, od_base: newValue.replace(",", ".")})},
        {value: undefinedChecker(inputData, "od_vcc"), newValue: (newValue) => setMeasurementsData({ ...measurementsData, od_vcc: newValue.replace(",", ".")})},
    ];

    const osDimensions = [
        {value: undefinedChecker(inputData, "os_sph"), newValue: (newValue) => setMeasurementsData({ ...measurementsData, os_sph: numeral(newValue.replace(",", ".")).format("+0.00")}) },
        {value: undefinedChecker(inputData, "os_cyl"), newValue: (newValue) => setMeasurementsData({ ...measurementsData, os_cyl: numeral(newValue.replace(",", ".")).format("+0.00")})},
        {value: undefinedChecker(inputData, "os_ax"), newValue: (newValue) => setMeasurementsData({ ...measurementsData, os_ax: newValue.replace(",", ".")})},
        {value: undefinedChecker(inputData, "os_add"), newValue: (newValue) => setMeasurementsData({ ...measurementsData, os_add: numeral(newValue.replace(",", ".")).format("0.00")})},
        {value: undefinedChecker(inputData, "os_pdfar"), newValue: (newValue) => setMeasurementsData({ ...measurementsData, os_pdfar: newValue.replace(",", ".")})},
        {value: undefinedChecker(inputData, "os_pdnear"), newValue: (newValue) => setMeasurementsData({ ...measurementsData, os_pdnear: newValue.replace(",", ".")})},
        {value: undefinedChecker(inputData, "os_prism"), newValue: (newValue) => setMeasurementsData({ ...measurementsData, os_prism: numeral(newValue.replace(",", ".")).format("+0.00")})},
        {value: undefinedChecker(inputData, "os_base"), newValue: (newValue) => setMeasurementsData({ ...measurementsData, os_base: newValue.replace(",", ".")})},
        {value: undefinedChecker(inputData, "os_vcc"), newValue: (newValue) => setMeasurementsData({ ...measurementsData, os_vcc: newValue.replace(",", ".")})},
    ];

    const saveDataObject = (data) => {
      SetObjectData(data);
      Create(measurementsData, "opticalMeasurements", `${ClientRoute.CustomerDetails}?id=${clientId}`);
    }  
    
    const updateDataObject = (data) => {
      SetObjectData(data);
      Update(measurementsData, "opticalMeasurements", `${ClientRoute.CustomerDetails}?id=${inputData.clientId}`);
    }
  
    const deleteDataObject = (data) => {
      SetObjectData(data);
      Delete(`opticalMeasurements/${measurementsData.id}`, `${ClientRoute.CustomerDetails}?id=${inputData.clientId}`);
    }  

    const renderButtons = (): JSX.Element => {
      switch (props.viewType) {
        case TemplateView.CreateNew: {
          return <>
            <Button color="success" onClick={() => {saveDataObject(inputData.clientId)}}>Запази</Button>
          </>
        }
        case TemplateView.Edit: {
          return <>
            <Button color="success" onClick={() => {updateDataObject(inputData.clientId)}}>Запази</Button>
            <Button color="danger" onClick={() => {deleteDataObject(inputData.clientId)}}>Изтрий</Button>
          </>
        }
        case TemplateView.View: {
          return <></>
        }
        default: {
          return <span>Template Type Unknown</span>
        }
      }
    }

    return <>
    <Row>
      <Col>
        <Table className={props.viewType == TemplateView.View ? "table-measurements transparent-background mb-2" : "table-measurements mb-2"} bordered>
        <thead>
          <tr>
              <th></th>
              {tableHeader.map((data, index) =>
                  <th className="f-size-16" key={index}>{data.name}</th>
              )}
              <th className="f-size-16">Vccbino</th>
          </tr>
        </thead>
        <tbody>
            <tr>
              <th className="f-size-16 align-middle" scope="row">OD</th>
              {odDimensions.map((data, index) =>
                <td key={index}>
                  {props.viewType === TemplateView.View ? 
                    <>
                      <div className="f-size-18 p-0">{data.value}</div>
                    </>
                    :
                    <Input
                      className={`f-size-18 p-0`}
                      type="text"
                      maxLength={6}
                      defaultValue={data.value}
                      onChange={e => data.newValue(e.target.value)}
                    />
                  }
                </td>
              )}
              <td rowSpan={2}>
                {props.viewType === TemplateView.View ? 
                  <>
                    <div className="f-size-18">{inputData.vccbino}</div>
                  </>
                  :
                  <Input
                    className="Vccbino_od f-size-18 p-0"
                    type="text"
                    maxLength={3}
                    defaultValue={inputData.vccbino}
                    onChange={e => setMeasurementsData({ ...measurementsData, vccbino: e.target.value})}
                  />
                }                   
              </td>
            </tr>
            <tr>
              <th className="f-size-16 align-middle" scope="row">OS</th>
              {osDimensions.map((data, index) =>
                  <td key={index}>
                    {props.viewType == TemplateView.CreateNew &&
                      <Input
                        className={`f-size-18 p-0`}
                        type="text"
                        maxLength={6}
                        onChange={e => data.newValue(e.target.value)}
                      />
                    }
                    {props.viewType == TemplateView.Edit && 
                      <Input
                        className={`f-size-18 p-0`}
                        type="text"
                        maxLength={6}
                        defaultValue={data.value}
                        onChange={e => data.newValue(e.target.value)}
                      />
                    }
                    {props.viewType == TemplateView.View &&
                      <div className="f-size-18 p-0">{data.value}</div>
                    }
                  </td>
                )}
            </tr>
        </tbody>
      </Table>
    </Col>
  </Row>
  <Row>
    <Col>
      <FormGroup className="mb-2">
        {props.viewType == TemplateView.Edit &&
          <>
            <h6 className="heading-small text-muted f-size-16">Допълнителна информация</h6>
            <Input 
                type="textarea" 
                name="text"
                resize="none"
                defaultValue={inputData.additionalInformation}
                onChange={e => setMeasurementsData({ ...measurementsData, additionalInformation: e.target.value})}/>
          </>
        }
        {props.viewType == TemplateView.CreateNew &&
          <>
            <h6 className="heading-small text-muted f-size-16">Допълнителна информация</h6>
            <Input 
                type="textarea" 
                name="text"
                resize="none"
                onChange={e => setMeasurementsData({ ...measurementsData, additionalInformation: e.target.value})}/>
          </>
        }
        {(props.viewType == TemplateView.View && inputData.additionalInformation) && 
          <>
            <h6 className="heading-small text-muted f-size-16 text-left">Допълнителна информация</h6>
            <div className="f-size-16 text-left">{inputData.additionalInformation}</div>
          </>
        }
      </FormGroup>
      {props.viewType != TemplateView.View && 
        <>
          <h6 className="heading-small text-muted f-size-16">Предписал:</h6>
          <Input
            className="form-control-alternative mb-3"
            type="text"
            defaultValue={inputData.prescribedBy}
            onChange={e => setMeasurementsData({ ...measurementsData, prescribedBy: e.target.value})}
          />
        </>
        }  
      {renderButtons()}
    </Col>
  </Row>
  </>
}

export default MeasurementsTableTemplate;