import React, { useState, useEffect } from "react";
import { Row, Col, FormGroup, Input, Card, CardBody, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from "reactstrap";
import RedirectButton from '../../../components/Buttons/RedirectButton';
import { AdminRoute } from '../../../routes';
import { SubscriptionPlan } from '../../../models/enums/SubscriptionPlan';
import { TemplateView } from '../../../models/enums/TemplateView';
import PageBreadcrumbs, { BreadCrumbItem } from "../../../components/PageBreadcrumbs";
import { Create, Delete, Update, UpdateWithoutRedirect } from "../../../helpers/requests";
import { undefinedChecker } from "../../../helpers/Checkers";
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate } from "../../../helpers/Formatters";
import { CompanyDetailsData } from "../../../models/companyDetailsData";
import { SetObjectData } from "../../../helpers/objectContextManager";
import { EmployeeDetailsData } from "../../../models/employeeDetailsData";
import { ProfileStatus } from "../../../models/enums/ProfileStatus";
import ButtonLoader from "../../../components/buttonLoader";

type CompanyTemplateProps = {
  templateData?: CompanyDetailsData;
  viewType: TemplateView;
}

const CompanyTemplate = (props?: CompanyTemplateProps): JSX.Element => {

  const breadcrumbs: Array<BreadCrumbItem> = [
    {label: "Фирма"}
  ];

  let subscriptionPlanArray = Object.values(SubscriptionPlan);
  const [dropdownOpen, setOpen] = useState(false);
  const [companyData, setCompanyData] = useState({} as CompanyDetailsData);
  const [loadingActivate, setLoadingActivate] = useState(false);
  const [loadingDeactivate, setLoadingDeactivate] = useState(false);
  const inputData = props.templateData;

  const inputLabels = [
    { label: "Име на фирмата", value: undefinedChecker(inputData, "companyName"), newValue: (newValue) => setCompanyData({ ...companyData, companyName: newValue}) },
    { label: "Име на бранда", value: undefinedChecker(inputData, "brandName"), newValue: (newValue) => setCompanyData({ ...companyData, brandName: newValue}) },
    { label: "Лице за контакт", value: undefinedChecker(inputData, "ownerName"), newValue: (newValue) => setCompanyData({ ...companyData, ownerName: newValue}) },
    { label: "ЕИК", value: undefinedChecker(inputData, "companyRegistrationNumber"), newValue: (newValue) => setCompanyData({ ...companyData, companyRegistrationNumber: newValue}) },
    { label: "Телефон", value: undefinedChecker(inputData, "ownerPhoneNumber"), newValue: (newValue) => setCompanyData({ ...companyData, ownerPhoneNumber: newValue}) },
    { label: "Мейл адрес", value: undefinedChecker(inputData, "email"), newValue: (newValue) => setCompanyData({ ...companyData, email: newValue}) },
  ];

  useEffect(() => {
    if (props.viewType == TemplateView.Edit){
      setCompanyData(inputData);
    }
    if (props.viewType == TemplateView.CreateNew){
      setCompanyData({ ...companyData, paymentPlan: SubscriptionPlan.Basic})
    }
  },[]);

  const toggle = () => setOpen(!dropdownOpen);

  const renderFields = (): JSX.Element => {

    return <>
        {inputLabels.map((data, index) => 
          <Col lg="4" key={index}>
            <FormGroup>
              <h6 className="heading-small text-muted f-size-16 my-1">
                {data.label}
              </h6>
              {props.viewType === TemplateView.View ? 
                <>
                  <hr className="my-1" />
                  <span>{data.value}</span>
                </>
                :
                <Input
                  className="form-control-alternative"
                  type="text"
                  defaultValue={data.value}
                  onChange={e => data.newValue(e.target.value)}
                />
              }
            </FormGroup>
          </Col>
        )}
        <Col lg="4">
          <FormGroup>
            <h6 className="heading-small text-muted f-size-16 my-1">
              Дата на следващо плащане
            </h6>
            {props.viewType === TemplateView.View ? 
              <>
                <hr className="my-1" />
                <span>{formatDate(inputData.subscriptionDueDate)}</span>
              </>
              :
              <DayPickerInput 
                placeholder={"DD/MM/YYYY"}
                value={formatDate(companyData.subscriptionDueDate)}
                onDayChange={day => setCompanyData({ ...companyData, subscriptionDueDate: day})} />
            }
          </FormGroup> 
        </Col>
        <Col lg="4">
          <FormGroup>
            <h6 className="heading-small text-muted f-size-16 my-1">
              Подписан договор на:
            </h6>
            {props.viewType === TemplateView.View ? 
              <>
                <hr className="my-1" />
                <span>{formatDate(inputData.subscriptionDueDate)}</span>
              </>
              :
              <span>{formatDate(companyData.contractSignDate)}</span>
            }
          </FormGroup> 
        </Col>
      </> 
  }

  const setButtonsLoader = (status: ProfileStatus, isLoading: boolean) => {
    if (status === ProfileStatus.Active){
      setLoadingActivate(isLoading);
    } else {
      setLoadingDeactivate(isLoading);
    }
  }

  const saveDataObject = () => {
    Create(companyData, "company", AdminRoute.Index);
  }  

  const updateDataObject = (data) => {
    Update(companyData, "company", AdminRoute.CompanyDetails);
    SetObjectData(data);
  }

  const manageAllProfiles = (status: ProfileStatus) => {
    setButtonsLoader(status, true);
    
    let data: EmployeeDetailsData = {
      companyId: inputData.id,
      profileStatus: status
    }
    
    UpdateWithoutRedirect(data, "employee/allEmployees")
    .res(response => {
      if (response.ok){
        setButtonsLoader(status, false);
      } else {
        console.log(response);
      }
    });
  }

  const deleteDataObject = () => {
    Delete(`company/${companyData.id}`, AdminRoute.Index);
  }  
  
  const renderButtons = (): JSX.Element => {
    switch(props.viewType) { 
      case TemplateView.CreateNew: { 
         return <>
         <Button color="success" onClick={() => {saveDataObject()}}>Запази</Button>
         </> 
      } 
      case TemplateView.Edit: { 
        return <>
        <Button color="success" onClick={() => {updateDataObject(companyData.id)}}>Запази</Button>
        <Button color="danger" onClick={() => {deleteDataObject()}}>Изтрий Фирмата</Button>
        </> 
      } 
      case TemplateView.View: { 
        return <Row>
        <Col>
          <RedirectButton buttonColor="success" buttonText="Добави Обект" url={`${AdminRoute.AddFacility}?companyId=${inputData.id}`}/>
        </Col>
        <Col className="text-right">
          <Button color="primary" onClick={() => {manageAllProfiles(ProfileStatus.Active)}}>
            Активирай 
            {loadingActivate && 
              <ButtonLoader className="ml-2" spinnerSize={18}></ButtonLoader>
            }
          </Button>
          <Button color="danger" onClick={() => {manageAllProfiles(ProfileStatus.Deactivated)}}>
            Спри достъп 
            {loadingDeactivate && 
              <ButtonLoader className="ml-2" spinnerSize={18}></ButtonLoader>
            }
          </Button>
        </Col>
        </Row> 
      } 
      default: { 
        return <span>Template Type Unknown</span>
      } 
    } 
  }

  return <>
    <PageBreadcrumbs breadcrumbsList={breadcrumbs}/>
    <Card className="bg-secondary shadow mt-2">
      <CardBody>
        <h6 className="heading-small f-size-16">
          Детайли на фирмата
          {props.viewType === TemplateView.View && 
            <RedirectButton className="optileader-teal-background white-font-color ml-4" buttonText="Редактирай" url={AdminRoute.EditCompany} />
          }
        </h6>
        {props 
        ?
        <>
          <Row className="mt-4">
            {renderFields()}
            <Col lg="4">
              <FormGroup>
                <h6 className="heading-small text-muted f-size-16 my-1">
                  Вид абонамент
                </h6>
                {props.viewType === TemplateView.View ?
                <>  
                  <hr className="my-1" />
                  <span>{inputData.paymentPlan}</span>
                </>
                :
                <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
                  <DropdownToggle caret className="px-5 optileader-teal-background white-font-color">
                    {props.viewType === TemplateView.Edit
                    ? <>{companyData.paymentPlan}</>
                    : <>{companyData.paymentPlan ? companyData.paymentPlan : "Избери"}</>
                    }
                  </DropdownToggle>
                  <DropdownMenu>
                  {subscriptionPlanArray.map((data, index) =>
                    <DropdownItem key={index} onClick={() => setCompanyData({ ...companyData, paymentPlan: data})}>{data}</DropdownItem>
                  )}
                  </DropdownMenu>
                </ButtonDropdown>
                }
              </FormGroup>
            </Col>
          </Row>
          {renderButtons()}
        </>
        :
        <h1>No Data</h1>
        }
      </CardBody>
    </Card>
  </>
}

export default CompanyTemplate;
