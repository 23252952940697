import React, { useState } from "react";
import { Card, CardBody, Button, Row, Col, TabContent, TabPane, NavItem, NavLink, Form, Nav} from "reactstrap";
import classnames from 'classnames';
import MeasurementsTableTemplate from '../OpticalMeasurement/MeasurementsTableTemplate';
import FrameInputDetails from './Order-Components/FrameInputDetails';
import ContactLensesOrderTemplate from './Order-Components/ContactLensesOrderTemplate';
import Lenses from './Order-Components/Lenses';
import { TemplateView } from "../../../models/enums/TemplateView";

const Order = () => {

  const [activeTab, setActiveTab] = useState('1');
  
  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  }
  return (
    <>
      <Card className="bg-secondary shadow">
        <CardBody>
          <h6 className="heading-small text-muted f-size-16">
              Измервания
          </h6>
          <hr className="my-2" />
          <Form>
            <div className="mt-4">
              <Row>
                <Col m={8} lg={8}>
                  <MeasurementsTableTemplate viewType={TemplateView.CreateNew}/>
                </Col>
              </Row>
              <Button color="success" className="mt-4">Запази</Button>{' '}
            </div>
          </Form>
        </CardBody>
      </Card>
      <Nav tabs className="mt-4">
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '1' }, "p-3")}
            onClick={() => { toggle('1'); }}
          >
            Рамка
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '2' }, "p-3")}
            onClick={() => { toggle('2'); }}
          >
            Лещи
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '3' }, "p-3")}
            onClick={() => { toggle('3'); }}
          >
            Контактни Лещи
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab} className="bg-secondary shadow">
        <TabPane tabId="1">
          <Row>
            <Col sm="12">
              <div className="p-lg-4">
                <FrameInputDetails viewType={TemplateView.CreateNew}/>
              </div>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="2">
          <Row>
            <Col sm="8">
              <Lenses viewType={TemplateView.CreateNew}/>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="3">
          <Row>
            <Col sm="8">
              <ContactLensesOrderTemplate viewType={TemplateView.CreateNew}/>
            </Col>
          </Row>
        </TabPane>
      </TabContent>
      </>
  );
}

export default Order;
