import React, { useState, useEffect } from 'react';
import { Row, Col, FormGroup, Input, Table } from 'reactstrap';
import { TemplateView } from '../../../../models/enums/TemplateView';
import { undefinedChecker } from '../../../../helpers/Checkers';
import { FrameDetails } from '../../../../models/frameDetails';
import { OrderStatus } from '../../../../models/enums/OrderStatus';
import { Get } from '../../../../helpers/requests';

type IndividualMeasurementsProps = {
  templateData?: FrameDetails;
  viewType: TemplateView;
  orderStatus?: OrderStatus;
  reload?: boolean;
  orderId?: string;
}

const IndividualMeasurements = (props: IndividualMeasurementsProps) => {

  const [frameData, setFrameData] = useState<FrameDetails>({});
  
  useEffect(() => {
    if(props.orderId){
      Get(`glassesOrder/${props.orderId}`)
        .catch(error => console.log(error))
        .then((json: FrameDetails) => {
          if(json){
            setFrameData({
              frameLensDiameter: json.frameLensDiameter,
              bridge: json.bridge, 
              temple: json.temple,
              frameType: json.frameType,
              brand: json.brand,
              number: json.number,
              vertex: json.vertex,
              pantoscopicTilt: json.pantoscopicTilt,
              baseCurve: json.baseCurve,
              odHeight: json.odHeight, 
              osHeight: json.osHeight, 
              od_pd_far: json.od_pd_far,
              od_pd_close: json.od_pd_close,
              os_pd_far: json.os_pd_far,
              os_pd_close: json.os_pd_close,
              progressiveChannel: json.progressiveChannel,
              additionalInformation: json.additionalInformation,
              frameCode: json.frameCode,
              lensesCode: json.lensesCode
            })
          }
      });
    }
    else {
      setFrameData({
        additionalInformation: ""
      });
    }
  },[props.reload]);

  const individualMeasurements = [
    { label: "Вертекс", value: undefinedChecker(frameData, "vertex"), newValue: (newValue) => setFrameData({ ...frameData, vertex: newValue}), placeholder: "мм" },
    { label: "Пантоскопичен ъгъл", value: undefinedChecker(frameData, "pantoscopicTilt"), newValue: (newValue) => setFrameData({ ...frameData, pantoscopicTilt: newValue}) },
    { label: "Прогресивен канал", value: undefinedChecker(frameData, "progressiveChannel"), newValue: (newValue) => setFrameData({ ...frameData, progressiveChannel: newValue}), placeholder: "мм" },
  ];

  const codesDetails = [
    { label: "Код рамка", value: undefinedChecker(frameData, "frameCode"), newValue: (newValue) => setFrameData({ ...frameData, frameCode: newValue.replace(",", ".")}) },
    { label: "Код лещи", value: undefinedChecker(frameData, "lensesCode"), newValue: (newValue) => setFrameData({ ...frameData, lensesCode: newValue.replace(",", ".")}) }
  ];
  
  const renderAdditionalInformation = (inputObject, objectProperty): JSX.Element => {
    
    return <>
      <FormGroup>
        <h6 className="heading-small text-muted f-size-16 mb-0">Допълнителна информация</h6>
        {props.viewType == TemplateView.View ? 
          <>
            <hr className="my-2" />
            <div className="f-size-16 text-left">{inputObject[objectProperty]}</div>
          </>
          :
          <>
          {inputObject[objectProperty] != undefined &&
            <Input 
              type="textarea" 
              name="text"
              resize="none"
              defaultValue={inputObject[objectProperty]}
              onChange={e => setFrameData({ ...inputObject, [objectProperty]: e.target.value})}/>
          }
          </>
        }
      </FormGroup>
    </>
  }

  const renderInputSequence = (itemCollection, columnWidth = 4): JSX.Element => {
    return <>
        {props.viewType == TemplateView.View ?
          <>
            {itemCollection.map((data, index) =>
              <Col sm={columnWidth} key={index}>
                <FormGroup className="mb-1">
                  <label className="form-control-label mb-1">
                    {data.label} {data.value ? `- ${data.value}` : ""}
                  </label>
                </FormGroup>
              </Col>
            )}
          </>
        :
          <>
            {itemCollection.map((data, index) =>
              <Col sm={columnWidth} key={index}>
                <FormGroup className="mb-1">
                  <label className="form-control-label mb-1">
                    {data.label}
                  </label>
                  <Input
                      className="form-control-alternative"
                      placeholder={data.placeholder}
                      type="text"
                      defaultValue={data.value}
                      onChange={e => data.newValue(e.target.value)}
                  />
                </FormGroup>
              </Col>
            )}
          </>
        }
    </>
  }

  const rowElement = (inputObject, objectProperty, secondObjectProperty, label): JSX.Element => {
    return <>
        <tr>
          <th className="f-size-16 align-middle text-center" scope="row">{label}</th>
          {props.viewType == TemplateView.View ?
          <>
            <td>
              <div className="f-size-18">{inputObject[objectProperty]}</div>
            </td>
            <td>
              <div className="f-size-18">{inputObject[secondObjectProperty]}</div>
            </td>
            </>
          :
          <>
            <td className="align-middle text-center">
              <Input 
                className="f-size-18 p-0 form-control"
                defaultValue={inputObject[objectProperty]}
                onChange={e => setFrameData({ ...inputObject, [objectProperty]: e.target.value})} 
                />
            </td>
            <td className="align-middle text-center">
              <Input
                className="f-size-18 p-0 form-control"
                defaultValue={inputObject[secondObjectProperty]}
                onChange={e => setFrameData({ ...inputObject, [secondObjectProperty]: e.target.value})} 
                />
            </td>
          </>
          }
        </tr>
    </>
  }
  
    return <>
        <h6 className="heading-small text-muted f-size-16 mb-0">
          Индивидуални мерки
        </h6>
        <Row>
          <Col sm={6}>
            <Row>
              <Table className="ml-3 table-measurements table-border-blue" bordered>
                <tbody>
                  <tr>
                      <th className="f-size-16 align-middle text-center"></th>
                      <th className="f-size-16 align-middle text-center">OD</th>
                      <th className="f-size-16 align-middle text-center">OS</th>
                  </tr>
                  {rowElement(frameData, "od_pd_far", "os_pd_far", "PD за далече")}
                  {rowElement(frameData, "od_pd_close", "os_pd_close", "PD за близо")}
                  {rowElement(frameData, "odHeight", "osHeight", "Височина")}
                </tbody>
              </Table>
            </Row>
          </Col>
          <Col sm={6}>
                <Row>
                    {renderInputSequence(individualMeasurements, 12)}
                </Row>
            </Col>
        </Row>
        <Row>
            <Col>
                {renderAdditionalInformation(frameData, "additionalInformation")}
            </Col>
        </Row>
        <Row>
            {renderInputSequence(codesDetails, 0)}
        </Row>
    </>
}

export default IndividualMeasurements;
