import React from "react";

const PageLoader = (): JSX.Element => {
    return <div className="d-flex justify-content-center mt-20">
        <img
            alt="..."
            src={require('../assets/img/30.gif')}
        />
    </div>
}

export default PageLoader;