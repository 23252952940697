import React, { useEffect, useState } from "react";
import { Card } from "reactstrap";
import EmployeeList, { EmployeeListItem } from '../Employee/EmployeesList';
import FacilityTemplate, { FacilityDetailsData } from './FacilityTemplate';
import { TemplateView } from '../../../models/enums/TemplateView';
import { GetObjectData } from "../../../helpers/objectContextManager";
import { Get } from "../../../helpers/requests";
import PageLoader from "../../../components/pageLoader";

export type FacilityViewModel = {
  facilityDetails : FacilityDetailsData;
  employees: EmployeeListItem[];
}

const FacilityDetails = (props): JSX.Element => {

  var url = new URL(window.location.href);
  var id = url.searchParams.get("id");

  const [data, setData] = useState<FacilityViewModel>();
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(true);
    
  useEffect(() => {
    Get(`facility/${id}`)
    .catch(error => console.log(error))
    .then((json: FacilityViewModel) => {
      setData(json);
      setLoading(false);  
      setReload(false);      
    });
  },[reload]);

  return <>
  {loading ? <PageLoader></PageLoader> : 
    <>
      <FacilityTemplate viewType={TemplateView.View} templateData={data.facilityDetails} />
      <Card className="mt-4">
        <EmployeeList listData={data.employees} reloadList={setReload} />
      </Card>
    </>
  }
  </>
}

export default FacilityDetails;
