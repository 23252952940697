import React, { useCallback, useContext, useEffect } from "react";
import { Card, Form, FormGroup, InputGroupAddon, InputGroupText, Input, InputGroup, Row } from "reactstrap";
import RedirectButton from '../../components/Buttons/RedirectButton';
import CustomersList from "./Customer/CustomersList";
import { ClientRoute } from '../../routes';
import { UserContext } from "../../userContext";
import { useState } from "react";
import { Get } from "../../helpers/requests";
import PageLoader from "../../components/pageLoader";
import { CustomersListView } from "../../models/customer/customersListView";
import PaginationBar from "../../helpers/PaginationBar";

const Customers = () => {

  const {objectData} = useContext(UserContext);

  const [data, setData] = useState({} as CustomersListView);
  const [search, setSearch] = useState("");
  const [pageResults, setPageResults] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const loadData = (apiUrl: string) => {
    Get(apiUrl)
      .catch(error => console.log(error))
      .then((json: CustomersListView) => {                      
          // console.log(json.results);    
          setData(json);
          if(json.results){
            setPageResults(json.results.length);
          }  
      });
  }

  useEffect(() => {
    loadData(`customer/${ objectData.getObjectData.companyId }/customers/search`);
  },[]);

  useEffect(() => {
    if(currentPage){
      loadData(`customer/${ objectData.getObjectData.companyId }/customers/search?pageSize=25&currentPage=${currentPage}&searchText=${search}`);
    }
  }, [currentPage]);

  const getResults = (searchText) => {
    setSearch(searchText);
    loadData(`customer/${ objectData.getObjectData.companyId }/customers/search?searchText=${searchText}`);
  }

  const changeCurrentPage = useCallback(
    (newValue) => {
      setCurrentPage(newValue);
    },[],
  );

  return (
    <>
      <Card className="p-4 d-flex-none align-middle w-100">
        <Form className="navbar-search mr-3">
          <FormGroup className="mb-0">
            <InputGroup className="input-group-alternative" 
              onChange={e => getResults(e.target.value)}>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fas fa-search" />
                </InputGroupText>
              </InputGroupAddon>
              <Input placeholder="Търси Клиент" type="text"/>
            </InputGroup>
          </FormGroup>
        </Form>
        <RedirectButton className="optileader-teal-background white-font-color" buttonText="Добави Клиент" url={ClientRoute.AddCustomer}/>
      </Card>
      {data.results
        ?
          <>
            <Row className="p-3">
              <span className="d-flex align-items-center pr-4">Резултати: {pageResults} от {data.recordCount}</span>
            </Row>
            <Row className="p-3">
              <PaginationBar currentPage={data.currentPage} pageCount={data.pageCount} setCurrentPage={changeCurrentPage}/>
            </Row>
            <CustomersList listData={data.results} />
            <Row className="p-3">
              <PaginationBar currentPage={data.currentPage} pageCount={data.pageCount} setCurrentPage={changeCurrentPage}/>
            </Row>
          </>
        :
        <PageLoader></PageLoader>
      }
    </>
  ); 
}

export default Customers;