import React from "react";
import { TemplateView } from "../../../models/enums/TemplateView";
import GlassesOrderTemplate from "./Order-Components/GlassesOrderTemplate";
import { OrderStatus } from "../../../models/enums/OrderStatus";

const AddGlassesOrder = () => {
  var url = new URL(window.location.href);
  var measurementId = url.searchParams.get("measurementId");
  return <>
    <GlassesOrderTemplate viewType={TemplateView.CreateNew} latestOpticalMeasurementId={measurementId} orderStatus={OrderStatus.Processing}/>
  </>
}

export default AddGlassesOrder;
