import React, { CSSProperties, useContext, useState } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { Button, Container, Row, Card, CardBody, FormGroup, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Col } from "reactstrap";
import ButtonLoader from '../components/buttonLoader';
import { Get } from '../helpers/requests';
import { LoginUser } from '../helpers/loginUser';
import { AdminRoute, ClientRoute } from '../routes';

import routes from "../routes";
import { UserContext } from "../userContext";
import { UserRole } from "../models/enums/UserRole";
import { ProfileStatus } from "../models/enums/ProfileStatus";

const Auth = (props) => {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [infoText, setInfoText] = useState("");
  const {objectData} = useContext(UserContext);
  const history = useHistory();

  objectData.setObjectData(null);
  
  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component} 
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const login = () => {
    setLoading(true);
    Get(`login/${email}/${password}`)
    .catch(error => console.log(error))
    .then((json: any) => {
      // console.log(json);
      if(json) {
        if(json.profileStatus === ProfileStatus.Deactivated) {
          setLoading(false);
          setInfoText("Подновете абонаментът си.");
        }
        else {
          if(LoginUser(json)){
            setLoading(false);
            localStorage.setItem('v', json.verificationToken);
            objectData.setObjectData(json);
            
            json.role == UserRole.Administrator ? history.push(AdminRoute.Index) : history.push(ClientRoute.Index);
          }
        }
      } else {
        setLoading(false);
        setInfoText("Проверете дали правилно въвеждате email/password полетата.");
      }
    });
  }
  const logoStyle: CSSProperties = {
    fontFamily: "Comfortaa",
    visibility: "hidden",
    height: '0px', 
    width : '0px'
  }

  return <div className="main-content login-screen">
    <div style={logoStyle}>a</div>
        <div className="header bg-gradient-info py-7 py-lg-8">
          {/* <Container>
            <div className="header-body text-center mb-7">
              <Row className="justify-content-center">
                <Col lg="5" md="6">
                  <h1 className="text-white optileader-logo">Optileader</h1>
                </Col>
              </Row>
            </div>
          </Container> */}
          <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-default"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
        </div>
        {/* Page content */}
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Col className="pt-2 auth-info-message">
              <span className="text-white"><span className="optileader-logo pr-2">Optileader</span> е онлайн платформа разработена за управление на данни, свързани с ежедневната дейност на оптиките. 
                <p className="pt-3 font-weight-bold">Предимства:</p>
                <p><i className="fas fa-chevron-right pr-2"></i> непрекъснат достъп от всяко устройство свързано с интернет</p>
                <p><i className="fas fa-chevron-right pr-2"></i> съхранение на данните на клиентите и ползваните от тях рамки, диоптрични и контактни лещи</p>
                <p><i className="fas fa-chevron-right pr-2"></i> създава се и история на клиента</p>
              </span>
            </Col>
            <Col lg="6" md="8" className="auth-info-message pr-0">
              <Card className="bg-secondary shadow border-0">
                <CardBody className="px-lg-5 py-lg-5">
                  <h4 className="text-danger text-center">{infoText}</h4>
                  <Form role="form" className="register-form">
                    <FormGroup>
                      <InputGroup className="input-group-alternative mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input placeholder="Email" type="email" onChange={e => setEmail(e.target.value)}/>
                      </InputGroup>
                    </FormGroup>
                    <FormGroup>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input placeholder="Парола" type="password" onChange={e => setPassword(e.target.value)}/>
                      </InputGroup>
                    </FormGroup>
                    <div className="text-center">
                      <Button className="mt-4" color="primary" type="button" onClick={() => login()}>
                        Вход 
                        {loading && 
                          <ButtonLoader className="ml-2" spinnerSize={18}></ButtonLoader>
                        }
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
            <Switch>{getRoutes(routes)}</Switch>
          </Row>
          <Row>
            <Col className="px-0">
                <p className="auth-info-message px-3 text-white text-center">
                  <span className="font-weight-bold">Контакти:</span>
                  <i className="fas fa-phone pl-4 pr-2"></i>0895263093 
                  <i className="fas fa-envelope pl-4 pr-2"></i> optileader.bg@gmail.com 
                  <a href="https://www.facebook.com/Optileader-103744925081824" className="facebook-link"><i className="fab fa-facebook-square pl-4 pr-2"></i> Optileader</a>
                </p>
            </Col>
          </Row>
        </Container>
      </div>
}

export default Auth;
