import React from "react";
import { Table, Button } from "reactstrap";
import RedirectButton from '../../../components/Buttons/RedirectButton';
import { AdminRoute } from '../../../routes';
import { EmployeeDetailsData } from "../../../models/employeeDetailsData";
import { ProfileStatus } from "../../../models/enums/ProfileStatus";
import { UpdateWithoutRedirect } from "../../../helpers/requests";

export type EmployeeListItem = {
    id: string;
    employeeName: string;
    employeeEmail: string;
    ordersCount: string;
    profileStatus: ProfileStatus;
}

type EmployeesListProps = {
    listData: Array<EmployeeListItem>;
    reloadList: any;
}

const EmployeesList = (props: EmployeesListProps): JSX.Element => {

  const manageProfile = (employeeId, status: ProfileStatus) => {
    props.reloadList(true);
    let data: EmployeeDetailsData = {
      id: employeeId,
      profileStatus: status
    }

    UpdateWithoutRedirect(data, "employee")
    .res(response => {
      if (response.ok){
        props.reloadList(false);
      } else {
        console.log(response);
      }
    });
  }

  return <>
      <Table className="align-items-center table-flush table-striped" responsive>
        <thead className="silver-background black-font-color">
          <tr className="text-center">
            <th className="f-size-16" scope="col"></th>
            <th className="f-size-16" scope="col">Име</th>
            <th className="f-size-16" scope="col">Мейл</th>
            <th className="f-size-16" scope="col">Брой Поръчки</th>
            <th className="f-size-16" scope="col">Статус</th>
          </tr>
        </thead>
        <tbody>
          {props.listData.map((data, index) =>
            <tr className="text-center" key={index} >
              <td><RedirectButton className="optileader-teal-background white-font-color" buttonText="Детайли" url={`${AdminRoute.EmployeeDetails}?id=${data.id}`}/></td>
              <td className="f-size-16">{data.employeeName}</td>
              <td className="f-size-16">{data.employeeEmail}</td>
              <td className="f-size-16">{data.ordersCount}</td>
              <td className="f-size-16">
              {data.profileStatus === ProfileStatus.Active
                ? <Button color="danger" onClick={() => manageProfile(data.id, ProfileStatus.Deactivated)}>Деактивирай</Button>
                : <Button color="success" onClick={() => manageProfile(data.id, ProfileStatus.Active)}>Активирай</Button>
              }
              </td>
            </tr>
          )}
        </tbody>
      </Table>
  </>
}

export default EmployeesList;