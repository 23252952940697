import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Button, FormGroup, Input, Card, CardBody } from "reactstrap";
import RedirectButton from '../../../components/Buttons/RedirectButton';
import { SharedRoute, ClientRoute } from '../../../routes';
import { TemplateView } from '../../../models/enums/TemplateView';
import { undefinedChecker } from "../../../helpers/Checkers";
import { Delete, Update, serverAddress } from "../../../helpers/requests";
import { GetObjectData, SetObjectData } from "../../../helpers/objectContextManager";
import { UserContext } from "../../../userContext";

export type CustomerTemplateData = {
  id?: string;
  name?: string;
  email?: string;
  phone?: string;
  dateCreated?: string;
  createdBy?: string;
  companyId?: string;
  description?: string;
  employeeId?: string;
}

type CustomerTemplateProps = {
  templateData?: CustomerTemplateData;
  viewType: TemplateView;
  measurementsList?: number;
  latestOpticalMeasurement?: string;
}

const CustomerTemplate = (props: CustomerTemplateProps): JSX.Element => {

  var url = new URL(window.location.href);
  var customerId = url.searchParams.get("id");

  const inputData = props.templateData;
  const [customerData, setCustomerData] = useState({} as CustomerTemplateData);
  const {objectData} = useContext(UserContext);
  
  const customerDetails = [
    { label: "Имена", value: undefinedChecker(inputData, "name"), newValue: (newValue) => setCustomerData({ ...customerData, name: newValue}) },
    { label: "Телефон", value: undefinedChecker(inputData, "phone"), newValue: (newValue) => setCustomerData({ ...customerData, phone: newValue}) },
    { label: "Имейл", value: undefinedChecker(inputData, "email"), newValue: (newValue) => setCustomerData({ ...customerData, email: newValue}) },
  ];

  useEffect(() => {
    if (props.viewType == TemplateView.Edit){
      setCustomerData(inputData);
    }
  },[]);

  const renderFields = (): JSX.Element => {
    switch (props.viewType) {
      case TemplateView.CreateNew: {
        return <>
          {customerDetails.map((data, index) =>
            <Col lg="4" key={index}>
              <FormGroup>
                <h6 className="heading-small text-muted f-size-16 my-1">
                  {data.label}
                </h6>
                <Input
                  className="form-control-alternative"
                  type="text"
                  onChange={e => data.newValue(e.target.value)}
                />
              </FormGroup>
            </Col>
          )}
          <Col lg={4}>
            <FormGroup>
              <label>Допълнителна Информация</label>
              <Input
                className="form-control-alternative"
                rows="4"
                placeholder="Тук можете да опишете специфични особености на клиента."
                type="textarea"
                onChange={e => setCustomerData({ ...customerData, description: e.target.value})}
              />
            </FormGroup>
          </Col>
        </>
      }
      case TemplateView.Edit: {
        return <>
          {customerDetails.map((data, index) =>
            <Col lg="4" key={index}>
              <FormGroup>
                <h6 className="heading-small text-muted f-size-16 my-1">
                  {data.label}
                </h6>
                <Input
                  className="form-control-alternative"
                  type="text"
                  defaultValue={data.value}
                  onChange={e => data.newValue(e.target.value)}
                />
              </FormGroup>
            </Col>
          )}<Col lg={4}>
          <FormGroup>
            <label>Допълнителна Информация</label>
            <Input
              className="form-control-alternative"
              rows="4"
              defaultValue={inputData.description}
              type="textarea"
              onChange={e => setCustomerData({ ...customerData, description: e.target.value})}
            />
          </FormGroup>
        </Col></>
      }
      case TemplateView.View: {
        return <>
         {customerDetails.map((data, index) =>
            <Col lg="4" key={index}>
              <FormGroup>
                <h6 className="heading-small text-muted f-size-16 my-1">
                  {data.label}
                </h6>
                <hr className="my-1" />
                <span>{data.value}</span>
              </FormGroup>
            </Col>
          )}
          <Col lg="4">
              <FormGroup>
                <h6 className="heading-small text-muted f-size-16 my-1">
                  Допълнителна Информация
                </h6>
                <hr className="my-1" />
                <span>{inputData.description}</span>
              </FormGroup>
            </Col>
        </>
      }
      default: {
        return <span>Template Type Unknown</span>
      }
    }
  }

  const saveDataObject = () => {
    customerData.companyId = objectData.getObjectData.companyId;
    customerData.createdBy = objectData.getObjectData.id;

    (async () => {
      const rawResponse = await fetch(`${serverAddress}/customer`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(customerData)
      });
      const content = await rawResponse.json();
      
      SetObjectData(content.id);      
      window.location.href = `${ClientRoute.CustomerDetails}?id=${content.id}`;
    })();
  }  

  const updateDataObject = () => {
    Update(customerData, "customer", `${ClientRoute.CustomerDetails}?id=${customerId}`);
  }

  const deleteDataObject = () => {
    Delete(`customer/${customerData.id}`, ClientRoute.Customers);
  } 

  const renderButtons = (): JSX.Element => {
    switch (props.viewType) {
      case TemplateView.CreateNew: {
        return <>
           <Button color="success" className="ml-3" onClick={() => {saveDataObject()}}>Запази</Button>
        </>
      }
      case TemplateView.Edit: {
        return <>
          <Button color="success" onClick={() => {updateDataObject()}}>Запази</Button>
          <Button color="danger" onClick={() => {deleteDataObject()}}>Изтрий Клиент</Button>
        </>
      }
      case TemplateView.View: {
        return <>
          {props.measurementsList === 0 &&
            <>
              <Button color="secondary" className="ml-3 disabled-button" disabled>Добави Очила</Button>
              <Button color="secondary" className="disabled-button" disabled>Добави Лещи</Button>
            </>
          }
          {props.latestOpticalMeasurement.length > 1 && 
            <>
              <RedirectButton buttonColor="success" buttonText="Добави Очила" url={`${ClientRoute.AddGlassesOrder}?measurementId=${props.latestOpticalMeasurement}&customerId=${customerId}`} className="ml-3"/>
              <RedirectButton buttonColor="success" buttonText="Добави Лещи" url={`${ClientRoute.AddContactLensesOrder}?measurementId=${props.latestOpticalMeasurement}&customerId=${customerId}`}/>
            </>
          }
          <RedirectButton buttonColor="success" buttonText="Добави Рецепта" url={`${ClientRoute.AddOpticalMeasurement}?customerId=${customerId}`}/>
          <RedirectButton buttonColor="success" buttonText="Добави Рефракция" url={`${ClientRoute.AddRefraction}?customerId=${customerId}`}/>
        </>
      }
      default: {
        return <span>Template Type Unknown</span>
      }
    }
  }

  return <>
    <Card className="bg-secondary shadow">
      <CardBody>
        <h6 className="heading-small f-size-16">
          Детайли на клиента
          {props.viewType === TemplateView.View &&
            <RedirectButton className="optileader-teal-background white-font-color ml-4" buttonText="Редактирай" url={`${SharedRoute.EditCustomer}?id=${inputData.id}`}/>
          }
        </h6>
        <Row className="mt-4">
          {renderFields()}
        </Row>
        <Row>
          {renderButtons()}
        </Row>
      </CardBody>
    </Card>
  </>
}

export default CustomerTemplate;
