import React, { useContext } from "react";
import { Button, CardBody, Row, Col, Modal, ModalBody, Input } from "reactstrap";
import { UserContext } from "../../userContext";
import { useState } from "react";
import { UpdateWithoutRedirect } from "../../helpers/requests";
import { EmployeeDetailsData } from "../../models/employeeDetailsData";
import Logout from "../../helpers/Logout";

function UserSettings () {

  const {objectData} = useContext(UserContext);
  const [password, setPassword] = useState("");
  const [modal, setModal] = useState(false);  

  const toggleModal = () => setModal(!modal);

  const updateEmployeePassword = () => {
    let updateObject: EmployeeDetailsData = {id: objectData.getObjectData.id, password: password}; 
    UpdateWithoutRedirect(updateObject, "employee");
    Logout();
  }

  return (
    <>
      <CardBody className="light-silver-background">
        <Row>
            <Col>
            <h6 className="heading-small text-muted f-size-16 my-1">
                Парола
            </h6>
            </Col>
        </Row>
        <Row>
            <Col>
              <div className="alert alert-teal" role="alert">
                Внимание: при смяна на паролата, ще трябва да влезете отново в профила си с новата парола.
              </div>
              <Button color="primary" onClick={toggleModal}>Промени парола</Button>
              <Modal isOpen={modal} toggle={toggleModal}>
                <ModalBody className="light-silver-background">
                    <h6 className="heading-small text-muted f-size-16 my-1">
                        Въведете новата парола
                    </h6>
                    <Input
                        className="form-control-alternative"
                        type="text"
                        onChange={e => setPassword(e.target.value)}
                    />
                </ModalBody>
                <Row className="light-silver-background py-4 no-gutters">
                    <Col className="text-center">
                      <Button color="success" onClick={() => updateEmployeePassword()}>Запази</Button>
                      <Button color="danger" onClick={toggleModal}>Откажи</Button>
                    </Col>
                </Row>
              </Modal>
            </Col>
        </Row>
      </CardBody>
    </>
  ); 
}

export default UserSettings;