import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, CardBody, Input } from 'reactstrap';
import { TemplateView } from '../../../models/enums/TemplateView';
import { ClientRoute } from '../../../routes';
import { Update, Delete, Get } from '../../../helpers/requests';
import { MeasurementsDetails } from '../../../models/measurementDetails';
import CommonConditionTemplate from './CommonConditionTemplate';
import { CommonCondition } from '../../../models/commonCondition';
import LenseMeasurementsTemplate from './LenseMeasurementsTemplate';
import { LenseMeasurements } from '../../../models/LenseMeasurements';
import LastUsedLenses from './LastUsedLenses';
import ConsultWithSpecialist from './ConsultWithSpecialist';
import LensesType from './LensesType';
import { RefractionProtocol } from '../../../models/refractionProtocol';
import { UserContext } from '../../../userContext';
import { SetObjectData } from '../../../helpers/objectContextManager';
import PageLoader from '../../../components/pageLoader';
import OtherObservationsTemplate from './OtherObservationsTemplate';
import { OtherObservations } from '../../../models/otherObservations';

type MeasurementsDetailsProps = {
    templateData?: MeasurementsDetails;
    populateData?: any;
    viewType: TemplateView;
}

const EditRefraction = (props: MeasurementsDetailsProps) => {

    var url = new URL(window.location.href);
    var id = url.searchParams.get("id");
    
    const [refractionProtocolData, setRefractionProtocolData] = useState<RefractionProtocol>({});
    const [conditionData, setConditionData] = useState<CommonCondition>({});
    const [lenseMeasurements, setLenseMeasurements] = useState<LenseMeasurements>({});
    const [lastUsedLenses, setLastUsedLenses] = useState<LenseMeasurements>({});
    const [otherObservationsData, setOtherObservationsData] = useState({} as OtherObservations);
    const [consultWithSpecialist, setConsultWithSpecialist] = useState("");
    const [lensesType, setLensesType] = useState("");
    const [additionalInformation, setAdditionalInformation] = useState("");
    const {objectData} = useContext(UserContext);
    const [loading, setLoading] = useState(true);

    const employeeId = objectData.getObjectData.id;
    const refractionId = id;
    
    useEffect(() => {
        Get(`refraction/${refractionId}`)
        .catch(error => console.log(error))
        .then((json: RefractionProtocol) => {                            
            setRefractionProtocolData(json);
            setLoading(false);    
        });
    },[]);
        
    const updateDataObject = () => {
      
      let newLenseMeasurement: LenseMeasurements = { ...lenseMeasurements, prescribedBy: objectData.getObjectData.name};
      let protocolData: RefractionProtocol = {};
      protocolData = {
        ...conditionData,
        ...otherObservationsData,
        additionalInformation: additionalInformation,
        lastUsedGlassesType: lensesType,
        id: refractionId,
        employeeId: employeeId,
        consultWithSpecialist: consultWithSpecialist,
        type: lensesType,
        newMeasurement: newLenseMeasurement,
        latestMeasurement: lastUsedLenses,
      };
      // console.log(protocolData);
      SetObjectData(protocolData.clientId);
      Update(protocolData, "refraction", `${ClientRoute.CustomerDetails}?id=${protocolData.clientId}`);
    }

    const deleteDataObject = () => {
      SetObjectData(refractionProtocolData.clientId);
      Delete(`refraction/${refractionId}`, `${ClientRoute.CustomerDetails}?id=${refractionProtocolData.clientId}`);
    }

    return <> {loading ? <PageLoader></PageLoader> : <>
      <Card className="bg-secondary shadow mb-4">
        <CardBody className="py-3">
          <h6 className="heading-small text-muted f-size-16 mb-0">
            Общо състояние
          </h6>
          <CommonConditionTemplate viewType={TemplateView.Edit} setConditionData={setConditionData} templateData={refractionProtocolData}/>
        </CardBody>
      </Card>
      <Card className="bg-secondary shadow mb-4">
        <CardBody className="py-3">
          <h6 className="heading-small text-muted f-size-16 mb-0">
            Ново измерване
          </h6>
          <LenseMeasurementsTemplate className="mt-3 lenses-table" viewType={TemplateView.Edit} setMeasurementstData={setLenseMeasurements} templateData={refractionProtocolData.newMeasurement}/>
        </CardBody>
      </Card>
      <Card className="bg-secondary shadow mb-4">
        <CardBody className="py-3">
          <h6 className="heading-small text-muted f-size-16 mb-0">
            Други наблюдения
          </h6>
          <OtherObservationsTemplate className="mt-4 lenses-table" viewType={TemplateView.Edit} setOtherObservationData={setOtherObservationsData} templateData={refractionProtocolData}/>
          <ConsultWithSpecialist className="mt-4" viewType={TemplateView.Edit} setCounsultChoice={setConsultWithSpecialist} templateData={refractionProtocolData.consultWithSpecialist}/>
        </CardBody>
      </Card>
      <Card className="bg-secondary shadow mb-4">
        <CardBody className="py-3">
          <h6 className="heading-small text-muted f-size-16 mb-0">
            Последно ползвани очила
          </h6>
          <LastUsedLenses className="mt-3 lenses-table" viewType={TemplateView.Edit} setMeasurementsData={setLastUsedLenses} templateData={refractionProtocolData.latestMeasurement}/>
          <LensesType className="mt-4" viewType={TemplateView.Edit} setLenseTypeChoice={setLensesType} templateData={refractionProtocolData.lastUsedGlassesType}/>
        </CardBody>
      </Card>
      <Card className="bg-secondary shadow mb-4">
        <CardBody>
          <h6 className="heading-small text-muted f-size-16">Допълнителна информация</h6>
          <Input 
              type="textarea" 
              name="text"
              resize="none"
              className="mb-2"
              defaultValue={refractionProtocolData.additionalInformation}
              onChange={e => setAdditionalInformation(e.target.value)}/>
          <Button className="mt-4" color="success" onClick={() => updateDataObject()}>Запази</Button>
          <Button className="mt-4" color="danger" onClick={() => deleteDataObject()}>Изтрий</Button>
        </CardBody>
      </Card>
  </>
}
</>
}

export default EditRefraction;