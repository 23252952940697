import React, { useContext, useEffect, useState } from "react";
import { Get } from "../../helpers/requests";
import { UserContext } from "../../userContext";
import OrdersTableTemplate from "../../components/OrdersTableTemplate";

const Orders = () => {

  const [data, setData] = useState<any>();
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(true);
  const {objectData} = useContext(UserContext);
  
  const facilityId = objectData.getObjectData.facilityId;

  useEffect(() => {
    Get(`order/${facilityId}`)
    .catch(error => console.log(error))
    .then((json) => {
      setData(json);
      setLoading(false);        
    });
    setReload(false);
  },[reload]);

  const reloadTableData = (newValue) => {
    setReload(newValue);
  }
  
  return <>
    <OrdersTableTemplate loading={loading} data={data} reload={reloadTableData}/>
  </>
}

export default Orders;
