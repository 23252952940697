export class GlassesOrderData {
    id?: string;
    companyId?: string;
    facilityId?: string;
    customerId?: string;
    employeeId?: string;
    status?: string;
    opticalMeasurementId?: string;
    frameLensDiameter?: string;
    bridge?: string;
    temple?: string;
    frameType?: string;
    brand?: string;
    number?: string;
    vertex?: string;
    pantoscopicTilt?: string;
    baseCurve?: string;
    manufacturer?: string;
    materialType?: string;
    designType?: string;
    lensesType?: string;
    product?: string;
    coating?: string;
    fotoTechnologyType?: string;
    coloring?: string;
    lensDiameter?: string;
    od_sphere?: string;
    od_cylinder?: string;
    od_axis?: string;
    od_addition?: string;
    os_sphere?: string;
    os_cylinder?: string;
    os_axis?: string;
    os_addition?: string;
    productDetails?: string;
    osDioptersDetails?: string;
    odDioptersDetails?: string;
    employeeName?: string;
    odHeight?: string;
    osHeight?: string;
    additionalInformation?: string;
    distanceType?: string;
    frameCode?: string;
    lensesCode?: string;
}