import React from 'react';
import EmployeeTemplate from './EmployeeTemplate';
import { EmployeeDetailsData } from '../../../models/employeeDetailsData';
import { TemplateView } from '../../../models/enums/TemplateView';

const AddEmployee = (props) => {

  var url = new URL(window.location.href);
  var companyId = url.searchParams.get("companyId");
  var facilityId = url.searchParams.get("facilityId");

  const newEmployeeData: EmployeeDetailsData = {
    companyId: companyId, 
    facilityId: facilityId
  }

  return <>
    <EmployeeTemplate viewType={TemplateView.CreateNew} templateData={newEmployeeData}/>
  </>
}

export default AddEmployee;