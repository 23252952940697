import React from 'react';
import CustomerTemplate from './CustomerTemplate';
import { TemplateView } from '../../../models/enums/TemplateView';

const AddEmployee = (props) => {

  return <>
    <CustomerTemplate viewType={TemplateView.CreateNew}/>
  </>
}

export default AddEmployee;