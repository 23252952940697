import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media, Col
} from "reactstrap";
import Logout from "../../helpers/Logout";
import { ClientRoute } from "../../routes";
import { UserContext } from "../../userContext";

const AdminNavbar = (props) => {

  let userInitials = "";
  let userName = "";
  const {objectData} = useContext(UserContext);
  
  if(objectData.getObjectData) {
    userName =  objectData.getObjectData.name;
    let userNames = objectData.getObjectData.name.split(" ");
    userNames.forEach(element => {
      userInitials += element.charAt(0);
    });
  }

  const sideNav = document.querySelector("#sidenav-main");
  const contentWrapper = document.querySelector(".main-content");
  const documentWidth = window.innerWidth;

  const toggleMenu = () => {
    sideNav.classList.toggle("expanded-nav");
    contentWrapper.classList.toggle("expanded-menu");
  }

  useEffect(() => {
    if(documentWidth > 1400 && sideNav)
    {
      toggleMenu();
    }
    
  },[sideNav]);

    return <>
        <Navbar className="navbar-top navbar-dark shadow p-2" expand="md" id="navbar-main">
          <Col className="px-0">
            <i onClick={() => toggleMenu()} className="fas fa-bars f-size-22 align-middle p-3 sidebar-btn"></i>
            <h1 className="optileader-logo optileader-teal-color mb-0 d-inline align-middle">
              OptiLeader
            </h1>
          </Col>
          <Col className="d-flex justify-content-center">
            <h4 className="h4 mb-0 text-uppercase">
              {props.brandText}
            </h4>
          </Col>
          <Col className="d-flex justify-content-end">
          <Nav className="align-items-center d-none d-md-flex" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="pr-0" nav>
                  <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      {/* <img
                        alt="..."
                        src={require("assets/img/theme/team-4-800x800.jpg")}
                      /> */}
                      {userInitials}
                    </span>
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold text-dark">
                        {userName}
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  {/* <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">Welcome!</h6>
                  </DropdownItem>
                  <DropdownItem to="/admin/user-profile" tag={Link}>
                    <i className="ni ni-single-02" />
                    <span>My profile</span>
                  </DropdownItem> */}
                  <DropdownItem onClick={() => {window.location.href = ClientRoute.UserSettings}}>
                    <i className="ni ni-settings-gear-65" />
                    <span>Settings</span>
                  </DropdownItem>
                  {/* <DropdownItem to="/admin/user-profile" tag={Link}>
                    <i className="ni ni-calendar-grid-58" />
                    <span>Activity</span>
                  </DropdownItem>
                  <DropdownItem to="/admin/user-profile" tag={Link}>
                    <i className="ni ni-support-16" />
                    <span>Support</span>
                  </DropdownItem> */}
                  <DropdownItem divider />
                  <DropdownItem href="/auth/login" onClick={() => Logout()}>
                    <i className="ni ni-user-run" />
                    <span>Logout</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Col>
        </Navbar>
      </>
}

export default AdminNavbar;
