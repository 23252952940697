
import React, { useEffect, useState } from "react";
import { Row, Col, FormGroup, Card, CardBody } from "reactstrap";
import RedirectButton from "../../../components/Buttons/RedirectButton";
import PageLoader from "../../../components/pageLoader";
import { undefinedChecker } from "../../../helpers/Checkers";
import { Get } from "../../../helpers/requests";
import { ContactInputData } from "../../../models/contact/contactInputData";
import { ClientRoute } from "../../../routes";

const ViewContact = (): JSX.Element => {

    var url = new URL(window.location.href);
    var id = url.searchParams.get("id");

    const [data, setData] = useState({} as ContactInputData);
    const [loading, setLoading] = useState(Boolean);

    useEffect(() => {
        setLoading(true);
        Get(`contacts/${id}`)
            .catch(error => console.log(error))
            .then((json: ContactInputData) => {
                setData(json);
                setLoading(false);        
            });
    },[]);

    const viewData = [
        { label: "Име", value: undefinedChecker(data, "name") },
        { label: "Адрес", value: undefinedChecker(data, "address") },
        { label: "Лице за контакт / МОЛ", value: undefinedChecker(data, "contactPerson") },
        { label: "ЕИК", value: undefinedChecker(data, "companyRegistrationNumber") },
        { label: "Телефон", value: undefinedChecker(data, "phoneNumber") },
        { label: "Мейл адрес", value: undefinedChecker(data, "email") },
    ];
      
  return <>
    {loading 
        ? 
        <PageLoader></PageLoader> 
        :
        <>
            {data
                ?
                <Card className="bg-secondary shadow">
                    <CardBody>
                        <h6 className="heading-small f-size-16">
                            Детайли на контакта
                            <RedirectButton className="optileader-teal-background white-font-color ml-4" buttonText="Редактирай" url={`${ClientRoute.EditContact}?id=${data.id}`}/>
                        </h6>
                        <Row className="mt-4">
                            {viewData.map((data, index) =>
                                <Col lg="4" key={index}>
                                    <FormGroup>
                                        <h6 className="heading-small text-muted f-size-16 my-1">
                                            {data.label}
                                        </h6>
                                        <hr className="my-1" />
                                        <span>{data.value}</span>
                                    </FormGroup>
                                </Col>
                            )}
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <h6 className="heading-small text-muted f-size-16">Допълнителна информация</h6>
                                    <hr className="my-2" />
                                    <div className="f-size-16 text-left">{data.additionalInformation}</div>
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                :
                <h1>Няма данни.</h1>
            }
        </>
    }
  </>
}

export default ViewContact;
