import React, { useState } from "react";
import { Table, Button, Row, Col, Modal, ModalBody } from "reactstrap";
import { OrderStatus } from "../models/enums/OrderStatus";
import { OrderType } from "../models/enums/orderTypes";
import PageLoader from "./pageLoader";
import { formatDate } from "../helpers/Formatters";
import { DeleteWithoutRedirect, UpdateWithoutRedirect } from "../helpers/requests";
import { OrderListItem } from "../models/order/orderListItem";
import { ClientRoute } from "../routes";
import { OrderStatusText } from "../models/order/orderStatusText";
import { MeasurementsDetails } from "../models/measurementDetails";
import { useHistory } from "react-router-dom";
import NoAvailableData from "./noAvailableData";

export const tableHeader = [
  "Дата",
  "Марка",
  "Продукт",
  "Диоптри",
  "Статус"
];

type OrdersTableTemplateProps = {
  loading?: boolean;
  data: any;
  reload?: any;
}

const OrdersTableTemplate = (props: OrdersTableTemplateProps) => {

  const [modal, setModal] = useState(false);
  const [orderId, setOrderId] = useState("");
  const history = useHistory();

  const toggleModal = (orderId) => {
    setModal(!modal);
    setOrderId(orderId);
  };

  const reloadData = (objectData) => {
    props.reload(objectData);
  }
  
  const requestOrder = (orderId) => {
    UpdateWithoutRedirect({ id: orderId, status: OrderStatus.Requested } as OrderListItem, "order")
    .res(response => {
      if (response.ok){
        reloadData(true);
      } else {
        console.log(response);
      }
    });
  }

  const finishOrder = (orderId) => {
    UpdateWithoutRedirect({ id: orderId, status: OrderStatus.Completed } as OrderListItem, "order", )
    .res(response => {
      if (response.ok){
        reloadData(true);
      } else {
        // console.log(response);
      }
    });
  }

  const deleteOrder = (orderId) => {
    
    DeleteWithoutRedirect(`order/${orderId}`)
    .res(response => {
      if (response.ok){
        reloadData(true);
        setModal(false);
      } else {
        // console.log(response);
        setModal(false);
      }
    });
  }

  const splitString = (input: string): JSX.Element => {

    var str = input,
    delimiter = ',',
    start = 4,
    tokens = str.split(delimiter).slice(start),
    result = tokens.join(delimiter);

    var tokens2 = str.split(delimiter).slice(0, start),
    result2 = tokens2.join(delimiter); 

    return <>
      <div className="f-size-16 mb-0">{result2}</div>
      <div className="f-size-16 mb-0">{result}</div>
    </>
  }

  const deleteOrderButton = (): JSX.Element => {
    return <>
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalBody className="light-silver-background pb-0">
          <h6 className="heading-small text-muted f-size-16 my-1 text-center">
            Сигурни ли сте, че искате да изтриете поръчката?
          </h6>
        </ModalBody>
        <Row className="light-silver-background py-4 no-gutters">
            <Col className="text-center">
              <Button color="success" onClick={() => {deleteOrder(orderId)}}>Да</Button>
              <Button color="danger" onClick={toggleModal}>Откажи</Button>
            </Col>
        </Row>
      </Modal>
    </>
  }

  const IsJsonString = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
  }

  const odParsedData = (input): JSX.Element => {
    let parsedData;
    
    if(IsJsonString(input)) {
      parsedData = JSON.parse(input);
    } else {
      if(input != null){
        parsedData = input;
      } else {
        parsedData = {} as MeasurementsDetails;
      }
    }
   
    return <>
    <table>
      <tbody>
        {IsJsonString(input) && input != null ?
          <tr className="transparent-background no-cell-padding">
            <td className="eye-direction">OD</td>
            <td className="measurement-cell-width">{parsedData.od_sph}</td>
            <td className="measurement-cell-width">{parsedData.od_cyl}</td>
            <td className="measurement-cell-width">{parsedData.od_ax}</td>
            <td className="measurement-cell-width">{parsedData.od_add}</td>
            <td className="measurement-cell-width">{parsedData.od_prism}</td>
            <td className="measurement-cell-width">{parsedData.od_base}</td>
          </tr>
        :
          <tr className="transparent-background no-cell-padding">
            <td className="measurement-cell-width">{parsedData}</td>
          </tr>
        }
      </tbody>
    </table>
    </>
  }

  const osParsedData = (input, index): JSX.Element => {
    let parsedData;
    if(IsJsonString(input)) {
      parsedData = JSON.parse(input);
    } else {
      if(input != null){
        parsedData = input;
      } else {
        parsedData = {} as MeasurementsDetails;
      }
    }
   
    return <>
    <table>
      <tbody key={index}>
      {IsJsonString(input) && input != null ?
        <tr className="transparent-background no-cell-padding">
          <td className="eye-direction">OS</td>
          <td className="measurement-cell-width">{parsedData.os_sph}</td>
          <td className="measurement-cell-width">{parsedData.os_cyl}</td>
          <td className="measurement-cell-width">{parsedData.os_ax}</td>
          <td className="measurement-cell-width">{parsedData.os_add}</td>
          <td className="measurement-cell-width">{parsedData.os_prism}</td>
          <td className="measurement-cell-width">{parsedData.os_base}</td>
        </tr>
        :
        <tr className="transparent-background no-cell-padding">
          <td className="measurement-cell-width">{parsedData}</td>
        </tr>
      }
      </tbody>
    </table>
    </>
  }

  const diopterTableHeader = (): JSX.Element => {
    return <>
        <table >
          <tbody >
            <tr className="transparent-background no-cell-padding">
              <td className="eye-direction"></td>
              <td className="measurement-cell-width">Sph</td>
              <td className="measurement-cell-width">cyl </td>
              <td className="measurement-cell-width">ax </td>
              <td className="measurement-cell-width">add </td>
              <td className="measurement-cell-width">prism </td>
              <td className="measurement-cell-width">base</td>
            </tr>
          </tbody>
        </table>
    </>
  }

  const redirectToPage = (link: string): void => {
    history.push(link);
  }

  const orderPage = (data: any): string => {
    if (data.orderType == OrderType.Glasses){
      return `${ClientRoute.GlassesOrderDetails}?opticalMeasurementId=${data.opticalMeasurementId}&orderId=${data.id}&orderStatus=${OrderStatus.Requested}`;
    }
    if (data.orderType == OrderType.ContactLenses){
      return `${ClientRoute.ContactLensesOrderDetails}?opticalMeasurementId=${data.opticalMeasurementId}&orderId=${data.id}&orderStatus=${OrderStatus.Requested}`;
    }
  }
  
  return <>
  {props.loading ? <PageLoader></PageLoader> :
    <Table className="align-items-center table-flush orders-table table-striped" responsive>
      <thead className="silver-background black-font-color">
        <tr>
          {tableHeader.map((data, index) =>
            <th key={index} className="f-size-16 pt-4 pb-4 border-top-0" scope="col">
              {data == "Диоптри" ? 
                <>
                  {diopterTableHeader()}
                </>
              :
                <>
                  {data}
                </>  
            }  
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        {props.data
        ?
          <>
          {props.data.map((data, index) =>
          <tr key={index}>
            <td className="f-size-16 cursor-pointer" onClick={() => redirectToPage(orderPage(data))}>{formatDate(data.dateCreated, 'DD/MM') }</td>
            <td className="f-size-16 cursor-pointer" onClick={() => redirectToPage(orderPage(data))}>{data.brand == "BL" ? "B & L" : data.brand}</td>
            <td className="f-size-16 cursor-pointer" onClick={() => redirectToPage(orderPage(data))}>{splitString(data.productDetails)}</td>
            <td className="f-size-16 cursor-pointer" onClick={() => redirectToPage(orderPage(data))}>
                {odParsedData(data.odDioptersDetails)}
                {osParsedData(data.osDioptersDetails, index)}
            </td>
            <td className="f-size-16"><span className="mr-2">{OrderStatusText(data.status)}</span>
              {data.status === OrderStatus.Processing && 
                <>
                  <Button color="warning" className="m-0 mr-2 p-2" onClick={() => {requestOrder(data.id)}}>Заяви</Button>
                  <Button color="success" className="m-0 p-2" title="Приключи" onClick={() => {finishOrder(data.id)}}><i className="fa fa-check" aria-hidden="true"></i></Button>
                  <Button color="danger" className="m-0 ml-2 p-2" title="Откажи" onClick={() => toggleModal(data.id)}><i className="fa fa-times" aria-hidden="true"></i></Button>
                </>
              }
              {data.status === OrderStatus.Requested && 
                <>
                  <Button color="success" className="m-0 p-2" title="Приключи" onClick={() => {finishOrder(data.id)}}><i className="fa fa-check" aria-hidden="true"></i></Button>
                  <Button color="danger" className="m-0 ml-2 p-2" title="Откажи" onClick={() => toggleModal(data.id)}><i className="fa fa-times" aria-hidden="true"></i></Button>
                </>
              }
            </td>
          </tr>
        )}
          </>
          :
          <tr><td><NoAvailableData/></td></tr>
        }
        
      </tbody>
      <>{deleteOrderButton()}</>
    </Table>
  }
  </>
}

export default OrdersTableTemplate;
