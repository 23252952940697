import React, { useState, useContext, useEffect, } from "react";
import { Card, CardBody, Row, Col, Button } from "reactstrap";
import MeasurementsTableTemplate from '../../OpticalMeasurement/MeasurementsTableTemplate';
import FrameInputDetails from './FrameInputDetails';
import Lenses, { LensesData } from './Lenses';
import { TemplateView } from "../../../../models/enums/TemplateView";
import { Update, Create, Delete, Get } from "../../../../helpers/requests";
import { ClientRoute } from "../../../../routes";
import { osDioptersDetails } from "../../../../helpers/OsDioptersDetails";
import { productDetails, ProductDetailsType } from "../../../../helpers/ProductDetails";
import { GlassesOrderData } from "../../../../models/glassesOrderData";
import { FrameDetails } from "../../../../models/frameDetails";
import { MeasurementsDetails } from "../../../../models/measurementDetails";
import { UserContext } from "../../../../userContext";
import PageLoader from "../../../../components/pageLoader";
import { OrderStatus } from "../../../../models/enums/OrderStatus";
import { odDioptersDetails } from "../../../../helpers/OdDioptersDetails";

type GlassesOrderTemplateProps = {
    templateData?: GlassesOrderData;
    viewType: TemplateView;
    orderId?: string;
    latestOpticalMeasurementId?: string;
    orderStatus?: OrderStatus;
}

const GlassesOrderTemplate = (props: GlassesOrderTemplateProps) => {

    var url = new URL(window.location.href);
    var clientId = url.searchParams.get("customerId");

    const [loading, setLoading] = useState(true);
    const [reload, setReload] = useState(false);
    const [frameObjectData, setFrameObjectData] = useState<FrameDetails>({});
    const [lensesObjectData, setLensesObjectData] = useState<LensesData>({});
    const [measurementsDetails, setMeasurementsDetails] = useState<MeasurementsDetails>({});
    const [glassesOrderData, setGlassesOrderData] = useState<GlassesOrderData>({});

    useEffect(() => {
        Get(`opticalMeasurements/${props.latestOpticalMeasurementId}/details`)
            .catch(error => console.log(error))
            .then((json: any) => {
            setMeasurementsDetails(json);
        });
        if (props.viewType == TemplateView.Edit) {
            Get(`glassesOrder/${props.orderId}`)
                .catch(error => console.log(error))
                .then((json: GlassesOrderData) => {
                    setGlassesOrderData(json);
                    setLoading(false);
                    setReload(false);
            });
        }
    },[reload]);
    
    const templateType = props.viewType;
    
    const {objectData} = useContext(UserContext);

    const companyId = objectData.getObjectData.companyId;
    const facilityId = objectData.getObjectData.facilityId;
    const customerId = clientId;
    
    const employeeId = objectData.getObjectData.id;

    const saveDataObject = () => {

        let orderData: GlassesOrderData = {};
        orderData = {
            ...lensesObjectData,
            ...frameObjectData,
            companyId: companyId,
            facilityId: facilityId,
            employeeId: employeeId,
            customerId: customerId,
            opticalMeasurementId: props.latestOpticalMeasurementId,
            productDetails: productDetails(lensesObjectData, ProductDetailsType.Glasses),
            osDioptersDetails: osDioptersDetails(lensesObjectData.distanceType, measurementsDetails),
            odDioptersDetails: odDioptersDetails(lensesObjectData.distanceType, measurementsDetails),
        };
        
        Create(orderData, "glassesOrder", `${ClientRoute.CustomerDetails}?id=${customerId}`);
    }

    const updateDataObject = () => {
        let orderData = new GlassesOrderData();
        orderData = {
            ...lensesObjectData,
            ...frameObjectData,
            employeeId: employeeId,
            id: props.orderId,
            productDetails: productDetails(lensesObjectData, ProductDetailsType.Glasses),
            osDioptersDetails: osDioptersDetails(lensesObjectData.distanceType, measurementsDetails),
            odDioptersDetails: odDioptersDetails(lensesObjectData.distanceType, measurementsDetails),
        };
         
        Update(orderData, "glassesOrder", `${ClientRoute.CustomerDetails}?id=${measurementsDetails.clientId}`);
    }

    const deleteDataObject = () => {
        Delete(`order/${props.orderId}`);
        Delete(`glassesOrder/${props.orderId}`, `${ClientRoute.CustomerDetails}?id=${measurementsDetails.clientId}`);
    }

    const renderButtons = (): JSX.Element => {
        switch (templateType) {
            case TemplateView.CreateNew: {
                return <>
                    <Button color="success" onClick={saveDataObject}>Запази</Button>
                </>
            }
            case TemplateView.Edit: {
                return <>
                    {props.orderStatus == OrderStatus.Processing && 
                        <>
                            <Button color="success" onClick={() => updateDataObject()}>Запази</Button>
                            <Button color="danger" onClick={deleteDataObject}>Изтрий Поръчка</Button>
                        </>
                    }
                </>
            }
            default: {
                return <span>Template Type Unknown</span>
            }
        }
    }

    const renderFields = (): JSX.Element => {
        switch (props.viewType) {
            case TemplateView.CreateNew: {
                return <>
                <Card className="bg-secondary shadow mb-1">
                    <CardBody className="py-3">
                        <h6 className="heading-small text-muted f-size-16">
                            Диоптрични лещи
                        </h6>
                        <Row>
                            <Lenses viewType={templateType} setLensesData={setLensesObjectData}/>
                        </Row>
                    </CardBody>
                </Card>
                <FrameInputDetails viewType={templateType} setFrameData={setFrameObjectData} />      
                </>
            }
            case TemplateView.Edit: {
                return <>
                    {loading ? <PageLoader></PageLoader> : 
                        <>
                        <Card className="bg-secondary shadow mb-1">
                            <CardBody className="py-3">
                                <h6 className="heading-small text-muted f-size-16">
                                    Диоптрични лещи
                                </h6>
                                <Row className="mt-3">
                                    <Lenses viewType={templateType} setLensesData={setLensesObjectData} reload={reload} orderId={props.orderId}/>
                                </Row>
                            </CardBody>
                        </Card>
                        <FrameInputDetails viewType={templateType} setFrameData={setFrameObjectData} reload={reload} orderId={props.orderId}/>      
                        </>
                    }
                </>
            }
            default: {
                return <span>Template Type Unknown</span>
            }
        }
    }

    return (
        <>
        <Card className="bg-secondary shadow mb-1">
            <CardBody className="py-3">
                <h6 className="heading-small text-muted f-size-16 mb-0">
                    Клиент
                    {measurementsDetails.customerName && 
                        <span className="mr-4"> - {measurementsDetails.customerName} </span>
                    }
                    {measurementsDetails.customerPhone && 
                        <span className="ml-4">Телефон - {measurementsDetails.customerPhone}</span>
                    }
                </h6>
            </CardBody>
        </Card>
        <Card className="bg-secondary shadow mb-1">
            <CardBody className="py-3">
                <h6 className="heading-small text-muted f-size-16">
                    Рецепта
                    {measurementsDetails.prescribedBy && 
                        <> - Предписал: {measurementsDetails.prescribedBy}</>
                    }
                </h6>
                <Row className="mt-3">
                    <Col m={8} lg={8}>
                        <MeasurementsTableTemplate viewType={TemplateView.View} templateData={measurementsDetails} />
                    </Col>
                </Row>
            </CardBody>
        </Card>
        {renderFields()}
        <Card className="bg-secondary shadow">
            <CardBody className="py-3">
                {glassesOrderData.employeeName && 
                    <h6 className="heading-small text-muted f-size-16">Създал: {glassesOrderData.employeeName}</h6>
                }
                {renderButtons()}
            </CardBody>
        </Card>
    </>
    );
}

export default GlassesOrderTemplate;
