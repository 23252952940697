import React from "react";
import { TemplateView } from "../../../models/enums/TemplateView";
import GlassesOrderTemplate from "./Order-Components/GlassesOrderTemplate";

const EditGlassesOrder = () => {

  var url = new URL(window.location.href);
  var opticalMeasurementId = url.searchParams.get("opticalMeasurementId");
  var orderId = url.searchParams.get("orderId");
  var orderStatus: any = url.searchParams.get("orderStatus");
  
  return <>
    <GlassesOrderTemplate viewType={TemplateView.Edit} latestOpticalMeasurementId={opticalMeasurementId} orderId={orderId} orderStatus={orderStatus}/>
  </>
}

export default EditGlassesOrder;
