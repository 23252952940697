import React from 'react';
import FacilityTemplate, { FacilityDetailsData } from './FacilityTemplate';
import { TemplateView } from '../../../models/enums/TemplateView';
import ApiResource from '../../../helpers/ApiResource';

const EditFacility = (props: FacilityDetailsData) => {

  var url = new URL(window.location.href);
  var id = url.searchParams.get("id");

  return <>
      <ApiResource url={`facility/${id}/details`}>
          {(facilityDetailsData: FacilityDetailsData) => <>
            <FacilityTemplate viewType={TemplateView.Edit} templateData={facilityDetailsData}/>
          </>}
      </ApiResource>
  </>
}

export default EditFacility;