import React from "react";
import { TemplateView } from "../../../models/enums/TemplateView";
import ContactLensesOrderTemplate from "./Order-Components/ContactLensesOrderTemplate";
import { OrderStatus } from "../../../models/enums/OrderStatus";

const AddContactLensesOrder = () => {

  var url = new URL(window.location.href);
  var measurementId = url.searchParams.get("measurementId");
  
  return <>
    <ContactLensesOrderTemplate viewType={TemplateView.CreateNew} latestOpticalMeasurementId={measurementId} orderStatus={OrderStatus.Processing}/>
  </>
}

export default AddContactLensesOrder;