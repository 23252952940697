import React from 'react';
import CompanyTemplate from './CompanyTemplate';
import { TemplateView } from '../../../models/enums/TemplateView';
import ApiResource from '../../../helpers/ApiResource';
import { CompanyDetailsData } from '../../../models/companyDetailsData';
import { GetObjectData } from '../../../helpers/objectContextManager';

const EditCompany = (props: CompanyDetailsData) => {
  
  return <>
    <ApiResource url={`company/${GetObjectData()}/details`}>
          {(companyDetailsData: CompanyDetailsData) => <>
            <CompanyTemplate viewType={TemplateView.Edit} templateData={companyDetailsData}/>
          </>}
      </ApiResource>
  </>
}

export default EditCompany;