import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Table, Button, ButtonGroup, Input, Row, Col, FormGroup } from 'reactstrap';
import { TemplateView } from '../../../models/enums/TemplateView';
import { CommonCondition }  from '../../../models/commonCondition';

type CommonConditionTemplateProps = {
    templateData?: any;
    setConditionData?: Dispatch<SetStateAction<CommonCondition>>;
    viewType: TemplateView;
}

const CommonConditionTemplate = (props: CommonConditionTemplateProps) => {

    const inputData = props.templateData ? props.templateData : {} as CommonCondition;
    const [conditionData, setConditionObjectData] = useState<CommonCondition>({});

    const bloodPressureType = ["Нормално", "Ниско", "Високо"];
    const bloodSugarType = ["Нормално", "Предиабетно", "Диабет"];
    const cataractsType = ["Отстранена", "Има", "Няма"];
    const familyHistoryType = ["Глаукома", "Кератоконус", "Макулна дег."];
    const complaintsType = ["Главоболие", "Болка в очите", "Световъртеж"];
    const lightSensitivityType = ["Фотофобия", "Кокоша слепота", "Дневна слепота"];
    const priorityDistanceType = ["Безкрайност", "от 60см до 1м", "от 30см до 60см"];
    
    useEffect(() => {
      if (props.viewType != TemplateView.CreateNew){
        if(props.templateData){
          setConditionObjectData(inputData);
        }
      }
    },[]);

    useEffect(() => {
      if(typeof(props.setConditionData) === "function" ) {
          props.setConditionData(conditionData);
      }
    }, [conditionData]);

    const colElement = (inputObject, objectProperty, label): JSX.Element => {
      return <>
          <Col className="pr-0">
            <label className="form-control-label text-right w-100">
                {label}
            </label>
          </Col>
          <Col>
            {props.viewType == TemplateView.View ?
              <label className="form-control-label text-left w-100">
                {inputObject[objectProperty] ? `${inputObject[objectProperty]}` : ""}
              </label>
            :
              <Input
                  className="form-control-alternative"
                  type="text"
                  defaultValue={inputObject[objectProperty]}
                  onChange={e => setConditionObjectData({ ...inputObject, [objectProperty]: e.target.value})}
              />
            }
          </Col>
      </>
    }

    const rowElement = (elementType, inputObject, objectProperty, label): JSX.Element => {
      return <>
        <tr>
            <th className="f-size-16" scope="row">{label}</th>
            {props.viewType == TemplateView.View ?
              <td>
                <div className="f-size-18">{inputObject[objectProperty]}</div>
              </td>
            :
            <>
              <td colSpan={3}>
                <ButtonGroup className="d-flex justify-content-around contact-lenses-buttons">
                {elementType.map((data, index) =>
                  <Button
                    key={index}
                    className="w-30 px-2"
                    color="primary"
                    onClick={() => setConditionObjectData({ ...inputObject, [objectProperty]: data })}
                    active={inputObject[objectProperty] === data}>
                    {data}
                  </Button>
                )}
                </ButtonGroup>
              </td>
              <td className="w-20">
                  <Input
                      className={`f-size-18`}
                      type="text"
                      placeholder="Друг"
                      defaultValue={inputObject[objectProperty]}
                      onChange={e => setConditionObjectData({ ...inputObject, [objectProperty]: e.target.value})}
                  />
              </td>
            </>
            }
          </tr>
      </>
    }
    
    return <>
      <Table className="table-border-blue my-3 common-condition-table" bordered>
        <tbody>
          {rowElement(bloodPressureType, conditionData, "bloodPressure", "Кръвно налягане")}
          {rowElement(bloodSugarType, conditionData, "bloodSugar", "Кръвна захар/инсулин")}
          {rowElement(cataractsType, conditionData, "cataractsRight", "Катаракти дясно")}
          {rowElement(cataractsType, conditionData, "cataractsLeft", "Катаракти ляво")}
          {rowElement(familyHistoryType, conditionData, "familyHistory", "Фамилна обремененост")}
          {rowElement(complaintsType, conditionData, "complaints", "Оплаквания")}
          {rowElement(lightSensitivityType, conditionData, "lightSensitivity", "Светлочувствителност")}
          {rowElement(priorityDistanceType, conditionData, "priorityDistance", "Приоритетна дистанция")}
        </tbody>
      </Table>
      <Row className="common-condition-table align-items-center mb-2">
        {colElement(conditionData, "objectiveRefraction_od", "Обективна Рефр. OD:")}
        {colElement(conditionData, "objectiveRefraction_os", "Обективна Рефр. OS:")}
      </Row>
      <Row className="common-condition-table align-items-center mb-2">
        {colElement(conditionData, "pressure_od", "Налягане OD:")}
        {colElement(conditionData, "pressure_os", "Налягане OS:")}
      </Row>
      <Row className="common-condition-table align-items-center mb-2">
        {colElement(conditionData, "corneaCurvature_od", "Кривина Роговица OD:")}
        {colElement(conditionData, "corneaCurvature_os", "Кривина Роговица OS:")}
      </Row>
      <Row className="common-condition-table align-items-center">
        {colElement(conditionData, "od_vsc", "Vsc OD:")}
        {colElement(conditionData, "os_vsc", "Vsc OS:")}
      </Row>
    </>
}

export default CommonConditionTemplate;