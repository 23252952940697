import React from "react";

type ButtonLoaderProps = {
    spinnerSize: number;
    className: string;
}

const ButtonLoader = (props: ButtonLoaderProps): JSX.Element => {

    return <img className={props.className.toString()}
            width={props.spinnerSize.toString()}
            height={props.spinnerSize.toString()}
            alt="..."
            src={require('../assets/img/91.gif')}
        />
}

export default ButtonLoader;