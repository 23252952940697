export const OrderStatusText = (statusId: number) => {
    switch (statusId) {
      case 0: {
        return "Обработва се";
      }
      case 1: {
        return "Заявена";
      }
      case 2: {
        return "Завършена";
      }
      default: {
        return "Няма статус";
      }
    }
  }