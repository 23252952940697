import React, { useContext } from 'react';
import { TemplateView } from '../../../models/enums/TemplateView';
import MeasurementsTableTemplate from './MeasurementsTableTemplate';
import { Card, CardBody } from 'reactstrap';
import { MeasurementsDetails } from '../../../models/measurementDetails';
import { UserContext } from '../../../userContext';

const AddOpticalMeasurement = (props) => {

  var url = new URL(window.location.href);
  var customerId = url.searchParams.get("customerId");

  const {objectData} = useContext(UserContext);
  
  const newEntityData: MeasurementsDetails = {
    clientId: customerId,
    employeeId: objectData.getObjectData.id
  }

  return <>
    <Card className="bg-secondary shadow mt-4">
      <CardBody>
        <h6 className="heading-small f-size-16">Детайли</h6>
          <MeasurementsTableTemplate viewType={TemplateView.CreateNew} templateData={newEntityData}/>
      </CardBody>
    </Card>
  </>
}

export default AddOpticalMeasurement;