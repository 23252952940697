import React from 'react';
import { Row, Card, Col, CardBody } from 'reactstrap';
import { TemplateView } from '../../../models/enums/TemplateView';
import ApiResource from '../../../helpers/ApiResource';
import MeasurementsTableTemplate from './MeasurementsTableTemplate';
import { MeasurementsDetails } from '../../../models/measurementDetails';

const EditOpticalMeasurement = (props) => {

  var url = new URL(window.location.href);
  var id = url.searchParams.get("id");

  return <>
    <Card className="bg-secondary shadow">
      <CardBody>
        <h6 className="heading-small f-size-16">Детайли</h6>
        <Row className="mt-4">
          <Col lg={10}>
            <ApiResource url={`opticalmeasurements/${id}/details`}>
              {(measurementData: MeasurementsDetails) => <>
                  <MeasurementsTableTemplate viewType={TemplateView.Edit} templateData={measurementData}/>
              </>}
            </ApiResource>
          </Col>
        </Row>
      </CardBody>
    </Card>
  </>
}

export default EditOpticalMeasurement;